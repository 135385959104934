import { OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { urlBackendUpload } from 'src/app/models/GLOBAL';
import { Paginas } from 'src/app/models/paginas';
import { PaginasService } from 'src/app/services/paginas/paginas.service';
import { NoticiasService } from 'src/app/services/noticias/noticias.service';
import { GaleriaService } from 'src/app/services/galeria/galeria.service';
var PaginasComponent = /** @class */ (function () {
    function PaginasComponent(activatedRoute, paginasService, noticiasService, galeriaService) {
        this.activatedRoute = activatedRoute;
        this.paginasService = paginasService;
        this.noticiasService = noticiasService;
        this.galeriaService = galeriaService;
        this.pagina = new Paginas();
        this.url = urlBackendUpload + 'paginas/';
        this.busqueda = true;
        this.noticias = [];
        this.total = 0;
        this.urlNoticias = urlBackendUpload + 'noticias/';
        this.galerias = [];
        this.galeriasPortada = [];
        this.totalGaleria = 0;
        this.totalPortada = 0;
        this.urlGaleria = urlBackendUpload + 'galeria/';
    }
    PaginasComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.activatedRoute.params.subscribe(function (params) {
            var menuId = params['menuId'];
            var submenuId = params['submenuId'];
            if (submenuId === '0') {
                _this.cargarPaginaMenu(menuId);
            }
            else {
                _this.cargarPaginaSubMenu(submenuId);
            }
        });
    };
    PaginasComponent.prototype.cargarPaginaSubMenu = function (submenuId) {
        var _this = this;
        this.paginasService.cargarPaginasSubMenu(submenuId).subscribe(function (response) {
            _this.pagina = response.paginas;
            _this.cargarGaleria();
            _this.cargarGaleriaPortada();
        }, function (error) {
            if (error.error.code === 404) {
                console.log(error.error.message);
            }
        });
    };
    PaginasComponent.prototype.cargarPaginaMenu = function (menuId) {
        var _this = this;
        this.paginasService.cargarPaginasMenu(menuId).subscribe(function (response) {
            _this.pagina = response.paginas;
            _this.cargarNoticias();
        }, function (error) {
            if (error.error.code === 404) {
                console.log(error.error.message);
            }
        });
    };
    PaginasComponent.prototype.cargarNoticias = function () {
        var _this = this;
        this.noticiasService.cargarNoticias().subscribe(function (response) {
            _this.noticias = response.noticias;
            _this.total = response.total;
        });
    };
    PaginasComponent.prototype.cargarGaleria = function () {
        var _this = this;
        this.galeriaService.cargarGaleria().subscribe(function (response) {
            _this.galerias = response.galerias;
            _this.totalGaleria = response.total;
        });
    };
    PaginasComponent.prototype.cargarGaleriaPortada = function () {
        var _this = this;
        this.galeriaService.cargarGaleriaPortada().subscribe(function (response) {
            _this.galeriasPortada = response.galerias;
            _this.totalPortada = response.total;
        });
    };
    return PaginasComponent;
}());
export { PaginasComponent };
