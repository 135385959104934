/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../pipes/cadenas-cortas.pipe";
import * as i3 from "../../pipes/cadenas-libros.pipe";
import * as i4 from "../../shared/breadcrumbs/breadcrumbs.component.ngfactory";
import * as i5 from "../../shared/breadcrumbs/breadcrumbs.component";
import * as i6 from "../../services/shared/breadcrumbs.service";
import * as i7 from "@angular/platform-browser";
import * as i8 from "./libros.component";
import * as i9 from "../../services/libros/libros.service";
import * as i10 from "@angular/router";
var styles_LibrosComponent = [];
var RenderType_LibrosComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_LibrosComponent, data: {} });
export { RenderType_LibrosComponent as RenderType_LibrosComponent };
function View_LibrosComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "a", [["class", "pointer"]], [[8, "title", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.detalle(_v.parent.context.$implicit.id) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "img", [["alt", ""], ["class", "libros"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.parent.context.$implicit.titulo; _ck(_v, 0, 0, currVal_0); var currVal_1 = (_co.url + _v.parent.context.$implicit.imagen); _ck(_v, 1, 0, currVal_1); }); }
function View_LibrosComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "li", [["class", "pull-left ajp text-center portada"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_LibrosComponent_3)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 4, "h3", [["style", "margin-bottom:6px; text-transform: capitalize;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 3, "a", [["class", "pointer"]], [[8, "title", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.detalle(_v.context.$implicit.id) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 2, "strong", [["style", "color: #000;"]], null, null, null, null, null)), (_l()(), i0.ɵted(6, null, ["", ""])), i0.ɵppd(7, 1), (_l()(), i0.ɵeld(8, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(9, null, ["", ""])), i0.ɵppd(10, 1)], function (_ck, _v) { var currVal_0 = _v.context.$implicit.imagen; _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = _v.context.$implicit.titulo; _ck(_v, 4, 0, currVal_1); var currVal_2 = i0.ɵunv(_v, 6, 0, _ck(_v, 7, 0, i0.ɵnov(_v.parent.parent, 0), _v.context.$implicit.titulo)); _ck(_v, 6, 0, currVal_2); var currVal_3 = i0.ɵunv(_v, 9, 0, _ck(_v, 10, 0, i0.ɵnov(_v.parent.parent, 1), _v.context.$implicit.autor)); _ck(_v, 9, 0, currVal_3); }); }
function View_LibrosComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["id", "agv"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 5, "section", [], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Fondo Editorial CNL"])), (_l()(), i0.ɵeld(4, 0, null, null, 2, "ul", [["class", "ain"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_LibrosComponent_2)), i0.ɵdid(6, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.libros; _ck(_v, 6, 0, currVal_0); }, null); }
function View_LibrosComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "section", [["class", "ahg"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "div", [["class", "ady ait"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Sin registros encontrados."]))], null, null); }
export function View_LibrosComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i2.CadenasCortasPipe, []), i0.ɵpid(0, i3.CadenasLibrosPipe, []), (_l()(), i0.ɵeld(2, 0, null, null, 1, "app-breadcrumbs", [], null, null, null, i4.View_BreadcrumbsComponent_0, i4.RenderType_BreadcrumbsComponent)), i0.ɵdid(3, 114688, null, 0, i5.BreadcrumbsComponent, [i6.BreadcrumbsService, i7.Title, i7.Meta], null, null), (_l()(), i0.ɵeld(4, 0, null, null, 6, "div", [["class", "afx ake"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 5, "div", [["class", "aem"], ["role", "main"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_LibrosComponent_1)), i0.ɵdid(7, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_LibrosComponent_4)), i0.ɵdid(9, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(10, 0, null, null, 0, "div", [["class", "ain"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; _ck(_v, 3, 0); var currVal_0 = (_co.total > 0); _ck(_v, 7, 0, currVal_0); var currVal_1 = (_co.total == 0); _ck(_v, 9, 0, currVal_1); }, null); }
export function View_LibrosComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-libros", [], null, null, null, View_LibrosComponent_0, RenderType_LibrosComponent)), i0.ɵdid(1, 114688, null, 0, i8.LibrosComponent, [i9.LibrosService, i10.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LibrosComponentNgFactory = i0.ɵccf("app-libros", i8.LibrosComponent, View_LibrosComponent_Host_0, {}, {}, []);
export { LibrosComponentNgFactory as LibrosComponentNgFactory };
