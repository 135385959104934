/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/common";
import * as i3 from "./movil-template.component";
import * as i4 from "../../services/movil/movil.service";
var styles_MovilTemplateComponent = [];
var RenderType_MovilTemplateComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_MovilTemplateComponent, data: {} });
export { RenderType_MovilTemplateComponent as RenderType_MovilTemplateComponent };
function View_MovilTemplateComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "img", [], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.url + _co.movil.imagenPortada); _ck(_v, 0, 0, currVal_0); }); }
export function View_MovilTemplateComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "aem"], ["role", "main"], ["style", "max-width: 74%;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "a", [["routerLink", "/movil"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 671744, null, 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MovilTemplateComponent_1)), i0.ɵdid(4, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = "/movil"; _ck(_v, 2, 0, currVal_2); var currVal_3 = _co.movil.imagenPortada; _ck(_v, 4, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 2).target; var currVal_1 = i0.ɵnov(_v, 2).href; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
export function View_MovilTemplateComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-movil-template", [], null, null, null, View_MovilTemplateComponent_0, RenderType_MovilTemplateComponent)), i0.ɵdid(1, 114688, null, 0, i3.MovilTemplateComponent, [i4.MovilService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MovilTemplateComponentNgFactory = i0.ɵccf("app-movil-template", i3.MovilTemplateComponent, View_MovilTemplateComponent_Host_0, {}, {}, []);
export { MovilTemplateComponentNgFactory as MovilTemplateComponentNgFactory };
