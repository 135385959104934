import { Ubigeo } from "./ubigeo";
import { Colegios } from "./colegios";

export class Notarios {
  constructor(
    public id?:number,
    public ubigeo?: number,
    public sisev?:number,
    public estado?: number, //0 activo - 1 cesado - 2 licencia
    public notario?: string,
    public direccion?: string,
    public horario?: string,
    public telefono?: string,
    public correo?: string,
    public web?: string,
    public latitud?: string,
    public longitud?: string,
    public movilnotario?: string,
    public created_at?: string,
    public updated_at?: string,
    public colegiatura?: number,
    public presidente?: number,
    public miembro?: number,
    public visitado?: number,
    public nomdis?: string,
    public ubigeos?: Ubigeo,
    public colegios?: Colegios
  ) {}
}
