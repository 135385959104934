/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/common";
import * as i3 from "../../shared/breadcrumbs/breadcrumbs.component.ngfactory";
import * as i4 from "../../shared/breadcrumbs/breadcrumbs.component";
import * as i5 from "../../services/shared/breadcrumbs.service";
import * as i6 from "@angular/platform-browser";
import * as i7 from "./prensa-boletines.component";
import * as i8 from "../../services/boletines/boletines.service";
var styles_PrensaBoletinesComponent = [];
var RenderType_PrensaBoletinesComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PrensaBoletinesComponent, data: {} });
export { RenderType_PrensaBoletinesComponent as RenderType_PrensaBoletinesComponent };
function View_PrensaBoletinesComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "aap"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "a", [["class", "abf"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 671744, null, 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i0.ɵpad(3, 2), (_l()(), i0.ɵeld(4, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(5, null, ["", ""]))], function (_ck, _v) { var currVal_2 = _ck(_v, 3, 0, "/detalleBoletines", _v.context.$implicit.id); _ck(_v, 2, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 2).target; var currVal_1 = i0.ɵnov(_v, 2).href; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_3 = _v.context.$implicit.titulo; _ck(_v, 5, 0, currVal_3); }); }
function View_PrensaBoletinesComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "afx ake"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "div", [["class", "aem"], ["role", "main"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 3, "div", [["class", "ain ahg"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 2, "div", [["class", "ais"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PrensaBoletinesComponent_2)), i0.ɵdid(5, 278528, null, 0, i2.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.boletines; _ck(_v, 5, 0, currVal_0); }, null); }
export function View_PrensaBoletinesComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-breadcrumbs", [], null, null, null, i3.View_BreadcrumbsComponent_0, i3.RenderType_BreadcrumbsComponent)), i0.ɵdid(1, 114688, null, 0, i4.BreadcrumbsComponent, [i5.BreadcrumbsService, i6.Title, i6.Meta], null, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PrensaBoletinesComponent_1)), i0.ɵdid(3, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_0 = (_co.total > 0); _ck(_v, 3, 0, currVal_0); }, null); }
export function View_PrensaBoletinesComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-prensa-boletines", [], null, null, null, View_PrensaBoletinesComponent_0, RenderType_PrensaBoletinesComponent)), i0.ɵdid(1, 114688, null, 0, i7.PrensaBoletinesComponent, [i8.BoletinesService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PrensaBoletinesComponentNgFactory = i0.ɵccf("app-prensa-boletines", i7.PrensaBoletinesComponent, View_PrensaBoletinesComponent_Host_0, {}, {}, []);
export { PrensaBoletinesComponentNgFactory as PrensaBoletinesComponentNgFactory };
