import { OnInit } from '@angular/core';
import { NotariosService } from './../../../services/notarios/notarios.service';
var NotariosTodosComponent = /** @class */ (function () {
    function NotariosTodosComponent(notariosService) {
        this.notariosService = notariosService;
        this.notarios = [];
        this.eventos = [];
        this.total = 0;
        // PAGINADO
        this.p = 1;
        this.autoHide = true;
        this.labels = {
            previousLabel: 'Anterior',
            nextLabel: 'Siguiente'
        };
    }
    NotariosTodosComponent.prototype.ngOnInit = function () {
        this.cargarNotarios();
    };
    NotariosTodosComponent.prototype.cargarNotarios = function () {
        var _this = this;
        this.notariosService.cargarNotariosTodos().subscribe(function (response) {
            _this.notarios = response.notarios;
            _this.total = response.total;
        });
    };
    NotariosTodosComponent.prototype.buscarNotarioTodos = function (termino) {
        var _this = this;
        if (termino != '') {
            this.notariosService.buscarNotarioTodos(termino).subscribe(function (response) {
                _this.notarios = response.notarios;
                _this.total = response.total;
            });
        }
        else {
            this.cargarNotarios();
        }
    };
    NotariosTodosComponent.prototype.buscarNotariosEstado = function (estado) {
        var _this = this;
        if (estado != "3") {
            this.notariosService.buscarNotarioEstado(estado).subscribe(function (response) {
                _this.notarios = response.notarios;
                _this.total = response.total;
            });
        }
        else {
            this.cargarNotarios();
        }
    };
    return NotariosTodosComponent;
}());
export { NotariosTodosComponent };
