import { OnInit } from '@angular/core';
import { BreadcrumbsService } from '../../services/shared/breadcrumbs.service';
import { Title, Meta } from '@angular/platform-browser';
var BreadcrumbsComponent = /** @class */ (function () {
    function BreadcrumbsComponent(_services, title, meta) {
        var _this = this;
        this._services = _services;
        this.title = title;
        this.meta = meta;
        this.label = '';
        this._services.getDataRoute()
            .subscribe(function (data) {
            //  console.log(data);
            _this.label = data.titulo;
            _this.title.setTitle(_this.label);
            var metaTag = {
                name: 'description',
                content: _this.label
            };
            var metaAuthor = {
                name: 'author',
                content: _this.label
            };
            var metaKeywords = {
                name: 'keywords',
                content: _this.label
            };
            _this.meta.updateTag(metaTag);
            _this.meta.updateTag(metaAuthor);
            _this.meta.updateTag(metaKeywords);
        });
    }
    BreadcrumbsComponent.prototype.ngOnInit = function () {
    };
    return BreadcrumbsComponent;
}());
export { BreadcrumbsComponent };
