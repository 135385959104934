import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'fecha'
})
export class FechaPipe implements PipeTransform {

  diasEspañol:any;
  mesLetra:any;

  transform(value: any): any {

    let datePie = new DatePipe('en-US').transform(value, 'EEEE');
    let dia = new DatePipe('en-US').transform(value, 'dd');
    let anio = new DatePipe('en-US').transform(value, 'yyyy');
    let mes = value.split('-')[1];

    switch (datePie) {
      case 'Monday':
        this.diasEspañol = 'Lunes';
        break;
      case 'Tuesday':
        this.diasEspañol = 'Martes';
        break;
      case 'Wednesday':
        this.diasEspañol = 'Miércoles';
        break;
      case 'Thursday':
        this.diasEspañol = 'Jueves';
        break;
      case 'Friday':
        this.diasEspañol = 'Viernes';
        break;
      case 'Saturday':
        this.diasEspañol = 'Sábado';
        break;
      case 'Sunday':
        this.diasEspañol = 'Domingo';
        break;
      default:
        this.diasEspañol = ''
        break;
    }

    switch (mes) {
      case '12':
        this.mesLetra = 'Diciembre';
        break;
      case '11':
        this.mesLetra = 'Noviembre';
        break;
      case '10':
        this.mesLetra = 'Octubre';
        break;
      case '09':
        this.mesLetra = 'Setiembre';
        break;
      case '08':
        this.mesLetra = 'Agosto';
        break;
      case '07':
        this.mesLetra = 'Julio';
        break;
      case '06':
        this.mesLetra = 'Junio';
        break;
      case '05':
        this.mesLetra = 'Mayo';
        break;
      case '04':
        this.mesLetra = 'Abril';
        break;
      case '03':
        this.mesLetra = 'Marzo';
        break;
      case '02':
        this.mesLetra = 'Febrero';
        break;
      case '01':
        this.mesLetra = 'Enero';
        break;
      default:
        this.mesLetra = ''
        break;
    }


    return this.diasEspañol + ', ' +  dia + ' de ' + this.mesLetra + ' del ' + anio;
  }

}
