var Movil = /** @class */ (function () {
    function Movil(id, titulo, detalle, detalle2, imagenPortada, created_at, updated_at) {
        this.id = id;
        this.titulo = titulo;
        this.detalle = detalle;
        this.detalle2 = detalle2;
        this.imagenPortada = imagenPortada;
        this.created_at = created_at;
        this.updated_at = updated_at;
    }
    return Movil;
}());
export { Movil };
