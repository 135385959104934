<div class="afx ajh" *ngIf="total > 0">
    <div id="aen" class="ain">
        <div class="agh">
            <h2 class="agl">Detalles de Videos</h2>
        </div>
        <nav class="aex adv">
            <ul>
                <li><a routerLink="/home" title="Inicio">Inicio</a></li>
                <li><a routerLink="/videos/10/1">Videos del CNL</a></li>
                <li><a class="titulo" title="{{videos.titulo}}">{{videos.titulo}}</a></li>
            </ul>
        </nav>
    </div>
</div>

<div class="afx ake" *ngIf="total > 0">

    <div role="main" class="aem">
        <div id="ado">
            <div id="aca" class="ain">
                <article class="aak">
                    <div class="acx">
                        <div class="adu">
                            <iframe [src]="videos.url + opciones | domseguro" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen width="100%" height="500px"></iframe>
                        </div>
                        <h2 class="aba text-center">{{videos.titulo}}</h2>
                        <div class="abl text-center">
                            <ul>
                                <li class="abw">
                                    <time>{{videos.fecha | fecha}}</time>
                                </li>
                            </ul>
                        </div>
                    </div>
                </article>
            </div>
        </div>
    </div>
</div>
