export class Libros {
  constructor(
    public id?:number,
    public titulo?: string,
    public autor?: string,
    public resumen?: string,
    public publicacion?: string,
    public precio?:number,
    public imagen?: string,
    public orden?: number,
    public status?: number,
    public created_at?:string,
    public updated_at?:string
  ) {}
}
