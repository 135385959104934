/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../pipes/dias.pipe";
import * as i3 from "../../pipes/meses.pipe";
import * as i4 from "@angular/router";
import * as i5 from "./boletin-detalle.component";
import * as i6 from "../../services/boletines/boletines.service";
var styles_BoletinDetalleComponent = [];
var RenderType_BoletinDetalleComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_BoletinDetalleComponent, data: {} });
export { RenderType_BoletinDetalleComponent as RenderType_BoletinDetalleComponent };
function View_BoletinDetalleComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "a", [["class", "titulo"]], [[8, "title", 0]], null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "", _co.titulo, ""); _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.titulo; _ck(_v, 2, 0, currVal_1); }); }
function View_BoletinDetalleComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.titulo; _ck(_v, 1, 0, currVal_0); }); }
function View_BoletinDetalleComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "aap"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "div", [["class", "ady ait"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Sin registros encontrados."]))], null, null); }
function View_BoletinDetalleComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "a", [["class", "abf"], ["target", "blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 8, "span", [], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 3, "strong", [], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["D\u00EDa ", "(", "):"])), i0.ɵppd(4, 1), i0.ɵppd(5, 2), (_l()(), i0.ɵted(6, null, [" ", " "])), (_l()(), i0.ɵeld(7, 0, null, null, 2, "strong", [["style", "margin-left: 2%;"]], null, null, null, null, null)), (_l()(), i0.ɵted(8, null, ["", ""])), i0.ɵppd(9, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.url + _v.parent.context.$implicit.archivo); _ck(_v, 0, 0, currVal_0); var currVal_1 = i0.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i0.ɵnov(_v.parent.parent.parent, 0), _v.parent.context.$implicit.fecha)); var currVal_2 = i0.ɵunv(_v, 3, 1, _ck(_v, 5, 0, i0.ɵnov(_v.parent.parent.parent, 1), _v.parent.context.$implicit.fecha, "d")); _ck(_v, 3, 0, currVal_1, currVal_2); var currVal_3 = _v.parent.context.$implicit.titulo; _ck(_v, 6, 0, currVal_3); var currVal_4 = i0.ɵunv(_v, 8, 0, _ck(_v, 9, 0, i0.ɵnov(_v.parent.parent.parent, 2), _v.parent.context.$implicit.fecha)); _ck(_v, 8, 0, currVal_4); }); }
function View_BoletinDetalleComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "a", [["class", "abf"], ["target", "blank"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 8, "span", [], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 3, "strong", [], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["D\u00EDa ", "(", "):"])), i0.ɵppd(4, 1), i0.ɵppd(5, 2), (_l()(), i0.ɵted(6, null, [" ", " "])), (_l()(), i0.ɵeld(7, 0, null, null, 2, "strong", [["style", "margin-left: 2%;"]], null, null, null, null, null)), (_l()(), i0.ɵted(8, null, ["", ""])), i0.ɵppd(9, 1)], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i0.ɵnov(_v.parent.parent.parent, 0), _v.parent.context.$implicit.fecha)); var currVal_1 = i0.ɵunv(_v, 3, 1, _ck(_v, 5, 0, i0.ɵnov(_v.parent.parent.parent, 1), _v.parent.context.$implicit.fecha, "d")); _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = _v.parent.context.$implicit.titulo; _ck(_v, 6, 0, currVal_2); var currVal_3 = i0.ɵunv(_v, 8, 0, _ck(_v, 9, 0, i0.ɵnov(_v.parent.parent.parent, 2), _v.parent.context.$implicit.fecha)); _ck(_v, 8, 0, currVal_3); }); }
function View_BoletinDetalleComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "aap"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_BoletinDetalleComponent_6)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_BoletinDetalleComponent_7)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit.archivo; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_v.context.$implicit.archivo; _ck(_v, 4, 0, currVal_1); }, null); }
function View_BoletinDetalleComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_BoletinDetalleComponent_5)), i0.ɵdid(2, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.boletinDetalles; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_BoletinDetalleComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i2.DiasPipe, []), i0.ɵpid(0, i1.DatePipe, [i0.LOCALE_ID]), i0.ɵpid(0, i3.MesesPipe, []), (_l()(), i0.ɵeld(3, 0, null, null, 16, "div", [["class", "afx ajh"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 15, "div", [["class", "ain"], ["id", "aen"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 2, "div", [["class", "agh"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 1, "h2", [["class", "agl"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Bolet\u00EDn Institucional"])), (_l()(), i0.ɵeld(8, 0, null, null, 11, "nav", [["class", "aex adv"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 10, "ul", [], null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 3, "li", [], null, null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 2, "a", [["routerLink", "/home"], ["title", "Inicio"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 12).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(12, 671744, null, 0, i4.RouterLinkWithHref, [i4.Router, i4.ActivatedRoute, i1.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵted(-1, null, ["Inicio"])), (_l()(), i0.ɵeld(14, 0, null, null, 3, "li", [], null, null, null, null, null)), (_l()(), i0.ɵeld(15, 0, null, null, 2, "a", [["routerLink", "/boletines"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 16).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(16, 671744, null, 0, i4.RouterLinkWithHref, [i4.Router, i4.ActivatedRoute, i1.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵted(-1, null, ["Bolet\u00EDn Institucional"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_BoletinDetalleComponent_1)), i0.ɵdid(19, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(20, 0, null, null, 13, "div", [["class", "afx ake"]], null, null, null, null, null)), (_l()(), i0.ɵeld(21, 0, null, null, 12, "div", [["class", "aem"], ["role", "main"]], null, null, null, null, null)), (_l()(), i0.ɵeld(22, 0, null, null, 7, "div", [["class", "ain ahg"]], null, null, null, null, null)), (_l()(), i0.ɵeld(23, 0, null, null, 6, "div", [["class", "ais"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_BoletinDetalleComponent_2)), i0.ɵdid(25, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_BoletinDetalleComponent_3)), i0.ɵdid(27, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_BoletinDetalleComponent_4)), i0.ɵdid(29, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(30, 0, null, null, 2, "a", [["routerLink", "/boletines/9/1"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 31).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(31, 671744, null, 0, i4.RouterLinkWithHref, [i4.Router, i4.ActivatedRoute, i1.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵted(-1, null, ["Regresar"])), (_l()(), i0.ɵeld(33, 0, null, null, 0, "div", [["class", "ain"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_2 = "/home"; _ck(_v, 12, 0, currVal_2); var currVal_5 = "/boletines"; _ck(_v, 16, 0, currVal_5); var currVal_6 = _co.titulo; _ck(_v, 19, 0, currVal_6); var currVal_7 = _co.titulo; _ck(_v, 25, 0, currVal_7); var currVal_8 = (_co.total == 0); _ck(_v, 27, 0, currVal_8); var currVal_9 = (_co.total > 0); _ck(_v, 29, 0, currVal_9); var currVal_12 = "/boletines/9/1"; _ck(_v, 31, 0, currVal_12); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 12).target; var currVal_1 = i0.ɵnov(_v, 12).href; _ck(_v, 11, 0, currVal_0, currVal_1); var currVal_3 = i0.ɵnov(_v, 16).target; var currVal_4 = i0.ɵnov(_v, 16).href; _ck(_v, 15, 0, currVal_3, currVal_4); var currVal_10 = i0.ɵnov(_v, 31).target; var currVal_11 = i0.ɵnov(_v, 31).href; _ck(_v, 30, 0, currVal_10, currVal_11); }); }
export function View_BoletinDetalleComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-boletin-detalle", [], null, null, null, View_BoletinDetalleComponent_0, RenderType_BoletinDetalleComponent)), i0.ɵdid(1, 114688, null, 0, i5.BoletinDetalleComponent, [i4.ActivatedRoute, i6.BoletinesService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BoletinDetalleComponentNgFactory = i0.ɵccf("app-boletin-detalle", i5.BoletinDetalleComponent, View_BoletinDetalleComponent_Host_0, {}, {}, []);
export { BoletinDetalleComponentNgFactory as BoletinDetalleComponentNgFactory };
