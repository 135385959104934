import { OnInit } from '@angular/core';
import { Libros } from 'src/app/models/libros';
import { urlBackendUpload } from 'src/app/models/GLOBAL';
import { LibrosService } from 'src/app/services/libros/libros.service';
import { ActivatedRoute } from '@angular/router';
var LibroDetallesComponent = /** @class */ (function () {
    function LibroDetallesComponent(librosService, activatedRoute) {
        this.librosService = librosService;
        this.activatedRoute = activatedRoute;
        this.url = urlBackendUpload + 'libros/';
        this.libro = new Libros();
    }
    LibroDetallesComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.activatedRoute.params.subscribe(function (params) {
            var codigo = params["id"];
            _this.obtenerLibros(codigo);
        });
    };
    LibroDetallesComponent.prototype.obtenerLibros = function (libroId) {
        var _this = this;
        this.librosService.obtenerLibros(libroId).subscribe(function (response) {
            _this.libro = response.libros;
        });
    };
    return LibroDetallesComponent;
}());
export { LibroDetallesComponent };
