<app-breadcrumbs></app-breadcrumbs>

<div class="afx ake">
    <div role="main" class="aem">
        <div class="ain ahp">

            <div class="aji">
                <div class="aac">
                    <div class="acu">
                        <table class="table table-light">
                            <thead class="thead-light">
                                <tr>
                                    <th>N°</th>
                                    <th>LISTADO DE NOTARIOS QUE CUENTAN CON SERVICIO DE BIOMETRÍA Y SERVICIO DE LÍNEA</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let notario of notarios  | paginate: { itemsPerPage: 20, currentPage: p }; let i = index; ">
                                    <td style="width: 30%;">{{i+1}}</td>
                                    <td>{{notario.notario}}</td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colSpan="2">
                                        <nav class="adl" style="margin-bottom: 1%;">
                                            <pagination-controls [autoHide]="autoHide" [previousLabel]="labels.previousLabel" [nextLabel]="labels.nextLabel" (pageChange)="p = $event" class="text-center"></pagination-controls>
                                        </nav>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>

        </div>
        <div class="ain"></div>
    </div>
</div>
