import { OnInit } from '@angular/core';
import { VideosService } from 'src/app/services/videos/videos.service';
var PrensaVideosComponent = /** @class */ (function () {
    function PrensaVideosComponent(videosService) {
        this.videosService = videosService;
        // PAGINADO
        this.p = 1;
        this.autoHide = true;
        this.labels = {
            previousLabel: 'Anterior',
            nextLabel: 'Siguiente'
        };
        this.videos = [];
        this.url = 'https://i.ytimg.com/vi/';
    }
    PrensaVideosComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.videosService.cargarVideos().subscribe(function (response) {
            _this.videos = response.videos;
            _this.total = response.total;
        });
    };
    return PrensaVideosComponent;
}());
export { PrensaVideosComponent };
