import { Injectable } from '@angular/core';
import { urlBackend } from 'src/app/models/GLOBAL';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LibrosService {

  url: string = urlBackend;

  constructor(public http: HttpClient) { }

  cargarLibros(): Observable<any> {
    return this.http.get(this.url + 'libros');
  }

  obtenerLibros(librosId: number): Observable<any> {
    return this.http.get(this.url + 'libros/' + librosId);
  }
}
