import { urlBackend } from 'src/app/models/GLOBAL';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var NotariosService = /** @class */ (function () {
    function NotariosService(http) {
        this.http = http;
        this.url = urlBackend;
    }
    NotariosService.prototype.cargarNotarios = function () {
        return this.http.get(this.url + 'notarios');
    };
    NotariosService.prototype.cargarNotariosTodos = function () {
        return this.http.get(this.url + 'notariosLima');
    };
    NotariosService.prototype.buscarNotarioTodos = function (termino) {
        return this.http.get(this.url + "notarios/likeTodos/" + termino);
    };
    NotariosService.prototype.buscarNotarioEstado = function (estado) {
        return this.http.get(this.url + "notarios/likeEstado/" + estado);
    };
    NotariosService.prototype.listarPrecios = function () {
        return this.http.get(this.url + 'detalleServicio/listado');
    };
    NotariosService.prototype.listarPreciosOpcion = function () {
        return this.http.get(this.url + 'detalleServicio/opcion');
    };
    NotariosService.prototype.listaNotarios = function (sisev) {
        return this.http.get(this.url + "colegios/" + sisev);
    };
    NotariosService.prototype.listaNotariosPrecio = function (sisev) {
        return this.http.get(this.url + "notarios/precios/" + sisev);
    };
    NotariosService.prototype.buscarNotario = function (termino) {
        return this.http.get(this.url + 'notarios/likeServicios/' + termino);
    };
    NotariosService.prototype.buscarNotarioNuevo = function (termino) {
        return this.http.get(this.url + 'notarios/like/' + termino);
    };
    NotariosService.prototype.buscarNotarioDistrito = function (termino) {
        return this.http.get(this.url + 'notarios/distrito/' + termino);
    };
    NotariosService.prototype.cargarDistritos = function () {
        return this.http.get(this.url + "distritos");
    };
    NotariosService.prototype.obtenerNotarioServicio = function (notarioId) {
        return this.http.get(this.url + 'notarioServicio/' + notarioId);
    };
    NotariosService.prototype.obtenerNotarioServicioOpcion = function (notarioId) {
        return this.http.get(this.url + 'notarioServicioOpcion/' + notarioId);
    };
    NotariosService.prototype.cargarContactos = function (notarioId) {
        return this.http.get(this.url + 'listaContactos/' + notarioId);
    };
    NotariosService.prototype.obtenerNotario = function (notarioId) {
        return this.http.get(this.url + 'notarios/' + notarioId);
    };
    NotariosService.ngInjectableDef = i0.defineInjectable({ factory: function NotariosService_Factory() { return new NotariosService(i0.inject(i1.HttpClient)); }, token: NotariosService, providedIn: "root" });
    return NotariosService;
}());
export { NotariosService };
