import { Router } from '@angular/router';
import { OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { SharedService } from 'src/app/services/shared/shared.service';
var BusquedaNotarialComponent = /** @class */ (function () {
    function BusquedaNotarialComponent(sharedService, router) {
        this.sharedService = sharedService;
        this.router = router;
        this.myColegios = new FormControl();
        this.myDistritos = new FormControl();
        this.myNotarios = new FormControl();
        this.optionsColegios = [];
        this.optionsDistritos = [];
        this.optionsNotarios = [];
        // NOTARIOS
        this.notarios = [];
    }
    BusquedaNotarialComponent.prototype.ngOnInit = function () {
        this.cargarColegios();
        this.cargarDistritos();
        this.cargarNotarios(21);
    };
    BusquedaNotarialComponent.prototype.cargarColegios = function () {
        var _this = this;
        this.sharedService.getColegios().subscribe(function (response) {
            _this.optionsColegios = response.colegios;
            _this.filteredColegios = _this.myColegios.valueChanges
                .pipe(startWith(''), map(function (value) { return typeof value === 'string' ? value : value.colegios; }), map(function (colegios) { return colegios ? _this._filterColegios(colegios) : _this.optionsColegios.slice(); }));
        });
    };
    BusquedaNotarialComponent.prototype.cargarDistritos = function () {
        var _this = this;
        this.sharedService.getDistritos().subscribe(function (response) {
            _this.optionsDistritos = response.distritos;
            _this.filteredDistritos = _this.myDistritos.valueChanges
                .pipe(startWith(''), map(function (value) { return typeof value === 'string' ? value : value.nomdis; }), map(function (distritos) { return distritos ? _this._filterDistritos(distritos) : _this.optionsDistritos.slice(); }));
        });
    };
    BusquedaNotarialComponent.prototype.cargarNotarios = function (sisev) {
        var _this = this;
        this.sharedService.listarNotariosXcolegios(sisev).subscribe(function (response) {
            _this.optionsNotarios = response.notarios;
            _this.filteredNotarios = _this.myNotarios.valueChanges
                .pipe(startWith(''), map(function (value) { return typeof value === 'string' ? value : value.notario; }), map(function (notarios) { return notarios ? _this._filterNotarios(notarios) : _this.optionsNotarios.slice(); }));
        });
    };
    BusquedaNotarialComponent.prototype.displayColegios = function (colegio) {
        return colegio ? colegio.colegios : undefined;
    };
    BusquedaNotarialComponent.prototype.displayDistritos = function (distrito) {
        return distrito ? distrito.nomdis : undefined;
    };
    BusquedaNotarialComponent.prototype.displayNotarios = function (notario) {
        return notario ? notario.notario : undefined;
    };
    BusquedaNotarialComponent.prototype._filterColegios = function (colegios) {
        var _this = this;
        var filterValue = colegios.toLowerCase();
        this.sharedService.getColegios().subscribe(function (response) {
            _this.optionsColegios = response.colegios;
        });
        return this.optionsColegios.filter(function (option) { return option.colegios.toLowerCase().indexOf(filterValue) >= 0; });
    };
    BusquedaNotarialComponent.prototype._filterDistritos = function (distritos) {
        var _this = this;
        var filterValue = distritos.toLowerCase();
        this.sharedService.getDistritos().subscribe(function (response) {
            _this.optionsDistritos = response.distritos;
        });
        return this.optionsDistritos.filter(function (option) { return option.nomdis.toLowerCase().indexOf(filterValue) >= 0; });
    };
    BusquedaNotarialComponent.prototype._filterNotarios = function (notarios) {
        var _this = this;
        var filterValue = notarios.toLowerCase();
        this.sharedService.getNotarios().subscribe(function (response) {
            _this.optionsNotarios = response.notarios;
        });
        return this.optionsNotarios.filter(function (option) { return option.notario.toLowerCase().indexOf(filterValue) >= 0; });
    };
    BusquedaNotarialComponent.prototype.mostrarNotarios = function (option) {
        this.cargarNotarios(option.id);
    };
    BusquedaNotarialComponent.prototype.mostrarNotariosDistritos = function (distritoInput) {
        this.router.navigate(['distrito', distritoInput]);
    };
    BusquedaNotarialComponent.prototype.filtrarNotario = function (colegio, notario) {
        if (colegio != '') {
            if (notario != '') {
                this.router.navigate(['colegios', colegio, notario]);
            }
        }
        else {
            this.router.navigate(['notarios', notario]);
        }
    };
    return BusquedaNotarialComponent;
}());
export { BusquedaNotarialComponent };
