<section class="ain" *ngIf="total > 0">
  <aside>
    <h2><span>Galería</span></h2>
    <div class="ain agb">
      <div class="afs acq">
        <div class="ain aif">
          <div class="agg" *ngFor="let galeria of galerias">
            <sup>
              <a class="" [routerLink]="['/detalleGaleria', galeria.id]" title="{{galeria.titulo}}"
                style="margin-right: 5%;">
                <img [src]="url + galeria.imagen" alt="">
                <h3 class="text-center" style="margin-bottom: 12px;"><a [routerLink]="['/detalleGaleria', galeria.id]" style="color:#000;">{{galeria.titulo}}</a></h3>
              </a>
            </sup>
          </div>
        </div>
      </div>
    </div>
  </aside>
</section>
