<app-breadcrumbs></app-breadcrumbs>

<div class="afx ake">
  <div role="main" class="aem">
    <div class="ain ahp">
      <div class="aji">
        <div class="acu">

          <!-- LISTA DE PRECIOS NOTARIOS -->
          <div class="afx ake">
            <div role="main" class="aem">
              <div class="ain ahg">
                <h2>Relación actualizada de notarios según <a href="https://busquedas.elperuano.pe/normaslegales/ley-que-modifica-la-ley-29571-codigo-de-proteccion-y-defens-ley-n-30908-1734215-1/" target="blank">Ley 30908</a>, sobre libre elección del servicio notarial en los servicios financieros.</h2>
                <h3 style="margin-top: -2%; color: #56558c;">* Relación según información enviada por los señores notarios.</h3>


                <div class="aiq">
                  <div class="ajo pull-left aie">
                    <form class="example-form">
                      <mat-form-field class="example-full-width">
                        <input #input matInput placeholder="Buscar Notario" (keyup)="buscarNotario(input.value)" style="font-size: 20px;">
                      </mat-form-field>
                    </form>
                  </div>

                  <div class="agk pull-left">
                    <form class="example-form">
                      <select #estado (change)="buscarNotarioDistrito(estado.value)" class="form-control">
                        <option value="0">Todos los Distritos</option>
                        <option *ngFor="let distrito of distritos" [value]="distrito.nomdis">{{distrito.nomdis}}</option>
                      </select>
                    </form>
                  </div>
                </div>

                <div class="aap" *ngIf="total == 0">
                  <div style="float: left;width: 100%;" class="ady ait">Sin registros encontrados.</div>
                </div>

                <div class="aiq ais" *ngIf="total> 0">
                  <mat-accordion>
                    <mat-expansion-panel (opened)="panelOpenState= true" (closed)="panelOpenState= false" *ngFor="let notario of notarios">
                      <mat-expansion-panel-header (click)="obtenerServicios(notario.id)">
                        <mat-panel-title>
                          <span [id]="notario.id" class="panel-titulo"><strong>{{notario.notario}}</strong></span>
                        </mat-panel-title>
                        <mat-panel-description>
                          {{notario.nomdis}}
                        </mat-panel-description>
                      </mat-expansion-panel-header>

                      <table class="table" border="0">
                        <tbody>
                          <tr>
                            <td>
                              <a [routerLink]="[ '/listaContacto', notario.id ]" style="color:black; font-weight: bold;">
                                <i class="fa fa-user text-info" aria-hidden="true"></i> Datos del contacto
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <a (click)="descargarPDF(notario.colegiatura, notario.id)" class="pointer" style="color:black; font-weight: bold;">
                                <i class="fa fa-file-pdf-o text-danger" aria-hidden="true"></i> Precios de los
                                Servicios Notariales
                              </a>
                            </td>
                          </tr>
                        </tbody>
                      </table>

                    </mat-expansion-panel>
                  </mat-accordion>
                </div>
              </div>
              <div class="ain"></div>
            </div>
          </div>

        </div>
      </div>

    </div>
    <div class="ain"></div>
  </div>
</div>
