import { urlBackend } from '../../models/GLOBAL';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var EventosService = /** @class */ (function () {
    function EventosService(http) {
        this.http = http;
        this.url = urlBackend;
    }
    EventosService.prototype.cargarEventos = function () {
        return this.http.get(this.url + 'eventos');
    };
    EventosService.ngInjectableDef = i0.defineInjectable({ factory: function EventosService_Factory() { return new EventosService(i0.inject(i1.HttpClient)); }, token: EventosService, providedIn: "root" });
    return EventosService;
}());
export { EventosService };
