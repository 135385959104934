import { Injectable } from '@angular/core';
import { urlBackend, urlBackendUpload } from '../../models/GLOBAL';
import { Observable } from 'rxjs-compat';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SliderService {

  url: string = urlBackend;
  urlUpload: string = urlBackendUpload;

  constructor(public http: HttpClient) { }

  cargarSlider(): Observable<any>{
    return this.http.get(this.url + 'slider');
  }
}
