import { Router, ActivationEnd } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { Meta, Title } from '@angular/platform-browser';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/platform-browser";
var BreadcrumbsService = /** @class */ (function () {
    function BreadcrumbsService(router, meta, title) {
        this.router = router;
        this.meta = meta;
        this.title = title;
    }
    BreadcrumbsService.prototype.getDataRoute = function () {
        return this.router.events.pipe(filter(function (evento) { return evento instanceof ActivationEnd; }), filter(function (evento) { return evento.snapshot.firstChild === null; }), map(function (evento) { return evento.snapshot.data; }));
    };
    BreadcrumbsService.prototype.generateTag = function (config) {
        this.title.setTitle(config.title);
        this.meta.updateTag({ name: 'description', content: config.title });
        this.meta.updateTag({ property: 'og:title', content: config.title });
        this.meta.updateTag({ property: 'og:description', content: config.description });
        this.meta.updateTag({ property: 'og:image', content: config.image });
        this.meta.updateTag({ property: 'og:url', content: config.slug });
    };
    BreadcrumbsService.ngInjectableDef = i0.defineInjectable({ factory: function BreadcrumbsService_Factory() { return new BreadcrumbsService(i0.inject(i1.Router), i0.inject(i2.Meta), i0.inject(i2.Title)); }, token: BreadcrumbsService, providedIn: "root" });
    return BreadcrumbsService;
}());
export { BreadcrumbsService };
