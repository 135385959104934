<aside *ngIf="total > 0">
    <h2>
        <span>Noticias</span>
    </h2>

    <div class="flex-container">
        <div class="flexslider aax">
            <ul class="flex-slides">
                <li *ngFor="let noticia of noticias">
                  <div class="afa">
                    <div class="agj ais" style="margin-right: 5px;">
                        <img class="aeg" [src]="url + noticia.imagen" alt="">
                    </div>
                    <div class="agj">
                        <h2>{{noticia.titulo}}</h2>
                        <p style="text-align: justify;" [innerHTML]="noticia.desc_small | cadenas | htmlseguro"></p>
                        <p>
                            <a [routerLink]="['/detalleNoticias', noticia.id]" class="ahs aip aks">Leer mas »</a>
                        </p>
                    </div>
                  </div>
                </li>
            </ul>
        </div>
    </div>
</aside>
