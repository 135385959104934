<div class="afx ajh">
  <div id="aen" class="ain">
    <div class="agh">
      <h2 class="agl">{{pagina.titulo}}</h2>
    </div>
  </div>
</div>

<div class="afx ake" *ngIf="pagina">

  <div role="main" class="aem" *ngIf="pagina.menu_id !== '2' && pagina.submenu !== 1">
    <article class="aiq">
      <div class="aeg ahg text-center" *ngIf="pagina.imagen"><img [src]="url + pagina.imagen" alt=""></div>
      <p style="text-align: justify;" [innerHTML]="pagina.contenido"></p>
      <p style="text-align: justify;" *ngIf="pagina.archivo">Descargar: <a *ngIf="pagina.menu_id === 3" [href]="url + pagina.archivo" target="blank">Verificación Biométrica Notarios Puno.</a></p>
    </article>
    <div class="ain"></div>
  </div>

  <div role="main" class="aem" *ngIf="pagina.menu_id === 2">
    <div class="ady ajt ait" *ngIf="total === 0">Sin registros encontrados <a class="aiu" href="javascript:void(0)">×</a></div>
    <div class="aiq ais" id="ado" *ngIf="total > 0">
      <article class="aiq ais" *ngFor="let noticia of noticias">
        <h2 class="agl akm aic"><a [routerLink]="['/detalleNoticias', noticia.id]">{{noticia.titulo}}</a></h2>
        <div class="d18 ais aic aeg mr-10">
          <a [routerLink]="['/detalleNoticias', noticia.id]">
            <img alt="" [src]="urlNoticias + noticia.imagen">
          </a>
        </div>
        <p class="agl aec"><strong>{{noticia.fecha | fecha}}</strong></p>
        <div style="text-align: justify;margin-right: 3%;">
          <p [innerHTML]="noticia.desc_small | cadenas"></p>
        </div>
        <p class="agl"><a class="ahs aip aks" [routerLink]="['/detalleNoticias', noticia.id]">Leer más »</a></p>
        <hr>
      </article>
    </div>
  </div>

  <div role="main" class="aem" *ngIf="pagina.menu_id === 1 && pagina.submenu_id === 1">
      <div class="ady ajt ait" *ngIf="totalGaleria === 0">Sin registros encontrados <a class="aiu" href="javascript:void(0)">×</a></div>
      <div class="aiq ais" id="ado" *ngIf="totalGaleria > 0">
        <article class="aiq ais" *ngFor="let galeria of galerias">
          <h2 class="agl akm aic"><a [routerLink]="['/detalleGaleria', galeria.id]">{{galeria.titulo}}</a></h2>
          <div class="d18 ais aic aeg mr-10">
            <a [routerLink]="['/detalleGaleria', galeria.id]">
              <img alt="" [src]="urlGaleria + galeria.imagen">
            </a>
          </div>
          <hr>
        </article>
      </div>
    </div>

</div>
