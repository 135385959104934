<app-breadcrumbs></app-breadcrumbs>

<div class="afx ake">

    <div role="main" class="aem" *ngIf="total > 0">
        <article class="aiq ais" *ngFor="let noticia of noticias | paginate: { itemsPerPage: 8, currentPage: p }">
            <h2 class="agl akm aic"><a [routerLink]="['/detalleNoticias', noticia.id]">{{noticia.titulo}}</a></h2>
            <div class="d18 ais aic aeg mr-10">
                <a [routerLink]="['/detalleNoticias', noticia.id]"><img [src]="url + noticia.imagen" alt="" style="width: 220px;"></a>
            </div>
            <p class="agl adi aec">{{noticia.fecha | fecha}}</p>
            <div style="text-align: justify;margin-right: 3%;">
                <p [innerHTML]="noticia.desc_small | cadenasLargas | htmlseguro"></p>
            </div>
            <p class="agl"><a [routerLink]="['/detalleNoticias', noticia.id]" class="ahs aip aks">Leer más »</a></p>
            <hr>
        </article>
        <nav class="adl" style="margin-bottom: 1%;">
            <pagination-controls [autoHide]="autoHide" [previousLabel]="labels.previousLabel" [nextLabel]="labels.nextLabel" (pageChange)="p = $event" class="text-center"></pagination-controls>
        </nav>
    </div>

</div>
