import { Component, OnInit } from '@angular/core';
import { Menu } from '../../models/menu';
import { SubMenu } from '../../models/submenu';
import { Router } from '@angular/router';
import { MenuService } from 'src/app/services/menu/menu.service';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styles: []
})
export class NavComponent implements OnInit {

  menus:Menu[]=[];
  submenus: SubMenu;

  constructor(private menuService: MenuService) { }

  ngOnInit() {
    this.cargarMenu();
  }

  cargarMenu(){
    this.menuService.cargarMenu().subscribe(response=>{
      this.menus = response.menus;
    });
  }

  cargarSubMenu(id:string){
    this.menuService.cargarSubMenu(id).subscribe(response=>{
      this.submenus = response.submenus;
    });
  }

  submenu(event, menuId:string){
    if (event === 'mouseover') {
      this.cargarSubMenu(menuId);
    }
  }

  menuResponsive(){
    var x = document.getElementById("myTopnav");
    if (x.className === "topnav") {
      x.className += " responsive";
    } else {
      x.className = "topnav";
    }
  }
}
