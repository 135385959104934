import { OnInit } from '@angular/core';
import { BoletinesService } from '../../services/boletines/boletines.service';
var PrensaBoletinesComponent = /** @class */ (function () {
    function PrensaBoletinesComponent(boletinService) {
        this.boletinService = boletinService;
    }
    PrensaBoletinesComponent.prototype.ngOnInit = function () {
        this.cargarBoletin();
    };
    PrensaBoletinesComponent.prototype.cargarBoletin = function () {
        var _this = this;
        this.boletinService.cargarBoletines().subscribe(function (response) {
            _this.boletines = response.boletines;
            _this.total = response.total;
        });
    };
    return PrensaBoletinesComponent;
}());
export { PrensaBoletinesComponent };
