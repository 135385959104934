import { PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
var HtmlseguroPipe = /** @class */ (function () {
    function HtmlseguroPipe(_sanitizer) {
        this._sanitizer = _sanitizer;
    }
    HtmlseguroPipe.prototype.transform = function (v) {
        return this._sanitizer.bypassSecurityTrustHtml(v);
    };
    return HtmlseguroPipe;
}());
export { HtmlseguroPipe };
