/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./topbar.component";
var styles_TopbarComponent = [];
var RenderType_TopbarComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TopbarComponent, data: {} });
export { RenderType_TopbarComponent as RenderType_TopbarComponent };
export function View_TopbarComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["id", "ahl"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "div", [["class", "ain"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "div", [["class", "aex"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "ul", [["class", "ahn"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 0, "li", [["style", "margin: 0px;"]], null, null, null, null, null))], null, null); }
export function View_TopbarComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-topbar", [], null, null, null, View_TopbarComponent_0, RenderType_TopbarComponent)), i0.ɵdid(1, 114688, null, 0, i1.TopbarComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TopbarComponentNgFactory = i0.ɵccf("app-topbar", i1.TopbarComponent, View_TopbarComponent_Host_0, {}, {}, []);
export { TopbarComponentNgFactory as TopbarComponentNgFactory };
