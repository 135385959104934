import { Component, OnInit, DoCheck } from '@angular/core';
import { Galeria } from '../../models/galeria';
import { ImagenesGaleria } from '../../models/imagenesGaleria';
import { ActivatedRoute } from '@angular/router';
import { GaleriaService } from 'src/app/services/galeria/galeria.service';
import { urlBackendUpload } from 'src/app/models/GLOBAL';
declare function init_imagen(): any;
declare var $: any;

@Component({
  selector: 'app-galeria-detalle',
  templateUrl: './galeria-detalle.component.html',
  styles: []
})
export class GaleriaDetalleComponent implements OnInit, DoCheck {

  galeria: Galeria = new Galeria();
  imagenes: ImagenesGaleria = new ImagenesGaleria();
  total: number;
  totalImagen: number;
  url: string = urlBackendUpload + 'galeria/';
  urlImagen: string = urlBackendUpload + 'imagenesGaleria/';

  // PAGINADO
  p: number = 1;
  public autoHide: boolean = true;
  public labels: any = {
    previousLabel: 'Anterior',
    nextLabel: 'Siguiente'
  };

  constructor(
    public galeriaService: GaleriaService,
    public activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit() {
    init_imagen();
    this.activatedRoute.params.subscribe(params=>{
      let codigo = params['id'];
      this.obtenerGaleria(codigo);
      this.obtenerImagenes(codigo);
    })

  }

  ngDoCheck(){
    $(".acm").nivoLightbox({
      effect: "fade",
      theme: "default",
      keyboardNav: !0,
      errorMessage:
        "El contenido solicitado no se puede cargar. Por favor, inténtelo de nuevo más tarde."
    });
  }

  cargarScript(){
    setTimeout(() => {
      $(".acm").nivoLightbox({
        effect: "fade",
        theme: "default",
        keyboardNav: !0,
        errorMessage:
          "El contenido solicitado no se puede cargar. Por favor, inténtelo de nuevo más tarde."
      });
    }, 1);
  }

  obtenerGaleria(id:string){
    this.galeriaService.obtenerGaleria(id).subscribe(response=>{
      this.galeria = response.galerias;
      this.total = response.total;
      this.cargarScript();
    });
  }

  obtenerImagenes(idGaleria:string){
    this.galeriaService.obtenerImagen(idGaleria).subscribe(response=>{
      this.imagenes = response.imagenes;
      this.totalImagen = response.total;
      this.cargarScript();
    })
  }

}
