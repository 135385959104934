import { OnInit } from '@angular/core';
import { Noticia } from '../../models/noticias';
import { Imagenes } from '../../models/imagenes';
import { ActivatedRoute } from '@angular/router';
import { urlBackendUpload } from 'src/app/models/GLOBAL';
import { BreadcrumbsService } from 'src/app/services/shared/breadcrumbs.service';
import { NoticiasService } from 'src/app/services/noticias/noticias.service';
var DetalleNoticiaComponent = /** @class */ (function () {
    function DetalleNoticiaComponent(noticiasService, activatedRoute, breadcrumbsService) {
        this.noticiasService = noticiasService;
        this.activatedRoute = activatedRoute;
        this.breadcrumbsService = breadcrumbsService;
        this.noticias = new Noticia();
        this.imagenes = new Imagenes();
        this.url = urlBackendUpload + 'noticias/';
        this.urlImagen = urlBackendUpload + 'imagenes/';
    }
    DetalleNoticiaComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.activatedRoute.params.subscribe(function (params) {
            var codigo = params['id'];
            _this.obtenerNoticias(codigo);
            _this.obtenerImagenes(codigo);
            init_slider();
        });
    };
    DetalleNoticiaComponent.prototype.obtenerNoticias = function (id) {
        var _this = this;
        this.noticiasService.obtenerNoticias(id).subscribe(function (response) {
            _this.noticias = response.noticias;
            _this.total = response.total;
            _this.breadcrumbsService.generateTag({
                title: _this.noticias.titulo,
                description: _this.noticias.desc_small,
                image: _this.url + _this.noticias.imagen,
                slug: 'http://www.notarios.org.pe/#/detalleNoticias/' + _this.noticias.id
            });
        });
    };
    DetalleNoticiaComponent.prototype.obtenerImagenes = function (idNoticia) {
        var _this = this;
        this.noticiasService.obtenerImagen(idNoticia).subscribe(function (response) {
            _this.imagenes = response.imagenes;
            _this.totalImagen = response.total;
        });
    };
    return DetalleNoticiaComponent;
}());
export { DetalleNoticiaComponent };
