import { OnInit } from '@angular/core';
import { BoletinesService } from '../../services/boletines/boletines.service';
import { ActivatedRoute } from '@angular/router';
import { urlBackendUpload } from 'src/app/models/GLOBAL';
var BoletinDetalleComponent = /** @class */ (function () {
    function BoletinDetalleComponent(activatedRoute, boletinService) {
        this.activatedRoute = activatedRoute;
        this.boletinService = boletinService;
        this.url = urlBackendUpload + 'boletines/';
    }
    BoletinDetalleComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.activatedRoute.params.subscribe(function (params) {
            var codigo = params['id'];
            _this.obtenerBoletin(codigo);
            _this.boletinService.obtenerBoletinDetalle(codigo).subscribe(function (response) {
                _this.boletinDetalles = response.boletines;
                _this.total = response.total;
            });
        });
    };
    BoletinDetalleComponent.prototype.obtenerBoletin = function (id) {
        var _this = this;
        this.boletinService.obtenerBoletin(id).subscribe(function (response) {
            _this.titulo = response.boletines.titulo;
        });
    };
    return BoletinDetalleComponent;
}());
export { BoletinDetalleComponent };
