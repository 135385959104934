import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cadenas'
})
export class CadenasPipe implements PipeTransform {

  cadenas:string;
  transform(value: any): any {
    this.cadenas = value.substr(0,500);
    return this.cadenas + '...';
  }

}
