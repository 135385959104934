import { Component, OnInit } from '@angular/core';
import { Videos } from '../../models/videos';
import { VideosService } from 'src/app/services/videos/videos.service';

@Component({
  selector: 'app-prensa-videos',
  templateUrl: './prensa-videos.component.html',
  styles: []
})
export class PrensaVideosComponent implements OnInit {

  // PAGINADO
  p: number = 1;
  public autoHide: boolean = true;
  public labels: any = {
        previousLabel: 'Anterior',
        nextLabel: 'Siguiente'
    };

  videos: Videos[] = [];
  total: number;
  url: string;

  constructor(public videosService: VideosService) {
    this.url = 'https://i.ytimg.com/vi/';
  }

  ngOnInit() {
    this.videosService.cargarVideos().subscribe(response=>{
      this.videos = response.videos;
      this.total = response.total;
    });
  }

}
