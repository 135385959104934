import { Injectable } from '@angular/core';
import { urlBackend } from 'src/app/models/GLOBAL';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MovilService {

  url: string = urlBackend;

  constructor(public http: HttpClient) { }

  cargarMovil():Observable<any>{
    return this.http.get(this.url + 'movil');
  }
}
