import { OnInit } from '@angular/core';
import { EventosService } from 'src/app/services/eventos/eventos.service';
import { urlBackendUpload } from 'src/app/models/GLOBAL';
var EventosComponent = /** @class */ (function () {
    function EventosComponent(eventosService) {
        this.eventosService = eventosService;
        this.eventos = [];
        this.url = urlBackendUpload + 'eventos/';
    }
    EventosComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.eventosService.cargarEventos().subscribe(function (response) {
            _this.eventos = response.eventos;
            _this.total = response.total;
        });
    };
    return EventosComponent;
}());
export { EventosComponent };
