<footer role="contentinfo" id="aff" class="ain aif">
    <div class="agg agm">
        <h3 class="text-center">
            <a [routerLink]="['/home']" *ngIf="empresas.imagen">
                <img class="img1 logo-footer" [src]="url + empresas.imagen" alt="">
            </a>
        </h3>
        <p class="text-center text-sm-center text-md-left">
            {{empresas.descripcion}}
        </p>
    </div>
    <div class="agg agm">
        <p [innerHTML]="empresas.horario | htmlseguro"></p>
    </div>
    <!-- <div class="agg">
      <iframe [src]="urlFacebook | domseguro" width="100%" height="200" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowTransparency="true"></iframe>
  </div> -->
    <div class="agg">
        <h6 class="aie">CONTACTO</h6>
        <p class="agl">
            <i class="fa fa-map-marker"></i> {{empresas.direccion}}
        </p>
        <p><i class="fa fa-phone"></i> {{empresas.telefono}} </p>
        <p><i class="fa fa-envelope"></i> <a href="mailTo:{{empresas.correo}}" style="color: white;"> {{empresas.correo}}</a> </p>
    </div>

</footer>
<div id="ael" class="ain">
    <div class="agh">Todos los derechos reservados © {{ fecha | date:'yyyy'}} - {{empresas.colegio}}
    </div>
</div>
