import { urlBackend } from '../../models/GLOBAL';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var MenuService = /** @class */ (function () {
    function MenuService(http) {
        this.http = http;
        this.url = urlBackend;
    }
    MenuService.prototype.cargarMenu = function () {
        return this.http.get(this.url + 'menu');
    };
    MenuService.prototype.cargarSubMenu = function (id) {
        return this.http.get(this.url + 'submenu/' + id);
    };
    MenuService.ngInjectableDef = i0.defineInjectable({ factory: function MenuService_Factory() { return new MenuService(i0.inject(i1.HttpClient)); }, token: MenuService, providedIn: "root" });
    return MenuService;
}());
export { MenuService };
