/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/common";
import * as i3 from "./album.component";
import * as i4 from "../../services/galeria/galeria.service";
var styles_AlbumComponent = [];
var RenderType_AlbumComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AlbumComponent, data: {} });
export { RenderType_AlbumComponent as RenderType_AlbumComponent };
function View_AlbumComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "div", [["class", "agg"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 9, "sup", [], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 8, "a", [["class", ""], ["style", "margin-right: 5%;"]], [[8, "title", 0], [1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 3).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(3, 671744, null, 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i0.ɵpad(4, 2), (_l()(), i0.ɵeld(5, 0, null, null, 0, "img", [["alt", ""]], [[8, "src", 4]], null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 4, "h3", [["class", "text-center"], ["style", "margin-bottom: 12px;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 3, "a", [["style", "color:#000;"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 8).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(8, 671744, null, 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i0.ɵpad(9, 2), (_l()(), i0.ɵted(10, null, ["", ""]))], function (_ck, _v) { var currVal_3 = _ck(_v, 4, 0, "/detalleGaleria", _v.context.$implicit.id); _ck(_v, 3, 0, currVal_3); var currVal_7 = _ck(_v, 9, 0, "/detalleGaleria", _v.context.$implicit.id); _ck(_v, 8, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "", _v.context.$implicit.titulo, ""); var currVal_1 = i0.ɵnov(_v, 3).target; var currVal_2 = i0.ɵnov(_v, 3).href; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); var currVal_4 = (_co.url + _v.context.$implicit.imagen); _ck(_v, 5, 0, currVal_4); var currVal_5 = i0.ɵnov(_v, 8).target; var currVal_6 = i0.ɵnov(_v, 8).href; _ck(_v, 7, 0, currVal_5, currVal_6); var currVal_8 = _v.context.$implicit.titulo; _ck(_v, 10, 0, currVal_8); }); }
function View_AlbumComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "section", [["class", "ain"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 8, "aside", [], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "h2", [], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Galer\u00EDa"])), (_l()(), i0.ɵeld(5, 0, null, null, 4, "div", [["class", "ain agb"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 3, "div", [["class", "afs acq"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 2, "div", [["class", "ain aif"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AlbumComponent_2)), i0.ɵdid(9, 278528, null, 0, i2.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.galerias; _ck(_v, 9, 0, currVal_0); }, null); }
export function View_AlbumComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_AlbumComponent_1)), i0.ɵdid(1, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.total > 0); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_AlbumComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-album", [], null, null, null, View_AlbumComponent_0, RenderType_AlbumComponent)), i0.ɵdid(1, 114688, null, 0, i3.AlbumComponent, [i4.GaleriaService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AlbumComponentNgFactory = i0.ɵccf("app-album", i3.AlbumComponent, View_AlbumComponent_Host_0, {}, {}, []);
export { AlbumComponentNgFactory as AlbumComponentNgFactory };
