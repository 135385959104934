import { OnInit } from '@angular/core';
import { urlBackendUpload } from 'src/app/models/GLOBAL';
import { GaleriaService } from 'src/app/services/galeria/galeria.service';
var AlbumComponent = /** @class */ (function () {
    function AlbumComponent(galeriaService) {
        this.galeriaService = galeriaService;
        this.galerias = [];
        this.url = urlBackendUpload + 'galeria/';
    }
    AlbumComponent.prototype.ngOnInit = function () {
        this.cargarGaleria();
    };
    AlbumComponent.prototype.cargarGaleria = function () {
        var _this = this;
        this.galeriaService.cargarGaleria().subscribe(function (response) {
            _this.galerias = response.galerias;
            _this.total = response.total;
        });
    };
    return AlbumComponent;
}());
export { AlbumComponent };
