import { PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
var DomseguroPipe = /** @class */ (function () {
    function DomseguroPipe(domSanitizer) {
        this.domSanitizer = domSanitizer;
    }
    DomseguroPipe.prototype.transform = function (value, url) {
        return this.domSanitizer.bypassSecurityTrustResourceUrl(value + url);
    };
    return DomseguroPipe;
}());
export { DomseguroPipe };
