import { OnInit, DoCheck } from '@angular/core';
import { Galeria } from '../../models/galeria';
import { ImagenesGaleria } from '../../models/imagenesGaleria';
import { ActivatedRoute } from '@angular/router';
import { GaleriaService } from 'src/app/services/galeria/galeria.service';
import { urlBackendUpload } from 'src/app/models/GLOBAL';
var GaleriaDetalleComponent = /** @class */ (function () {
    function GaleriaDetalleComponent(galeriaService, activatedRoute) {
        this.galeriaService = galeriaService;
        this.activatedRoute = activatedRoute;
        this.galeria = new Galeria();
        this.imagenes = new ImagenesGaleria();
        this.url = urlBackendUpload + 'galeria/';
        this.urlImagen = urlBackendUpload + 'imagenesGaleria/';
        // PAGINADO
        this.p = 1;
        this.autoHide = true;
        this.labels = {
            previousLabel: 'Anterior',
            nextLabel: 'Siguiente'
        };
    }
    GaleriaDetalleComponent.prototype.ngOnInit = function () {
        var _this = this;
        init_imagen();
        this.activatedRoute.params.subscribe(function (params) {
            var codigo = params['id'];
            _this.obtenerGaleria(codigo);
            _this.obtenerImagenes(codigo);
        });
    };
    GaleriaDetalleComponent.prototype.ngDoCheck = function () {
        $(".acm").nivoLightbox({
            effect: "fade",
            theme: "default",
            keyboardNav: !0,
            errorMessage: "El contenido solicitado no se puede cargar. Por favor, inténtelo de nuevo más tarde."
        });
    };
    GaleriaDetalleComponent.prototype.cargarScript = function () {
        setTimeout(function () {
            $(".acm").nivoLightbox({
                effect: "fade",
                theme: "default",
                keyboardNav: !0,
                errorMessage: "El contenido solicitado no se puede cargar. Por favor, inténtelo de nuevo más tarde."
            });
        }, 1);
    };
    GaleriaDetalleComponent.prototype.obtenerGaleria = function (id) {
        var _this = this;
        this.galeriaService.obtenerGaleria(id).subscribe(function (response) {
            _this.galeria = response.galerias;
            _this.total = response.total;
            _this.cargarScript();
        });
    };
    GaleriaDetalleComponent.prototype.obtenerImagenes = function (idGaleria) {
        var _this = this;
        this.galeriaService.obtenerImagen(idGaleria).subscribe(function (response) {
            _this.imagenes = response.imagenes;
            _this.totalImagen = response.total;
            _this.cargarScript();
        });
    };
    return GaleriaDetalleComponent;
}());
export { GaleriaDetalleComponent };
