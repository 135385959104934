import { DatePipe } from '@angular/common';
import { PipeTransform } from '@angular/core';
var DiasPipe = /** @class */ (function () {
    function DiasPipe() {
    }
    DiasPipe.prototype.transform = function (value) {
        var datePie = new DatePipe('en-US').transform(value, 'EEEE');
        switch (datePie) {
            case 'Monday':
                this.diasEspañol = 'Lunes';
                break;
            case 'Tuesday':
                this.diasEspañol = 'Martes';
                break;
            case 'Wednesday':
                this.diasEspañol = 'Miércoles';
                break;
            case 'Thursday':
                this.diasEspañol = 'Jueves';
                break;
            case 'Friday':
                this.diasEspañol = 'Viernes';
                break;
            case 'Saturday':
                this.diasEspañol = 'Sábado';
                break;
            case 'Sunday':
                this.diasEspañol = 'Domingo';
                break;
            default:
                this.diasEspañol = '';
                break;
        }
        return this.diasEspañol;
    };
    return DiasPipe;
}());
export { DiasPipe };
