import { Component, OnInit } from '@angular/core';
import { LibrosService } from 'src/app/services/libros/libros.service';
import { Libros } from 'src/app/models/libros';
import { urlBackendUpload } from 'src/app/models/GLOBAL';
import { Router } from '@angular/router';

@Component({
  selector: 'app-libros',
  templateUrl: './libros.component.html',
  styles: []
})
export class LibrosComponent implements OnInit {

  libros: Libros[] = [];
  total: number = 0;
  url: string = urlBackendUpload + 'libros/';

  constructor(public librosService: LibrosService, public router:Router) { }

  ngOnInit() {
    this.cargarLibros();
  }

  cargarLibros(){
    this.librosService.cargarLibros().subscribe(response=>{
      this.libros = response.libros;
      this.total = response.total;
    });
  }

  detalle(libroId:number){
    this.router.navigate(['/libroDetalles', libroId]);
  }

}
