import { OnInit } from '@angular/core';
import { NotariosService } from './../../services/notarios/notarios.service';
var NotariosComponent = /** @class */ (function () {
    function NotariosComponent(notariosService) {
        this.notariosService = notariosService;
        this.notarios = [];
        // PAGINADO
        this.p = 1;
        this.autoHide = true;
        this.labels = {
            previousLabel: 'Anterior',
            nextLabel: 'Siguiente'
        };
    }
    NotariosComponent.prototype.ngOnInit = function () {
        this.cargarNotarios();
    };
    NotariosComponent.prototype.cargarNotarios = function () {
        var _this = this;
        this.notariosService.cargarNotarios().subscribe(function (response) {
            _this.notarios = response.notarios;
        });
    };
    return NotariosComponent;
}());
export { NotariosComponent };
