import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cadenasCortas'
})
export class CadenasCortasPipe implements PipeTransform {

  cadenas:string;
  transform(value: any): any {
    this.cadenas = value.substr(0,25);
    return this.cadenas + '...';
  }

}
