import { OnInit } from '@angular/core';
import { LibrosService } from 'src/app/services/libros/libros.service';
import { urlBackendUpload } from 'src/app/models/GLOBAL';
import { Router } from '@angular/router';
var LibrosComponent = /** @class */ (function () {
    function LibrosComponent(librosService, router) {
        this.librosService = librosService;
        this.router = router;
        this.libros = [];
        this.total = 0;
        this.url = urlBackendUpload + 'libros/';
    }
    LibrosComponent.prototype.ngOnInit = function () {
        this.cargarLibros();
    };
    LibrosComponent.prototype.cargarLibros = function () {
        var _this = this;
        this.librosService.cargarLibros().subscribe(function (response) {
            _this.libros = response.libros;
            _this.total = response.total;
        });
    };
    LibrosComponent.prototype.detalle = function (libroId) {
        this.router.navigate(['/libroDetalles', libroId]);
    };
    return LibrosComponent;
}());
export { LibrosComponent };
