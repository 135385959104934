/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../shared/breadcrumbs/breadcrumbs.component.ngfactory";
import * as i2 from "../../shared/breadcrumbs/breadcrumbs.component";
import * as i3 from "../../services/shared/breadcrumbs.service";
import * as i4 from "@angular/platform-browser";
import * as i5 from "./contacto.component";
import * as i6 from "../../services/mensajes/mensajes.service";
var styles_ContactoComponent = [];
var RenderType_ContactoComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ContactoComponent, data: {} });
export { RenderType_ContactoComponent as RenderType_ContactoComponent };
export function View_ContactoComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-breadcrumbs", [], null, null, null, i1.View_BreadcrumbsComponent_0, i1.RenderType_BreadcrumbsComponent)), i0.ɵdid(1, 114688, null, 0, i2.BreadcrumbsComponent, [i3.BreadcrumbsService, i4.Title, i4.Meta], null, null), (_l()(), i0.ɵeld(2, 0, null, null, 6, "div", [["class", "afx ake"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 5, "div", [["class", "aem"], ["role", "main"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 3, "section", [["class", "ain ahp"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 2, "div", [["class", "ais"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 1, "div", [["class", "akr ahg"], ["style", "margin-bottom: 8%;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 0, "iframe", [["allowfullscreen", ""], ["class", "aiq"], ["frameborder", "0"], ["height", "450"], ["src", "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d959.5648073668801!2d-70.03004917083081!3d-15.842966999313823!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x915d69ec6284226b%3A0xf5840955887866f2!2sColegio+de+Notarios+de+Puno!5e0!3m2!1ses!2spe!4v1563395388960!5m2!1ses!2spe"], ["style", "border:0"], ["width", "600"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 0, "div", [["class", "ain"]], null, null, null, null, null))], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_ContactoComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-contacto", [], null, null, null, View_ContactoComponent_0, RenderType_ContactoComponent)), i0.ɵdid(1, 114688, null, 0, i5.ContactoComponent, [i6.MensajesService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ContactoComponentNgFactory = i0.ɵccf("app-contacto", i5.ContactoComponent, View_ContactoComponent_Host_0, {}, {}, []);
export { ContactoComponentNgFactory as ContactoComponentNgFactory };
