import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotariosService } from '../../../services/notarios/notarios.service';
import { Contacto } from 'src/app/models/contacto';
import { Notarios } from 'src/app/models/notarios';
import { urlBackendUpload } from 'src/app/models/GLOBAL';
import swal from 'sweetalert';
@Component({
  selector: 'app-lista-contactos',
  templateUrl: './lista-contactos.component.html',
  styles: []
})
export class ListaContactosComponent implements OnInit {

  contactos: Contacto[] = [];
  abogados: Contacto[] = [];
  total: number = 0;
  totalAbogado: number = 0;
  notario: Notarios = new Notarios();

  contactoArray: any[] = [];
  contactoFor: any[] = [];

  abogadoArray: any[] = [];
  abogadoFor: any[] = [];

  url: string = urlBackendUpload + 'listaprecios/';

  constructor(
    public activatedRoute: ActivatedRoute,
    public notariosService: NotariosService,
    public router: Router,
    private route:ActivatedRoute,
  ) { }

  ngOnInit() {
    this.cargarMapa();
  }

  descargarPDF(colegiatura: number) {
    if (colegiatura === 0) {
      swal('Sin datos encontrados');
      return;
    }
    window.open(this.url + colegiatura + '.pdf', '_blank');
  }

  cargarMapa() {
    this.route.params.subscribe(parametros=>{
      this.notariosService.obtenerNotario(parametros['id']).subscribe(response=>{
        console.log(response);
        this.notario = response.notarios;
      });
    });
  }

}
