import { PipeTransform } from '@angular/core';
var CadenasLargasPipe = /** @class */ (function () {
    function CadenasLargasPipe() {
    }
    CadenasLargasPipe.prototype.transform = function (value) {
        this.cadenas = value.substr(0, 400);
        return this.cadenas + '...';
    };
    return CadenasLargasPipe;
}());
export { CadenasLargasPipe };
