var Empresas = /** @class */ (function () {
    function Empresas(id, colegio, descripcion, horario, correo, telefono, direccion, imagen) {
        this.id = id;
        this.colegio = colegio;
        this.descripcion = descripcion;
        this.horario = horario;
        this.correo = correo;
        this.telefono = telefono;
        this.direccion = direccion;
        this.imagen = imagen;
    }
    return Empresas;
}());
export { Empresas };
