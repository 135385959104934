import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { urlBackendUpload } from 'src/app/models/GLOBAL';
import { Paginas } from 'src/app/models/paginas';
import { Noticia } from 'src/app/models/noticias';
import { PaginasService } from 'src/app/services/paginas/paginas.service';
import { NoticiasService } from 'src/app/services/noticias/noticias.service';
import { Galeria } from 'src/app/models/galeria';
import { GaleriaService } from 'src/app/services/galeria/galeria.service';

@Component({
  selector: 'app-paginas',
  templateUrl: './paginas.component.html',
  styles: []
})
export class PaginasComponent implements OnInit {

  pagina: Paginas = new Paginas();
  url: string = urlBackendUpload + 'paginas/';
  busqueda: boolean = true;

  noticias: Noticia[] = [];
  total: number = 0;
  urlNoticias: string = urlBackendUpload + 'noticias/';

  galerias: Galeria[] = [];
  galeriasPortada: Galeria[] = [];
  totalGaleria: number = 0;
  totalPortada: number = 0;
  urlGaleria: string = urlBackendUpload + 'galeria/';

  constructor(
    private activatedRoute: ActivatedRoute,
    private paginasService: PaginasService,
    public noticiasService: NoticiasService,
    public galeriaService: GaleriaService,
  ) {}

  ngOnInit() {
    this.activatedRoute.params.subscribe(params=>{
      let menuId = params['menuId'];
      let submenuId = params['submenuId'];

      if (submenuId === '0') {
        this.cargarPaginaMenu(menuId);
      } else {
        this.cargarPaginaSubMenu(submenuId);
      }
     });
  }

  cargarPaginaSubMenu(submenuId: number) {
    this.paginasService.cargarPaginasSubMenu(submenuId).subscribe(response => {
      this.pagina = response.paginas;
      this.cargarGaleria();
      this.cargarGaleriaPortada();
    }, error => {
      if (error.error.code === 404) {
        console.log(error.error.message);
      }
    });
  }

  cargarPaginaMenu(menuId: number) {
    this.paginasService.cargarPaginasMenu(menuId).subscribe(response => {
      this.pagina = response.paginas;
      this.cargarNoticias();
    }, error => {
      if (error.error.code === 404) {
        console.log(error.error.message);
      }
    });
  }

  cargarNoticias() {
    this.noticiasService.cargarNoticias().subscribe(response => {
      this.noticias = response.noticias;
      this.total = response.total;
    });
  }

  cargarGaleria(){
    this.galeriaService.cargarGaleria().subscribe(response=>{
      this.galerias = response.galerias;
      this.totalGaleria = response.total;
    });
  }

  cargarGaleriaPortada(){
    this.galeriaService.cargarGaleriaPortada().subscribe(response=>{
      this.galeriasPortada = response.galerias;
      this.totalPortada = response.total;
    });
  }
}
