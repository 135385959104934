/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/common";
import * as i3 from "./breadcrumbs.component";
import * as i4 from "../../services/shared/breadcrumbs.service";
import * as i5 from "@angular/platform-browser";
var styles_BreadcrumbsComponent = [];
var RenderType_BreadcrumbsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_BreadcrumbsComponent, data: {} });
export { RenderType_BreadcrumbsComponent as RenderType_BreadcrumbsComponent };
export function View_BreadcrumbsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 16, "div", [["class", "afx ajh"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 15, "div", [["class", "ain"], ["id", "aen"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "div", [["class", "agh"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "h2", [["class", "agl"]], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, ["", ""])), (_l()(), i0.ɵeld(5, 0, null, null, 11, "nav", [["class", "aex adv"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 10, "ul", [], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 3, "li", [], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 2, "a", [["routerLink", "/home"], ["title", "Inicio"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 9).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(9, 671744, null, 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵted(-1, null, ["Inicio"])), (_l()(), i0.ɵeld(11, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 1, "a", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["P\u00E1gina"])), (_l()(), i0.ɵeld(14, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i0.ɵeld(15, 0, null, null, 1, "a", [["class", "titulo"]], [[8, "title", 0]], null, null, null, null)), (_l()(), i0.ɵted(16, null, ["", ""]))], function (_ck, _v) { var currVal_3 = "/home"; _ck(_v, 9, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.label; _ck(_v, 4, 0, currVal_0); var currVal_1 = i0.ɵnov(_v, 9).target; var currVal_2 = i0.ɵnov(_v, 9).href; _ck(_v, 8, 0, currVal_1, currVal_2); var currVal_4 = i0.ɵinlineInterpolate(1, "", _co.label, ""); _ck(_v, 15, 0, currVal_4); var currVal_5 = _co.label; _ck(_v, 16, 0, currVal_5); }); }
export function View_BreadcrumbsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-breadcrumbs", [], null, null, null, View_BreadcrumbsComponent_0, RenderType_BreadcrumbsComponent)), i0.ɵdid(1, 114688, null, 0, i3.BreadcrumbsComponent, [i4.BreadcrumbsService, i5.Title, i5.Meta], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BreadcrumbsComponentNgFactory = i0.ɵccf("app-breadcrumbs", i3.BreadcrumbsComponent, View_BreadcrumbsComponent_Host_0, {}, {}, []);
export { BreadcrumbsComponentNgFactory as BreadcrumbsComponentNgFactory };
