/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/common";
import * as i3 from "ngx-pagination";
import * as i4 from "../../../../node_modules/ngx-pagination/dist/ngx-pagination.ngfactory";
import * as i5 from "../../pipes/fecha.pipe";
import * as i6 from "../../pipes/htmlseguro.pipe";
import * as i7 from "@angular/platform-browser";
import * as i8 from "../../pipes/cadenas-largas.pipe";
import * as i9 from "../../shared/breadcrumbs/breadcrumbs.component.ngfactory";
import * as i10 from "../../shared/breadcrumbs/breadcrumbs.component";
import * as i11 from "../../services/shared/breadcrumbs.service";
import * as i12 from "./prensa-noticias.component";
import * as i13 from "../../services/noticias/noticias.service";
var styles_PrensaNoticiasComponent = [];
var RenderType_PrensaNoticiasComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PrensaNoticiasComponent, data: {} });
export { RenderType_PrensaNoticiasComponent as RenderType_PrensaNoticiasComponent };
function View_PrensaNoticiasComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 23, "article", [["class", "aiq ais"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "h2", [["class", "agl akm aic"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 3, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 3).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(3, 671744, null, 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i0.ɵpad(4, 2), (_l()(), i0.ɵted(5, null, ["", ""])), (_l()(), i0.ɵeld(6, 0, null, null, 4, "div", [["class", "d18 ais aic aeg mr-10"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 3, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 8).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(8, 671744, null, 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i0.ɵpad(9, 2), (_l()(), i0.ɵeld(10, 0, null, null, 0, "img", [["alt", ""], ["style", "width: 220px;"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 2, "p", [["class", "agl adi aec"]], null, null, null, null, null)), (_l()(), i0.ɵted(12, null, ["", ""])), i0.ɵppd(13, 1), (_l()(), i0.ɵeld(14, 0, null, null, 3, "div", [["style", "text-align: justify;margin-right: 3%;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(15, 0, null, null, 2, "p", [], [[8, "innerHTML", 1]], null, null, null, null)), i0.ɵppd(16, 1), i0.ɵppd(17, 1), (_l()(), i0.ɵeld(18, 0, null, null, 4, "p", [["class", "agl"]], null, null, null, null, null)), (_l()(), i0.ɵeld(19, 0, null, null, 3, "a", [["class", "ahs aip aks"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 20).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(20, 671744, null, 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i0.ɵpad(21, 2), (_l()(), i0.ɵted(-1, null, ["Leer m\u00E1s \u00BB"])), (_l()(), i0.ɵeld(23, 0, null, null, 0, "hr", [], null, null, null, null, null))], function (_ck, _v) { var currVal_2 = _ck(_v, 4, 0, "/detalleNoticias", _v.context.$implicit.id); _ck(_v, 3, 0, currVal_2); var currVal_6 = _ck(_v, 9, 0, "/detalleNoticias", _v.context.$implicit.id); _ck(_v, 8, 0, currVal_6); var currVal_12 = _ck(_v, 21, 0, "/detalleNoticias", _v.context.$implicit.id); _ck(_v, 20, 0, currVal_12); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 3).target; var currVal_1 = i0.ɵnov(_v, 3).href; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_3 = _v.context.$implicit.titulo; _ck(_v, 5, 0, currVal_3); var currVal_4 = i0.ɵnov(_v, 8).target; var currVal_5 = i0.ɵnov(_v, 8).href; _ck(_v, 7, 0, currVal_4, currVal_5); var currVal_7 = (_co.url + _v.context.$implicit.imagen); _ck(_v, 10, 0, currVal_7); var currVal_8 = i0.ɵunv(_v, 12, 0, _ck(_v, 13, 0, i0.ɵnov(_v.parent.parent, 0), _v.context.$implicit.fecha)); _ck(_v, 12, 0, currVal_8); var currVal_9 = i0.ɵunv(_v, 15, 0, _ck(_v, 17, 0, i0.ɵnov(_v.parent.parent, 1), i0.ɵunv(_v, 15, 0, _ck(_v, 16, 0, i0.ɵnov(_v.parent.parent, 2), _v.context.$implicit.desc_small)))); _ck(_v, 15, 0, currVal_9); var currVal_10 = i0.ɵnov(_v, 20).target; var currVal_11 = i0.ɵnov(_v, 20).href; _ck(_v, 19, 0, currVal_10, currVal_11); }); }
function View_PrensaNoticiasComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [["class", "aem"], ["role", "main"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 3, null, View_PrensaNoticiasComponent_2)), i0.ɵdid(2, 278528, null, 0, i2.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i0.ɵpod(3, { itemsPerPage: 0, currentPage: 1 }), i0.ɵpid(0, i3.PaginatePipe, [i3.PaginationService]), (_l()(), i0.ɵeld(5, 0, null, null, 2, "nav", [["class", "adl"], ["style", "margin-bottom: 1%;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 1, "pagination-controls", [["class", "text-center"]], null, [[null, "pageChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("pageChange" === en)) {
        var pd_0 = ((_co.p = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_PaginationControlsComponent_0, i4.RenderType_PaginationControlsComponent)), i0.ɵdid(7, 49152, null, 0, i3.PaginationControlsComponent, [], { autoHide: [0, "autoHide"], previousLabel: [1, "previousLabel"], nextLabel: [2, "nextLabel"] }, { pageChange: "pageChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 4).transform(_co.noticias, _ck(_v, 3, 0, 8, _co.p))); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.autoHide; var currVal_2 = _co.labels.previousLabel; var currVal_3 = _co.labels.nextLabel; _ck(_v, 7, 0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_PrensaNoticiasComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i5.FechaPipe, []), i0.ɵpid(0, i6.HtmlseguroPipe, [i7.DomSanitizer]), i0.ɵpid(0, i8.CadenasLargasPipe, []), (_l()(), i0.ɵeld(3, 0, null, null, 1, "app-breadcrumbs", [], null, null, null, i9.View_BreadcrumbsComponent_0, i9.RenderType_BreadcrumbsComponent)), i0.ɵdid(4, 114688, null, 0, i10.BreadcrumbsComponent, [i11.BreadcrumbsService, i7.Title, i7.Meta], null, null), (_l()(), i0.ɵeld(5, 0, null, null, 2, "div", [["class", "afx ake"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PrensaNoticiasComponent_1)), i0.ɵdid(7, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 4, 0); var currVal_0 = (_co.total > 0); _ck(_v, 7, 0, currVal_0); }, null); }
export function View_PrensaNoticiasComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-prensa-noticias", [], null, null, null, View_PrensaNoticiasComponent_0, RenderType_PrensaNoticiasComponent)), i0.ɵdid(1, 114688, null, 0, i12.PrensaNoticiasComponent, [i13.NoticiasService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PrensaNoticiasComponentNgFactory = i0.ɵccf("app-prensa-noticias", i12.PrensaNoticiasComponent, View_PrensaNoticiasComponent_Host_0, {}, {}, []);
export { PrensaNoticiasComponentNgFactory as PrensaNoticiasComponentNgFactory };
