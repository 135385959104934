<div class="flex-container" *ngIf="total > 0">
  <div class="flexslider abe">
    <ul class="flex-slides">
      <li *ngFor="let slider of sliders">
        <img [src]="url + slider.imagen" alt="" draggable="true" style="width: 100%;">
        <div class="abs" *ngIf="slider.titulo !== ''">
          <p>{{slider.titulo}}</p>
        </div>
      </li>
    </ul>
    <ul class="flex-direction-nav">
      <li>
        <a class="flex-prev" href="#">
          <em>Previous</em>
        </a>
      </li>
      <li>
        <a class="flex-next" href="#">
          <em>Next</em>
        </a>
      </li>
    </ul>
  </div>
</div>
