<app-breadcrumbs></app-breadcrumbs>

<div class="afx adq">

  <div class="afx ake" *ngIf="total == 0">
    <div class="afx ake">
      <div role="main" class="aem">
        <section class="akd">
          <h2>NO SE ENCONTRO RESULTADO</h2>
          <div class="ady ajt ait">Intente nuevamente. <a class="aiu" href="javascript:void(0)">×</a></div>
        </section>
        <div class="ain"></div>
      </div>
    </div>
  </div>

  <!-- SI SOLO ENCONTRO UNA NOTARIA -->
  <div class="afx ake" *ngIf="total == 1">
    <agm-map [latitude]="latitud | parsefloat" [longitude]="longitud | parsefloat" [zoom]="zoom | parsefloat">
      <agm-marker *ngFor="let notario of notarios" [latitude]="notario.latitud | parsefloat" [longitude]="notario.longitud | parsefloat">
        <agm-info-window>
          <h4><strong class="aks">NOTARIA {{notario.notario}}</strong></h4>
          <p><i class="fa fa-map-marker aks"></i> <strong> {{notario.nomdis}}</strong></p>
          <p><i class="fa fa-street-view aks"></i> <strong> {{notario.direccion}}</strong></p>
          <p *ngIf="notario.horario"><i class="fa fa-calendar aks"></i> <strong> {{notario.horario}}</strong></p>
          <p *ngIf="!notario.horario"></p>
          <p *ngIf="notario.correo"><a class="ake" href="mailto:{{notario.correo}}"><i class="fa fa-envelope-o aks"></i> <strong> {{notario.correo}}</strong></a></p>
          <p *ngIf="!notario.correo"></p>
          <p><i class="fa fa-phone aks"></i> <strong> {{notario.telefono}}</strong></p>
          <p *ngIf="notario.web"><a class="ake" href="http://{{notario.web}}" target="_blank"><i class="fa fa-cloud-upload aks"></i> <strong>{{notario.web}}</strong></a></p>
          <p *ngIf="!notario.web"></p>
          <p *ngIf="notario.estado == 0"><strong><i class="fa fa-check aks"></i> ACTIVO</strong></p>
          <p *ngIf="notario.estado == 1"><strong><i class="fa fa-ban aks"></i> CESADO</strong></p>
          <p *ngIf="notario.estado == 2"><strong><i class="fa fa-pencil aks"></i> LICENCIA</strong></p>
        </agm-info-window>
      </agm-marker>
    </agm-map>
  </div>

  <!-- SI ENCONTRO MAS DE UNA NOTARIA -->
  <div class="afx ake" *ngIf="total > 1">
    <section class="ain">
      <div class="agq ais">
        <agm-map [latitude]="latitud | parsefloat" [longitude]="longitud | parsefloat" [zoom]="zoom | parsefloat">
          <agm-marker *ngFor="let notario of notarios" [latitude]="notario.latitud | parsefloat" [longitude]="notario.longitud | parsefloat">
            <agm-info-window>
              <h4><strong class="aks">NOTARIA {{notario.notario}}</strong></h4>
              <p><i class="fa fa-map-marker aks"></i> <strong> {{notario.nomdis}}</strong></p>
              <p><i class="fa fa-street-view aks"></i> <strong> {{notario.direccion}}</strong></p>
              <p *ngIf="notario.horario"><i class="fa fa-calendar aks"></i> <strong> {{notario.horario}}</strong></p>
              <p *ngIf="!notario.horario"></p>
              <p *ngIf="notario.correo"><a class="ake" href="mailto:{{notario.correo}}"><i class="fa fa-envelope-o aks"></i> <strong> {{notario.correo}}</strong></a></p>
              <p *ngIf="!notario.correo"></p>
              <p><i class="fa fa-phone aks"></i> <strong> {{notario.telefono}}</strong></p>
              <p *ngIf="notario.web"><a class="ake" href="http://{{notario.web}}" target="_blank"><i class="fa fa-cloud-upload aks"></i> <strong>{{notario.web}}</strong></a></p>
              <p *ngIf="!notario.web"></p>
              <p *ngIf="notario.estado == 0"><strong><i class="fa fa-check aks"></i> ACTIVO</strong></p>
              <p *ngIf="notario.estado == 1"><strong><i class="fa fa-ban aks"></i> CESADO</strong></p>
              <p *ngIf="notario.estado == 2"><strong><i class="fa fa-pencil aks"></i> LICENCIA</strong></p>
            </agm-info-window>
          </agm-marker>
        </agm-map>
      </div>
      <div style="overflow-y: scroll;height: 410px;">
          <div class="adz">
            <div class="aje">
              <div class="aez">
                <div class="ahr">Notarios</div>
              </div>
              <div class="ahf" *ngFor="let item of notarios">
                <div class="ajb">
                  <div class="ahr">
                    <a (click)="buscarMapa(item)" class="resaltado_a">
                      <i class="fa fa-map-marker" style="color:#400F10;"></i> {{item.notario}}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </section>
  </div>

</div>
