<app-breadcrumbs></app-breadcrumbs>

<div class="afx ake">
  <div role="main" class="aem">
    <div id="ado">

      <div class="adz">
        <div class="aje">
          <div class="aez">
            <div class="ahr text-center" style="color:black;">DATOS DE CONTACTO DE LA NOTARIA</div>
          </div>
          <div class="ahf">
            <div class="ajb">
              <div class="ahr"><strong>NOTARIO:</strong> {{notario.notario}} - {{notario.nomdis}}</div>
            </div>
            <div class="ajb">
              <div class="ahr"><strong>DIRECCIÓN:</strong> {{notario.direccion}}</div>
            </div>
            <div class="ajb">
              <div class="ahr"><strong>DIRECCIÓN ELECTRÓNICA CORPORATIVA:</strong> {{notario.correo}}</div>
            </div>
            <div class="ajb">
              <div class="ahr"><strong>TELEFONOS:</strong> {{notario.telefono}}</div>
            </div>
          </div>
        </div>
      </div>

      <hr>

      <div id="aca" class="ain">
        <article>
          <div class="acx">
            <h2 class="adu-important">Precios de los servicios notariales</h2>
            <div class="aiq">
              <article class="ajl pull-left" style="margin-bottom: 1%;">
                <div class="aiq">
                  <a (click)="descargarPDF(notario.colegiatura)" class="pointer" style="color:black; font-weight: bold;">
                    <i class="fa fa-file-pdf-o fa-3x text-danger" aria-hidden="true"></i>
                  </a>
                </div>
              </article>
            </div>
          </div>
        </article>
      </div>

      <hr>

      <div class="afx ake">
        <agm-map [latitude]="notario.latitud | parsefloat" [longitude]="notario.longitud | parsefloat" [zoom]="15">
          <agm-marker [latitude]="notario.latitud | parsefloat" [longitude]="notario.longitud | parsefloat">
            <agm-info-window>
              <h4><strong class="aks">NOTARIA {{notario.notario}}</strong></h4>
              <p><i class="fa fa-map-marker aks"></i> <strong> {{notario.nomdis}}</strong></p>
              <p><i class="fa fa-street-view aks"></i> <strong> {{notario.direccion}}</strong></p>
              <p *ngIf="notario.horario"><i class="fa fa-calendar aks"></i> <strong> {{notario.horario}}</strong></p>
              <p *ngIf="!notario.horario"></p>
              <p *ngIf="notario.correo"><a class="ake" href="mailto:{{notario.correo}}"><i class="fa fa-envelope-o aks"></i> <strong> {{notario.correo}}</strong></a></p>
              <p *ngIf="!notario.correo"></p>
              <p *ngIf="notario.telefono"><i class="fa fa-phone aks"></i> <strong> {{notario.telefono}}</strong></p>
              <p *ngIf="!notario.telefono"></p>
              <p *ngIf="notario.web"><a class="ake" href="http://{{notario.web}}" target="_blank"><i class="fa fa-cloud-upload aks"></i> <strong>{{notario.web}}</strong></a></p>
              <p *ngIf="!notario.web"></p>
              <p *ngIf="notario.estado == 0"><strong><i class="fa fa-check aks"></i> ACTIVO</strong></p>
              <p *ngIf="notario.estado == 1"><strong><i class="fa fa-ban aks"></i> CESADO</strong></p>
              <p *ngIf="notario.estado == 2"><strong><i class="fa fa-pencil aks"></i> LICENCIA</strong></p>
            </agm-info-window>
          </agm-marker>
        </agm-map>
      </div>

      <hr>

    </div>
    <div class="ain"></div>
  </div>

  <div role="main" class="aem">
    <div class="adq ain">
      <div class="agj">
        <p><a routerLink="/informate-y-compara" class="ahs air aiv aju">Regresar</a></p>
      </div>
    </div>
    <div class="ain"></div>
  </div>
</div>
