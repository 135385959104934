<app-breadcrumbs></app-breadcrumbs>

<div class="afx ake">

    <div role="main" class="aem">

        <div id="aeu">
            <ul class="ain">
                <li>
                    <article class="ain" *ngFor="let anio of Anios | paginate: { itemsPerPage: 1, currentPage: p }" style="width: 95%;">
                        <figure class="adu"></figure>
                        <header>
                            <h2 class="aba">Notas de Prensa - Archivo {{anio.Anio}}</h2>
                            <div *ngFor="let mes of Meses">
                                <div *ngIf="anio.Anio == mes.Anio" class="ady listado">{{mes.Mes | mesesNumero}}</div>
                                <div *ngIf="anio.Anio == mes.Anio">
                                    <div *ngFor="let noticia of noticias">
                                        <p style="margin-left: 2%;" *ngIf="anio.Anio == noticia.Anio  && mes.Mes == noticia.Mes"><a style="color:black;" [routerLink]="['/detalleNoticias', noticia.id]"><strong>Día {{noticia.Dia}} :</strong> {{noticia.titulo}}</a></p>
                                    </div>
                                </div>
                            </div>
                        </header>
                    </article>
                </li>
            </ul>
        </div>

        <nav class="adl" style="margin-bottom: 1%;">
            <pagination-controls [autoHide]="autoHide" [previousLabel]="labels.previousLabel" [nextLabel]="labels.nextLabel" (pageChange)="p = $event" class="text-center"></pagination-controls>
        </nav>

    </div>

</div>
