<app-breadcrumbs></app-breadcrumbs>

<div class="afx ake" *ngIf="total > 0">

    <div role="main" class="aem">
        <div class="ain ahg">
            <div class="ais">
                <div class="aap" *ngFor="let boletin of boletines">
                    <a [routerLink]="['/detalleBoletines', boletin.id]" class="abf">
                        <span>{{boletin.titulo}}</span>
                    </a>
                </div>
            </div>
        </div>
    </div>

</div>
