import { RouterModule } from '@angular/router';
import { PagesComponent } from './pages.component';
import { HomeComponent } from './home/home.component';
import { BuscarComponent } from './buscar/buscar.component';
import { BuscarColegioComponent } from './buscar/buscar-colegio.component';
import { PaginasComponent } from './paginas/paginas.component';
import { PrensaNoticiasComponent } from './prensa-noticias/prensa-noticias.component';
import { PrensaBoletinesComponent } from './prensa-boletines/prensa-boletines.component';
import { PrensaEventosComponent } from './prensa-eventos/prensa-eventos.component';
import { DetalleNoticiaComponent } from './prensa-noticias/detalle-noticia.component';
import { BoletinDetalleComponent } from './prensa-boletines/boletin-detalle.component';
import { PrensaVideosComponent } from './prensa-videos/prensa-videos.component';
import { VideoDetalleComponent } from './prensa-videos/video-detalle.component';
import { GaleriaComponent } from './galeria/galeria.component';
import { GaleriaDetalleComponent } from './galeria/galeria-detalle.component';
import { PrensaListadoComponent } from './prensa-noticias/prensa-listado.component';
import { BuscarDistritoComponent } from './buscar/buscar-distrito.component';
import { MovilComponent } from './movil/movil.component';
import { NotariosComponent } from './notarios/notarios.component';
import { NotariosTodosComponent } from './notarios/notarios-todos/notarios-todos.component';
import { LibrosComponent } from './libros/libros.component';
import { LibroDetallesComponent } from './libros/libro-detalles/libro-detalles.component';
import { ListaPrecioComponent } from './notarios/lista-precio/lista-precio.component';
import { ContactoComponent } from './contacto/contacto.component';
import { ListaContactosComponent } from './notarios/lista-contactos/lista-contactos.component';
var ɵ0 = { titulo: "Colegio de Notarios de Puno" }, ɵ1 = { titulo: "Contacto" }, ɵ2 = { titulo: "Búsqueda de Notarios" }, ɵ3 = { titulo: "Búsqueda por Colegio" }, ɵ4 = { titulo: "Búsqueda por Distrito" }, ɵ5 = { titulo: "" }, ɵ6 = { titulo: "Notas de Prensa" }, ɵ7 = { titulo: "Notas de Prensa" }, ɵ8 = { titulo: "Detalles de Notas de Prensa" }, ɵ9 = { titulo: "Boletín Institucional" }, ɵ10 = { titulo: "Resumen de Boletín" }, ɵ11 = { titulo: "Eventos Académicos" }, ɵ12 = { titulo: "Videos del CNL" }, ɵ13 = { titulo: "Resumen de Videos" }, ɵ14 = { titulo: "Galeria" }, ɵ15 = { titulo: "Detalle de Galeria" }, ɵ16 = { titulo: "Aplicación Móvil" }, ɵ17 = { titulo: "Listado de Notarios" }, ɵ18 = { titulo: "Listado de Notarios" }, ɵ19 = { titulo: "Relación actualizada de notarios según Ley 30908" }, ɵ20 = { titulo: "Fondo Editorial CNL" }, ɵ21 = { titulo: "Detalles Fondo Editorial CNL" }, ɵ22 = { titulo: "Listado de Contactos" };
var pagesRoutes = [
    {
        path: "",
        component: PagesComponent,
        children: [
            {
                path: "home",
                component: HomeComponent,
                data: ɵ0
            },
            {
                path: "contacto",
                component: ContactoComponent,
                data: ɵ1
            },
            {
                path: "notarios/:notarios",
                component: BuscarComponent,
                data: ɵ2
            },
            {
                path: "colegios/:terminos/:terminos2",
                component: BuscarColegioComponent,
                data: ɵ3
            },
            {
                path: "distrito/:nomdis",
                component: BuscarDistritoComponent,
                data: ɵ4
            },
            // PAGINAS
            {
                path: "paginas/:menuId/:submenuId",
                component: PaginasComponent,
                data: ɵ5
            },
            // NOTICIAS
            {
                path: "saladeprensa",
                component: PrensaListadoComponent,
                data: ɵ6
            },
            {
                path: "noticias",
                component: PrensaNoticiasComponent,
                data: ɵ7
            },
            {
                path: "detalleNoticias/:id",
                component: DetalleNoticiaComponent,
                data: ɵ8
            },
            {
                path: "boletines",
                component: PrensaBoletinesComponent,
                data: ɵ9
            },
            {
                path: "detalleBoletines/:id",
                component: BoletinDetalleComponent,
                data: ɵ10
            },
            {
                path: "eventos",
                component: PrensaEventosComponent,
                data: ɵ11
            },
            {
                path: "videos",
                component: PrensaVideosComponent,
                data: ɵ12
            },
            {
                path: "detalleVideos/:id",
                component: VideoDetalleComponent,
                data: ɵ13
            },
            {
                path: "galerias",
                component: GaleriaComponent,
                data: ɵ14
            },
            {
                path: "detalleGaleria/:id",
                component: GaleriaDetalleComponent,
                data: ɵ15
            },
            {
                path: "movil",
                component: MovilComponent,
                data: ɵ16
            },
            // NOTARIOS
            {
                path: "notarios",
                component: NotariosComponent,
                data: ɵ17
            },
            {
                path: "listaNotarios/:id/:pagina",
                component: NotariosTodosComponent,
                data: ɵ18
            },
            {
                path: "informate-y-compara",
                component: ListaPrecioComponent,
                data: ɵ19
            },
            {
                path: "libros",
                component: LibrosComponent,
                data: ɵ20
            },
            {
                path: "libroDetalles/:id",
                component: LibroDetallesComponent,
                data: ɵ21
            },
            {
                path: "listaContacto/:id",
                component: ListaContactosComponent,
                data: ɵ22
            },
            { path: "", redirectTo: "home", pathMatch: "full" }
        ]
    }
];
// tslint:disable-next-line:eofline
export var PAGES_ROUTES = RouterModule.forChild(pagesRoutes);
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14, ɵ15, ɵ16, ɵ17, ɵ18, ɵ19, ɵ20, ɵ21, ɵ22 };
