import { urlBackend, urlBackendUpload } from '../../models/GLOBAL';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var NoticiasService = /** @class */ (function () {
    function NoticiasService(http) {
        this.http = http;
        this.url = urlBackend;
        this.urlUpload = urlBackendUpload;
    }
    NoticiasService.prototype.cargarNoticias = function () {
        return this.http.get(this.url + 'noticias');
    };
    NoticiasService.prototype.cargarNoticiasPortada = function () {
        return this.http.get(this.url + 'noticias/portada');
    };
    NoticiasService.prototype.obtenerNoticias = function (id) {
        return this.http.get(this.url + 'noticias/' + id);
    };
    NoticiasService.prototype.obtenerImagen = function (idNoticia) {
        return this.http.get(this.url + 'imagen/' + idNoticia);
    };
    NoticiasService.prototype.listarAnios = function () {
        return this.http.get(this.url + 'noticias/listaanio');
    };
    NoticiasService.prototype.listarMeses = function () {
        return this.http.get(this.url + 'noticias/listameses');
    };
    NoticiasService.prototype.listarNoticias = function () {
        return this.http.get(this.url + 'noticias/listanoticias');
    };
    NoticiasService.ngInjectableDef = i0.defineInjectable({ factory: function NoticiasService_Factory() { return new NoticiasService(i0.inject(i1.HttpClient)); }, token: NoticiasService, providedIn: "root" });
    return NoticiasService;
}());
export { NoticiasService };
