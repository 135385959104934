import { Component, OnInit } from '@angular/core';
import { NotariosService } from './../../../services/notarios/notarios.service';
import { Notarios } from './../../../models/notarios';

@Component({
  selector: 'app-notarios-todos',
  templateUrl: './notarios-todos.component.html',
  styles: []
})
export class NotariosTodosComponent implements OnInit {
  notarios: Notarios[] = [];
  eventos: any[] = [];
  mostrar: string;
  total: number = 0;

  // PAGINADO
  p: number = 1;
  public autoHide: boolean = true;
  public labels: any = {
    previousLabel: 'Anterior',
    nextLabel: 'Siguiente'
  };

  constructor(
    public notariosService: NotariosService,
  ) { }

  ngOnInit() {
    this.cargarNotarios();
  }

  cargarNotarios() {
    this.notariosService.cargarNotariosTodos().subscribe(response => {
      this.notarios = response.notarios;
      this.total = response.total;
    });
  }

  buscarNotarioTodos(termino: string) {
    if (termino != '') {
      this.notariosService.buscarNotarioTodos(termino).subscribe(response => {
        this.notarios = response.notarios;
        this.total = response.total;
      });
    } else {
      this.cargarNotarios();
    }
  }

  buscarNotariosEstado(estado: string) {
    if (estado != "3") {
      this.notariosService.buscarNotarioEstado(estado).subscribe(response => {
        this.notarios = response.notarios;
        this.total = response.total;
      });
    } else {
      this.cargarNotarios();
    }
  }
}
