/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./slider.component";
import * as i3 from "../../services/slider/slider.service";
var styles_SliderComponent = [];
var RenderType_SliderComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SliderComponent, data: {} });
export { RenderType_SliderComponent as RenderType_SliderComponent };
function View_SliderComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "abs"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.titulo; _ck(_v, 2, 0, currVal_0); }); }
function View_SliderComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "li", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "img", [["alt", ""], ["draggable", "true"], ["style", "width: 100%;"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SliderComponent_3)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_1 = (_v.context.$implicit.titulo !== ""); _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.url + _v.context.$implicit.imagen); _ck(_v, 1, 0, currVal_0); }); }
function View_SliderComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 13, "div", [["class", "flex-container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 12, "div", [["class", "flexslider abe"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "ul", [["class", "flex-slides"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SliderComponent_2)), i0.ɵdid(4, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵeld(5, 0, null, null, 8, "ul", [["class", "flex-direction-nav"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 3, "li", [], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 2, "a", [["class", "flex-prev"], ["href", "#"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 1, "em", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Previous"])), (_l()(), i0.ɵeld(10, 0, null, null, 3, "li", [], null, null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 2, "a", [["class", "flex-next"], ["href", "#"]], null, null, null, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 1, "em", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Next"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.sliders; _ck(_v, 4, 0, currVal_0); }, null); }
export function View_SliderComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_SliderComponent_1)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.total > 0); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_SliderComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-slider", [], null, null, null, View_SliderComponent_0, RenderType_SliderComponent)), i0.ɵdid(1, 376832, null, 0, i2.SliderComponent, [i3.SliderService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SliderComponentNgFactory = i0.ɵccf("app-slider", i2.SliderComponent, View_SliderComponent_Host_0, {}, {}, []);
export { SliderComponentNgFactory as SliderComponentNgFactory };
