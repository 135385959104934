import { OnInit } from '@angular/core';
import { EmpresasService } from 'src/app/services/empresas/empresas.service';
import { Empresas } from 'src/app/models/empresas';
import { urlBackendUpload } from 'src/app/models/GLOBAL';
var HeaderComponent = /** @class */ (function () {
    function HeaderComponent(empresasService) {
        this.empresasService = empresasService;
        this.empresas = new Empresas();
        this.url = urlBackendUpload + 'empresas/';
    }
    HeaderComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.empresasService.cargarEmpresas(1).subscribe(function (response) {
            _this.empresas = response.empresas;
        });
    };
    return HeaderComponent;
}());
export { HeaderComponent };
