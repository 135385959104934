/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/common";
import * as i3 from "../../pipes/meses-numero.pipe";
import * as i4 from "../../shared/breadcrumbs/breadcrumbs.component.ngfactory";
import * as i5 from "../../shared/breadcrumbs/breadcrumbs.component";
import * as i6 from "../../services/shared/breadcrumbs.service";
import * as i7 from "@angular/platform-browser";
import * as i8 from "ngx-pagination";
import * as i9 from "../../../../node_modules/ngx-pagination/dist/ngx-pagination.ngfactory";
import * as i10 from "./prensa-listado.component";
import * as i11 from "../../services/noticias/noticias.service";
var styles_PrensaListadoComponent = [];
var RenderType_PrensaListadoComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PrensaListadoComponent, data: {} });
export { RenderType_PrensaListadoComponent as RenderType_PrensaListadoComponent };
function View_PrensaListadoComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "ady listado"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""])), i0.ɵppd(2, 1)], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i0.ɵnov(_v.parent.parent.parent, 0), _v.parent.context.$implicit.Mes)); _ck(_v, 1, 0, currVal_0); }); }
function View_PrensaListadoComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "p", [["style", "margin-left: 2%;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 5, "a", [["style", "color:black;"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 671744, null, 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i0.ɵpad(3, 2), (_l()(), i0.ɵeld(4, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i0.ɵted(5, null, ["D\u00EDa ", " :"])), (_l()(), i0.ɵted(6, null, [" ", ""]))], function (_ck, _v) { var currVal_2 = _ck(_v, 3, 0, "/detalleNoticias", _v.parent.context.$implicit.id); _ck(_v, 2, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 2).target; var currVal_1 = i0.ɵnov(_v, 2).href; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_3 = _v.parent.context.$implicit.Dia; _ck(_v, 5, 0, currVal_3); var currVal_4 = _v.parent.context.$implicit.titulo; _ck(_v, 6, 0, currVal_4); }); }
function View_PrensaListadoComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PrensaListadoComponent_6)), i0.ɵdid(2, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = ((_v.parent.parent.parent.context.$implicit.Anio == _v.context.$implicit.Anio) && (_v.parent.parent.context.$implicit.Mes == _v.context.$implicit.Mes)); _ck(_v, 2, 0, currVal_0); }, null); }
function View_PrensaListadoComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PrensaListadoComponent_5)), i0.ɵdid(2, 278528, null, 0, i2.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.noticias; _ck(_v, 2, 0, currVal_0); }, null); }
function View_PrensaListadoComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PrensaListadoComponent_3)), i0.ɵdid(2, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PrensaListadoComponent_4)), i0.ɵdid(4, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = (_v.parent.context.$implicit.Anio == _v.context.$implicit.Anio); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_v.parent.context.$implicit.Anio == _v.context.$implicit.Anio); _ck(_v, 4, 0, currVal_1); }, null); }
function View_PrensaListadoComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "article", [["class", "ain"], ["style", "width: 95%;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "figure", [["class", "adu"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 4, "header", [], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "h2", [["class", "aba"]], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, ["Notas de Prensa - Archivo ", ""])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PrensaListadoComponent_2)), i0.ɵdid(6, 278528, null, 0, i2.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.Meses; _ck(_v, 6, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.Anio; _ck(_v, 4, 0, currVal_0); }); }
export function View_PrensaListadoComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i3.MesesNumeroPipe, []), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-breadcrumbs", [], null, null, null, i4.View_BreadcrumbsComponent_0, i4.RenderType_BreadcrumbsComponent)), i0.ɵdid(2, 114688, null, 0, i5.BreadcrumbsComponent, [i6.BreadcrumbsService, i7.Title, i7.Meta], null, null), (_l()(), i0.ɵeld(3, 0, null, null, 11, "div", [["class", "afx ake"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 10, "div", [["class", "aem"], ["role", "main"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 6, "div", [["id", "aeu"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 5, "ul", [["class", "ain"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 4, "li", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 3, null, View_PrensaListadoComponent_1)), i0.ɵdid(9, 278528, null, 0, i2.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i0.ɵpod(10, { itemsPerPage: 0, currentPage: 1 }), i0.ɵpid(0, i8.PaginatePipe, [i8.PaginationService]), (_l()(), i0.ɵeld(12, 0, null, null, 2, "nav", [["class", "adl"], ["style", "margin-bottom: 1%;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(13, 0, null, null, 1, "pagination-controls", [["class", "text-center"]], null, [[null, "pageChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("pageChange" === en)) {
        var pd_0 = ((_co.p = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i9.View_PaginationControlsComponent_0, i9.RenderType_PaginationControlsComponent)), i0.ɵdid(14, 49152, null, 0, i8.PaginationControlsComponent, [], { autoHide: [0, "autoHide"], previousLabel: [1, "previousLabel"], nextLabel: [2, "nextLabel"] }, { pageChange: "pageChange" })], function (_ck, _v) { var _co = _v.component; _ck(_v, 2, 0); var currVal_0 = i0.ɵunv(_v, 9, 0, i0.ɵnov(_v, 11).transform(_co.Anios, _ck(_v, 10, 0, 1, _co.p))); _ck(_v, 9, 0, currVal_0); var currVal_1 = _co.autoHide; var currVal_2 = _co.labels.previousLabel; var currVal_3 = _co.labels.nextLabel; _ck(_v, 14, 0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_PrensaListadoComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-prensa-listado", [], null, null, null, View_PrensaListadoComponent_0, RenderType_PrensaListadoComponent)), i0.ɵdid(1, 114688, null, 0, i10.PrensaListadoComponent, [i11.NoticiasService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PrensaListadoComponentNgFactory = i0.ɵccf("app-prensa-listado", i10.PrensaListadoComponent, View_PrensaListadoComponent_Host_0, {}, {}, []);
export { PrensaListadoComponentNgFactory as PrensaListadoComponentNgFactory };
