import { Component, OnInit } from '@angular/core';
import { Noticia } from '../../models/noticias';
import { Imagenes } from '../../models/imagenes';
import { ActivatedRoute } from '@angular/router';
import { urlBackendUpload } from 'src/app/models/GLOBAL';
import { BreadcrumbsService } from 'src/app/services/shared/breadcrumbs.service';
import { NoticiasService } from 'src/app/services/noticias/noticias.service';
declare function init_slider(): any;

@Component({
  selector: 'app-detalle-noticia',
  templateUrl: './detalle-noticia.component.html',
  styles: []
})
export class DetalleNoticiaComponent implements OnInit {

  noticias: Noticia = new Noticia();
  imagenes: Imagenes = new Imagenes();
  total: number;
  totalImagen: number;
  url: string = urlBackendUpload + 'noticias/';
  urlImagen: string = urlBackendUpload + 'imagenes/';

  constructor(
    public noticiasService: NoticiasService,
    public activatedRoute: ActivatedRoute,
    private breadcrumbsService: BreadcrumbsService,
  ) {
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params=>{
      let codigo = params['id'];
      this.obtenerNoticias(codigo);
      this.obtenerImagenes(codigo);
      init_slider();
    });
  }
  obtenerNoticias(id:string){
    this.noticiasService.obtenerNoticias(id).subscribe(response=>{
      this.noticias = response.noticias;
      this.total = response.total;

      this.breadcrumbsService.generateTag({
        title: this.noticias.titulo,
        description: this.noticias.desc_small,
        image: this.url + this.noticias.imagen,
        slug: 'http://www.notarios.org.pe/#/detalleNoticias/' + this.noticias.id
      });
    });
  }

  obtenerImagenes(idNoticia:string){
    this.noticiasService.obtenerImagen(idNoticia).subscribe(response=>{
      this.imagenes = response.imagenes;
      this.totalImagen = response.total;
    })
  }

}
