<app-breadcrumbs></app-breadcrumbs>

<div class="afx ake">
  <div role="main" class="aem">

    <div id="agv" *ngIf="total > 0">
      <section>
        <h2>Fondo Editorial CNL</h2>
        <ul class="ain">
          <li class="pull-left ajp text-center portada" *ngFor="let libro of libros">
            <a *ngIf="libro.imagen" class="pointer" (click)="detalle(libro.id)" [title]="libro.titulo">
              <img [src]="url + libro.imagen" alt="" class="libros">
            </a>
            <h3 style="margin-bottom:6px; text-transform: capitalize;">
              <a class="pointer" (click)="detalle(libro.id)" [title]="libro.titulo">
                <strong style="color: #000;">{{libro.titulo | cadenasCortas}}</strong>
              </a>
            </h3>
            <span>{{libro.autor | cadenasLibros}}</span>
          </li>
        </ul>
      </section>
    </div>

    <section class="ahg" *ngIf="total == 0">
        <div class="ady ait">Sin registros encontrados.</div>
      </section>

    <div class="ain"></div>
  </div>
</div>
