import { Injectable } from '@angular/core';
import { urlBackend } from 'src/app/models/GLOBAL';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs-compat';

@Injectable({
  providedIn: 'root'
})
export class NotariosService {

  url: string = urlBackend;

  constructor(public http: HttpClient) { }

  cargarNotarios(): Observable<any> {
    return this.http.get(this.url + 'notarios');
  }

  cargarNotariosTodos(): Observable<any> {
    return this.http.get(this.url + 'notariosLima');
  }

  buscarNotarioTodos(termino: string): Observable<any> {
    return this.http.get(this.url + "notarios/likeTodos/" + termino);
  }

  buscarNotarioEstado(estado: string): Observable<any> {
    return this.http.get(this.url + "notarios/likeEstado/" + estado);
  }

  listarPrecios(): Observable<any>{
    return this.http.get(this.url + 'detalleServicio/listado');
  }

  listarPreciosOpcion(): Observable<any>{
    return this.http.get(this.url + 'detalleServicio/opcion');
  }

  listaNotarios(sisev: number): Observable<any> {
    return this.http.get(this.url + "colegios/" + sisev);
  }

  listaNotariosPrecio(sisev: number): Observable<any> {
    return this.http.get(this.url + "notarios/precios/" + sisev);
  }

  buscarNotario(termino:string): Observable<any> {
    return this.http.get(this.url + 'notarios/likeServicios/' + termino);
  }

  buscarNotarioNuevo(termino: string): Observable<any> {
    return this.http.get(this.url + 'notarios/like/' + termino);
  }

  buscarNotarioDistrito(termino: string): Observable<any> {
    return this.http.get(this.url + 'notarios/distrito/' + termino);
  }

  cargarDistritos(): Observable<any> {
    return this.http.get(this.url + "distritos");
  }

  obtenerNotarioServicio(notarioId: number): Observable<any> {
    return this.http.get(this.url + 'notarioServicio/' + notarioId);
  }

  obtenerNotarioServicioOpcion(notarioId: number): Observable<any> {
    return this.http.get(this.url + 'notarioServicioOpcion/' + notarioId);
  }

  cargarContactos(notarioId: number): Observable<any> {
    return this.http.get(this.url + 'listaContactos/' + notarioId);
  }

  obtenerNotario(notarioId: number): Observable<any> {
    return this.http.get(this.url + 'notarios/' + notarioId);
  }
}
