/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/common";
import * as i3 from "./nopagefound.component";
var styles_NopagefoundComponent = [];
var RenderType_NopagefoundComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_NopagefoundComponent, data: {} });
export { RenderType_NopagefoundComponent as RenderType_NopagefoundComponent };
export function View_NopagefoundComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 17, "div", [["class", "afx ajh"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 16, "div", [["class", "ain"], ["id", "aen"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "div", [["class", "agh"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "h2", [["class", "agl"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["404"])), (_l()(), i0.ɵeld(5, 0, null, null, 12, "nav", [["class", "aex adv"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 11, "ul", [], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 3, "li", [], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 2, "a", [["routerLink", "/home"], ["title", "Inicio"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 9).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(9, 671744, null, 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵted(-1, null, ["Inicio"])), (_l()(), i0.ɵeld(11, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 1, "a", [["title", "Inicio"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["P\u00E1gina"])), (_l()(), i0.ɵeld(14, 0, null, null, 3, "li", [], null, null, null, null, null)), (_l()(), i0.ɵeld(15, 0, null, null, 2, "a", [["routerLink", "/home"], ["title", "404"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 16).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(16, 671744, null, 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵted(-1, null, ["404"])), (_l()(), i0.ɵeld(18, 0, null, null, 16, "div", [["class", "afx ake"]], null, null, null, null, null)), (_l()(), i0.ɵeld(19, 0, null, null, 15, "div", [["class", "aem"], ["role", "main"]], null, null, null, null, null)), (_l()(), i0.ɵeld(20, 0, null, null, 13, "div", [["class", "ain"], ["id", "akt"]], null, null, null, null, null)), (_l()(), i0.ɵeld(21, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["404"])), (_l()(), i0.ɵeld(23, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Ocurri\u00F3 un error"])), (_l()(), i0.ɵeld(25, 0, null, null, 3, "p", [["class", "ahz"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Lo siento pero la p\u00E1gina o archivo que "])), (_l()(), i0.ɵeld(27, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" eras buscando no se puede encontrar"])), (_l()(), i0.ɵeld(29, 0, null, null, 4, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Regrese a la "])), (_l()(), i0.ɵeld(31, 0, null, null, 2, "a", [["routerLink", "/home"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 32).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(32, 671744, null, 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵted(-1, null, ["p\u00E1gina de inicio"])), (_l()(), i0.ɵeld(34, 0, null, null, 0, "div", [["class", "ain"]], null, null, null, null, null))], function (_ck, _v) { var currVal_2 = "/home"; _ck(_v, 9, 0, currVal_2); var currVal_5 = "/home"; _ck(_v, 16, 0, currVal_5); var currVal_8 = "/home"; _ck(_v, 32, 0, currVal_8); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 9).target; var currVal_1 = i0.ɵnov(_v, 9).href; _ck(_v, 8, 0, currVal_0, currVal_1); var currVal_3 = i0.ɵnov(_v, 16).target; var currVal_4 = i0.ɵnov(_v, 16).href; _ck(_v, 15, 0, currVal_3, currVal_4); var currVal_6 = i0.ɵnov(_v, 32).target; var currVal_7 = i0.ɵnov(_v, 32).href; _ck(_v, 31, 0, currVal_6, currVal_7); }); }
export function View_NopagefoundComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-nopagefound", [], null, null, null, View_NopagefoundComponent_0, RenderType_NopagefoundComponent)), i0.ɵdid(1, 114688, null, 0, i3.NopagefoundComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NopagefoundComponentNgFactory = i0.ɵccf("app-nopagefound", i3.NopagefoundComponent, View_NopagefoundComponent_Host_0, {}, {}, []);
export { NopagefoundComponentNgFactory as NopagefoundComponentNgFactory };
