import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cadenasLargas'
})
export class CadenasLargasPipe implements PipeTransform {

  cadenas:string;
  transform(value: any): any {
    this.cadenas = value.substr(0,400);
    return this.cadenas + '...';
  }

}
