import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-prensa-eventos',
  templateUrl: './prensa-eventos.component.html',
  styles: []
})
export class PrensaEventosComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
}
