import { Component, OnInit } from '@angular/core';
import { Mensajes } from 'src/app/models/mensajes';
import { MensajesService } from 'src/app/services/mensajes/mensajes.service';
import { NgForm } from '@angular/forms';
declare var swal: any;
@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html',
  styles: []
})
export class ContactoComponent implements OnInit {

  mensajes: Mensajes = new Mensajes();

  constructor(public mensajesService: MensajesService) { }

  ngOnInit() {
  }

  enviarMensaje(forma: NgForm) {

    if (forma.invalid) {
      return;
    }

    this.mensajesService.enviarMensaje(this.mensajes).subscribe(response=>{
      swal('Mensaje', response.message, response.status);
      forma.reset();
    }, error=>{
      console.log(error);
    });
  }

}
