var ImagenesGaleria = /** @class */ (function () {
    function ImagenesGaleria(id, imagen, orden, created_at, updated_at, titulo, galeria) {
        this.id = id;
        this.imagen = imagen;
        this.orden = orden;
        this.created_at = created_at;
        this.updated_at = updated_at;
        this.titulo = titulo;
        this.galeria = galeria;
    }
    return ImagenesGaleria;
}());
export { ImagenesGaleria };
