import { Noticia } from "./noticias";

export class Imagenes {
  constructor(
    public id?:number,
    public noticias?: string,
    public imagen?: string,
    public orden?:string,
    public created_at?:string,
    public updated_at?:string,
    public noticia?: Noticia
  ) {}
}
