var Noticia = /** @class */ (function () {
    function Noticia(id, titulo, imagen, fecha, desc_small, desc_large, archivo, orden, status, created_at, updated_at) {
        this.id = id;
        this.titulo = titulo;
        this.imagen = imagen;
        this.fecha = fecha;
        this.desc_small = desc_small;
        this.desc_large = desc_large;
        this.archivo = archivo;
        this.orden = orden;
        this.status = status;
        this.created_at = created_at;
        this.updated_at = updated_at;
    }
    return Noticia;
}());
export { Noticia };
