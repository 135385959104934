import { Injectable } from '@angular/core';
import { urlBackend, urlBackendUpload } from '../../models/GLOBAL';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs-compat';

@Injectable({
  providedIn: 'root'
})
export class NoticiasService {

  url: string = urlBackend;
  urlUpload: string = urlBackendUpload;

  constructor(public http: HttpClient) { }

  cargarNoticias():Observable<any>{
    return this.http.get(this.url + 'noticias');
  }

  cargarNoticiasPortada():Observable<any>{
    return this.http.get(this.url + 'noticias/portada');
  }

  obtenerNoticias(id:string):Observable<any>{
    return this.http.get(this.url + 'noticias/' + id);
  }

  obtenerImagen(idNoticia: string):Observable<any>{
    return this.http.get(this.url + 'imagen/' + idNoticia);
  }

  listarAnios():Observable<any>{
    return this.http.get(this.url + 'noticias/listaanio');
  }

  listarMeses():Observable<any>{
    return this.http.get(this.url + 'noticias/listameses');
  }

  listarNoticias(): Observable<any> {
    return this.http.get(this.url + 'noticias/listanoticias');
  }

}
