/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../pipes/htmlseguro.pipe";
import * as i3 from "@angular/platform-browser";
import * as i4 from "../../pipes/fecha.pipe";
import * as i5 from "../../shared/breadcrumbs/breadcrumbs.component.ngfactory";
import * as i6 from "../../shared/breadcrumbs/breadcrumbs.component";
import * as i7 from "../../services/shared/breadcrumbs.service";
import * as i8 from "./detalle-noticia.component";
import * as i9 from "../../services/noticias/noticias.service";
import * as i10 from "@angular/router";
var styles_DetalleNoticiaComponent = [];
var RenderType_DetalleNoticiaComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DetalleNoticiaComponent, data: {} });
export { RenderType_DetalleNoticiaComponent as RenderType_DetalleNoticiaComponent };
function View_DetalleNoticiaComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "li", [["aria-hidden", "true"], ["class", "clone"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "img", [["alt", ""], ["draggable", "true"], ["style", "width: 100%; height: 563px;"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.urlImagen + _v.context.$implicit.imagen); _ck(_v, 1, 0, currVal_0); }); }
function View_DetalleNoticiaComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 14, "div", [["class", "aeg ahg"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 13, "div", [["class", "flex-container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 12, "div", [["class", "flexslider abe"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 2, "ul", [["class", "flex-slides"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DetalleNoticiaComponent_3)), i0.ɵdid(5, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵeld(6, 0, null, null, 8, "ul", [["class", "flex-direction-nav"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 3, "li", [], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 2, "a", [["class", "flex-prev"], ["href", "#"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 1, "em", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Previous"])), (_l()(), i0.ɵeld(11, 0, null, null, 3, "li", [], null, null, null, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 2, "a", [["class", "flex-next"], ["href", "#"]], null, null, null, null, null)), (_l()(), i0.ɵeld(13, 0, null, null, 1, "em", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Next"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.imagenes; _ck(_v, 5, 0, currVal_0); }, null); }
function View_DetalleNoticiaComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 14, "div", [["class", "afx ake"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 13, "div", [["class", "aem"], ["role", "main"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DetalleNoticiaComponent_2)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(4, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i0.ɵted(5, null, ["", ""])), (_l()(), i0.ɵeld(6, 0, null, null, 1, "p", [["style", "text-align: justify !important;"]], [[8, "innerHTML", 1]], null, null, null, null)), i0.ɵppd(7, 1), (_l()(), i0.ɵeld(8, 0, null, null, 0, "hr", [["style", "margin: 0;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 4, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(10, null, ["", " - "])), i0.ɵppd(11, 1), (_l()(), i0.ɵeld(12, 0, null, null, 1, "a", [["target", "blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Descargar Archivo"])), (_l()(), i0.ɵeld(14, 0, null, null, 0, "div", [["class", "ain"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.totalImagen > 0); _ck(_v, 3, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.noticias.titulo; _ck(_v, 5, 0, currVal_1); var currVal_2 = i0.ɵunv(_v, 6, 0, _ck(_v, 7, 0, i0.ɵnov(_v.parent, 0), _co.noticias.desc_small)); _ck(_v, 6, 0, currVal_2); var currVal_3 = i0.ɵunv(_v, 10, 0, _ck(_v, 11, 0, i0.ɵnov(_v.parent, 1), _co.noticias.fecha)); _ck(_v, 10, 0, currVal_3); var currVal_4 = (_co.url + _co.noticias.archivo); _ck(_v, 12, 0, currVal_4); }); }
export function View_DetalleNoticiaComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i2.HtmlseguroPipe, [i3.DomSanitizer]), i0.ɵpid(0, i4.FechaPipe, []), (_l()(), i0.ɵeld(2, 0, null, null, 1, "app-breadcrumbs", [], null, null, null, i5.View_BreadcrumbsComponent_0, i5.RenderType_BreadcrumbsComponent)), i0.ɵdid(3, 114688, null, 0, i6.BreadcrumbsComponent, [i7.BreadcrumbsService, i3.Title, i3.Meta], null, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DetalleNoticiaComponent_1)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 3, 0); var currVal_0 = (_co.total > 0); _ck(_v, 5, 0, currVal_0); }, null); }
export function View_DetalleNoticiaComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-detalle-noticia", [], null, null, null, View_DetalleNoticiaComponent_0, RenderType_DetalleNoticiaComponent)), i0.ɵdid(1, 114688, null, 0, i8.DetalleNoticiaComponent, [i9.NoticiasService, i10.ActivatedRoute, i7.BreadcrumbsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DetalleNoticiaComponentNgFactory = i0.ɵccf("app-detalle-noticia", i8.DetalleNoticiaComponent, View_DetalleNoticiaComponent_Host_0, {}, {}, []);
export { DetalleNoticiaComponentNgFactory as DetalleNoticiaComponentNgFactory };
