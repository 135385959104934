<nav role="navigation" id="ahu" class="ain">
  <a class="iconMenu pointer" (click)="menuResponsive()" style="background: inherit;color: #b9282c;">
    <i class="fa fa-2x fa-bars" style="padding: 6px 0 6px 0;"></i>
  </a>
  <ul id="myTopnav" class="ain topnav">
    <li routerLinkActive="ahv">
      <a routerLink="/home" title="Inicio">Inicio</a>
    </li>

    <li *ngFor="let menu of menus" routerLinkActive="ahv">
      <!-- SI EL MENU NO LLEVA SUB MENU -->
      <a *ngIf="menu.padre == 1" [routerLink]="['/paginas', menu.id, 0]" title="{{menu.nommenu}}">{{menu.nommenu}}</a>
      <!-- SI EL MENU LLEVA SUB MENU -->
      <a *ngIf="menu.padre == 2" (mouseover)="submenu($event.type, menu.id)" class="akb"
        title="{{menu.nommenu}}">{{menu.nommenu}}</a>

      <ul class="afq" *ngIf="menu.padre == 2">
        <li *ngFor="let submenu of submenus">
          <a class="submenu" *ngIf="submenu.menu_id == menu.id" [routerLink]="['/paginas', menu.id, submenu.id]">
            {{submenu.nomhijo}}
          </a>
        </li>
      </ul>
    </li>

    <li routerLinkActive="ahv">
      <a routerLink="/contacto" title="Contacto">Contacto</a>
    </li>

  </ul>

</nav>
