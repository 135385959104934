var Imagenes = /** @class */ (function () {
    function Imagenes(id, noticias, imagen, orden, created_at, updated_at, noticia) {
        this.id = id;
        this.noticias = noticias;
        this.imagen = imagen;
        this.orden = orden;
        this.created_at = created_at;
        this.updated_at = updated_at;
        this.noticia = noticia;
    }
    return Imagenes;
}());
export { Imagenes };
