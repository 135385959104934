import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

// MODULOS
import { PagesComponent } from './pages.component';
import { SharedModule } from '../shared/shared.module';

// RUTAS
import { PAGES_ROUTES } from './pages.route';

// MAPS;
import { AgmCoreModule } from '@agm/core';

// PIPE
import { PipesModule } from '../pipes/pipes.module';

// PAGINADO
import {NgxPaginationModule} from 'ngx-pagination';

// COMPONENTES
import { HomeComponent } from './home/home.component';
import { BuscarComponent } from './buscar/buscar.component';
import { BuscarColegioComponent } from './buscar/buscar-colegio.component';
import { PaginasComponent } from './paginas/paginas.component';
import { ServiciosComponent } from './servicios/servicios.component';
import { NotvirtualComponent } from './notvirtual/notvirtual.component';
import { PrensaNoticiasComponent } from './prensa-noticias/prensa-noticias.component';
import { PrensaBoletinesComponent } from './prensa-boletines/prensa-boletines.component';
import { PrensaEventosComponent } from './prensa-eventos/prensa-eventos.component';
import { DetalleNoticiaComponent } from './prensa-noticias/detalle-noticia.component';
import { BoletinDetalleComponent } from './prensa-boletines/boletin-detalle.component';
import { PrensaVideosComponent } from './prensa-videos/prensa-videos.component';
import { VideoDetalleComponent } from './prensa-videos/video-detalle.component';
import { GaleriaComponent } from './galeria/galeria.component';
import { GaleriaDetalleComponent } from './galeria/galeria-detalle.component';
import { PrensaListadoComponent } from './prensa-noticias/prensa-listado.component';
import { BuscarDistritoComponent } from './buscar/buscar-distrito.component';
import { MovilComponent } from './movil/movil.component';
import { NotariosComponent } from './notarios/notarios.component';
import { NotariosTodosComponent } from './notarios/notarios-todos/notarios-todos.component';

// ANGULAR MATERIAL
import {
  MatAutocompleteModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatIconModule,
  MatInputModule,
} from '@angular/material';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTreeModule } from '@angular/material/tree';
import { LibrosComponent } from './libros/libros.component';
import { LibroDetallesComponent } from './libros/libro-detalles/libro-detalles.component';
import { ListaPrecioComponent } from './notarios/lista-precio/lista-precio.component';
import { ContactoComponent } from './contacto/contacto.component';
import { ListaContactosComponent } from './notarios/lista-contactos/lista-contactos.component';

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    SharedModule,
    ReactiveFormsModule,
    PAGES_ROUTES,
    NgxPaginationModule,
    PipesModule,
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyDigMHnT7rjb8HJ2eRGJ5X7quReCwA075Q"
    }),
    MatInputModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatFormFieldModule,
    MatExpansionModule,
    MatTreeModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatIconModule,
  ],
  declarations: [
    PagesComponent,
    HomeComponent,
    BuscarComponent,
    BuscarColegioComponent,
    PaginasComponent,
    ServiciosComponent,
    NotvirtualComponent,
    PrensaNoticiasComponent,
    PrensaBoletinesComponent,
    PrensaEventosComponent,
    DetalleNoticiaComponent,
    BoletinDetalleComponent,
    PrensaVideosComponent,
    VideoDetalleComponent,
    GaleriaComponent,
    GaleriaDetalleComponent,
    PrensaListadoComponent,
    BuscarDistritoComponent,
    MovilComponent,
    NotariosComponent,
    NotariosTodosComponent,
    LibrosComponent,
    LibroDetallesComponent,
    ListaPrecioComponent,
    ContactoComponent,
    ListaContactosComponent
  ],
  exports: [
    PagesComponent,
    HomeComponent,
    BuscarComponent,
    BuscarColegioComponent,
    PaginasComponent,
    ServiciosComponent,
    NotvirtualComponent,
    PrensaNoticiasComponent,
    PrensaBoletinesComponent,
    PrensaEventosComponent,
    DetalleNoticiaComponent,
    BoletinDetalleComponent,
    PrensaVideosComponent,
    VideoDetalleComponent,
    GaleriaComponent,
    GaleriaDetalleComponent,
    PrensaListadoComponent,
    BuscarDistritoComponent,
    MovilComponent,
    NotariosComponent,
    NotariosTodosComponent,
    LibrosComponent,
    LibroDetallesComponent,
    ListaPrecioComponent,
    ContactoComponent,
    ListaContactosComponent
  ],
  schemas: []
})
export class PagesModule {}
