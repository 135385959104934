<div class="afx akn">
  <app-topbar></app-topbar>
</div>

<div class="afx ajx">
  <app-header></app-header>
</div>

<div class="afx akf">
  <app-nav></app-nav>
</div>

<router-outlet></router-outlet>

<div class="afx ajs">
  <app-entidades></app-entidades>
</div>

<div class="afx footer colo-footer">
  <app-footer></app-footer>
</div>
