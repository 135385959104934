import { PipeTransform } from '@angular/core';
var CadenasCortasPipe = /** @class */ (function () {
    function CadenasCortasPipe() {
    }
    CadenasCortasPipe.prototype.transform = function (value) {
        this.cadenas = value.substr(0, 25);
        return this.cadenas + '...';
    };
    return CadenasCortasPipe;
}());
export { CadenasCortasPipe };
