import { PipeTransform } from '@angular/core';
var CadenasPipe = /** @class */ (function () {
    function CadenasPipe() {
    }
    CadenasPipe.prototype.transform = function (value) {
        this.cadenas = value.substr(0, 500);
        return this.cadenas + '...';
    };
    return CadenasPipe;
}());
export { CadenasPipe };
