import { Injectable } from '@angular/core';
import { urlBackend, urlBackendUpload } from '../../models/GLOBAL';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class GaleriaService {

  url: string = urlBackend;
  urlUpload: string = urlBackendUpload;

  constructor(public http: HttpClient) { }

  cargarGaleria():Observable<any>{
    return this.http.get(this.url + 'galeria');
  }

  cargarGaleriaPortada():Observable<any>{
    return this.http.get(this.url + 'galeria/slider');
  }

  obtenerGaleria(id:string):Observable<any>{
    return this.http.get(this.url + 'galeria/' + id);
  }

  obtenerImagen(idGaleria: string):Observable<any>{
    return this.http.get(this.url + 'imagenGaleria/' + idGaleria);
  }
}
