<h2 *ngIf="total > 0">
  <span>Próximos Eventos</span>
</h2>

<div class="flex-container" *ngIf="total > 0" style="margin-bottom: 18%;">
    <div class="flexslider aax">
      <ul class="flex-slides">
        <li *ngFor="let evento of eventos">
          <time class="aeo" [ngStyle]="{'background': evento.color}">
            <strong>{{evento.fecha_ing | meses}}</strong>
            <em [ngStyle]="{'background': evento.color}" class="evento">{{evento.fecha_ing | date:'dd'}}</em>
          </time>
          <div class="pull-left ajj">
            <p class="agl aio adg pull-left">
              <a *ngIf="evento.boletin_id == 0" style="font-size: 22px;"><strong>{{evento.titulo}}</strong></a>
              <a *ngIf="evento.boletin_id != 0" [routerLink]="['/detalleBoletines', evento.boletin_id]"
                style="font-size: 22px;"><strong>{{evento.titulo}}</strong></a> <br>
              <strong style="font-size: 12px; color: dimgray;">{{evento.fecha_ing | fecha}}</strong>
            </p>
            <p class="agl pull-left" style="text-align: justify;"><strong>{{evento.detalle}}</strong></p>
          </div>
        </li>
      </ul>
    </div>
  </div>
