<app-breadcrumbs></app-breadcrumbs>

<div class="afx ake">
  <div role="main" class="aem">
    <div class="agj ais text-center"><img *ngIf="libro.imagen" [src]="url + libro.imagen" alt="" style="width: 41%;"></div>
    <div class="agj">
      <h1>{{libro.titulo}}</h1>
      <ul class="akg akl" style="margin-bottom: 5%;">
        <li *ngIf="libro.autor">Autor: <strong>{{libro.autor}}</strong></li>
        <li *ngIf="libro.publicacion">Publicación: <strong>{{libro.publicacion | date:'dd/MM/yyyy'}}</strong></li>
        <li *ngIf="libro.precio != 0.00">Precio: <strong>{{libro.precio}}</strong></li>
      </ul>

      <h2 style="margin-bottom: 3%;">Resumen</h2>
      <p [innerHTML]="libro.resumen | htmlseguro"></p>
      <p style="margin-top: 10%;"><button class="ahs air aec aks" routerLink="/libros"><i aria-hidden="true" class="fa fa-arrow-left"></i> Regresar </button></p>
    </div>
    <div class="ain ahg"></div>
  </div>
</div>
