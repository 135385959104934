import { Component, OnInit } from '@angular/core';
import { Galeria } from '../../models/galeria';
import { GaleriaService } from 'src/app/services/galeria/galeria.service';
import { urlBackendUpload } from 'src/app/models/GLOBAL';

@Component({
  selector: 'app-galeria',
  templateUrl: './galeria.component.html',
  styles: []
})
export class GaleriaComponent implements OnInit {

  galerias: Galeria[] = [];
  galeriasPortada: Galeria[] = [];
  total: number;
  url: string = urlBackendUpload + 'galeria/';

  // PAGINADO
  p: number = 1;
  public autoHide: boolean = true;
  public labels: any = {
        previousLabel: 'Anterior',
        nextLabel: 'Siguiente'
    };

  constructor(
    public galeriaService: GaleriaService,
  ) {}

  ngOnInit() {
    this.cargarGaleria();
    this.cargarGaleriaPortada();
  }

  cargarGaleria(){
    this.galeriaService.cargarGaleria().subscribe(response=>{
      this.galerias = response.galerias;
      this.total = response.total;
    });
  }

  cargarGaleriaPortada(){
    this.galeriaService.cargarGaleriaPortada().subscribe(response=>{
      this.galeriasPortada = response.galerias;
      this.total = response.total;
    });
  }

}
