import { Component, OnInit, DoCheck } from '@angular/core';
import { Slider } from '../../models/slider';
import { SliderService } from 'src/app/services/slider/slider.service';
declare function init_slider();

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styles: []
})
export class SliderComponent implements OnInit, DoCheck {

  sliders: Slider[] = [];
  total: number;
  url: string;

  constructor(public sliderService: SliderService) {
    this.url = sliderService.urlUpload + 'sliders/';
  }

  ngOnInit() {
    this.sliderService.cargarSlider().subscribe(response=>{
      this.sliders = response.sliders;
      this.total = response.total;
      init_slider();
    });
  }

  ngDoCheck(){
    init_slider();
  }

}
