import { OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { SharedService } from 'src/app/services/shared/shared.service';
var BuscarComponent = /** @class */ (function () {
    function BuscarComponent(shared, router, route, domSanitizer) {
        this.shared = shared;
        this.router = router;
        this.route = route;
        this.domSanitizer = domSanitizer;
        this.notarios = [];
        this.latitud = -12.0839839;
        this.longitud = -77.0534367;
        // PAGINADO
        this.p = 1;
        this.autoHide = true;
        this.labels = {
            previousLabel: 'Anterior',
            nextLabel: 'Siguiente'
        };
    }
    BuscarComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.params.subscribe(function (parametros) {
            _this.shared.buscarNotarioLike(parametros['notarios']).subscribe(function (response) {
                _this.notarios = response.notarios;
                _this.total = response.total;
                _this.notarios.forEach(function (not) {
                    _this.latitud = not.latitud;
                    _this.longitud = not.longitud;
                    if (response.total === 1) {
                        _this.zoom = 16;
                    }
                    else if (response.total === 2) {
                        _this.zoom = 12;
                    }
                    else if (response.total === 3) {
                        _this.zoom = 8;
                    }
                    else if (response.total === 4) {
                        _this.zoom = 6;
                    }
                    else if (response.total === 5) {
                        _this.zoom = 4;
                    }
                    else if (response.total > 5) {
                        _this.zoom = 10;
                    }
                });
            });
        });
    };
    BuscarComponent.prototype.buscarMapa = function (item) {
        this.latitud = item.latitud;
        this.longitud = item.longitud;
        this.zoom = 18;
    };
    return BuscarComponent;
}());
export { BuscarComponent };
