import { OnInit } from '@angular/core';
import { VideosService } from 'src/app/services/videos/videos.service';
var VideoComponent = /** @class */ (function () {
    function VideoComponent(videosService) {
        this.videosService = videosService;
        this.videos = [];
        this.opciones = '?rel=0&amp;wmode=transparent';
        this.url = videosService.url + 'storage/videos/';
    }
    VideoComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.videosService.cargarVideosPortada().subscribe(function (response) {
            _this.videos = response.videos;
            _this.total = response.total;
        });
    };
    return VideoComponent;
}());
export { VideoComponent };
