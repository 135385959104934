import { Component, OnInit } from '@angular/core';
import { Galeria } from 'src/app/models/galeria';
import { urlBackendUpload } from 'src/app/models/GLOBAL';
import { GaleriaService } from 'src/app/services/galeria/galeria.service';

@Component({
  selector: 'app-album',
  templateUrl: './album.component.html',
  styles: []
})
export class AlbumComponent implements OnInit {

  galerias: Galeria[] = [];
  total: number;
  url: string = urlBackendUpload + 'galeria/';

  constructor(
    public galeriaService: GaleriaService,
  ) { }

  ngOnInit() {
    this.cargarGaleria();
  }

  cargarGaleria(){
    this.galeriaService.cargarGaleria().subscribe(response=>{
      this.galerias = response.galerias;
      this.total = response.total;
    });
  }

}
