import { OnInit } from '@angular/core';
import { BoletinesService } from '../../services/boletines/boletines.service';
import { Router } from '@angular/router';
import { urlBackendUpload } from 'src/app/models/GLOBAL';
import { InformacionService } from 'src/app/services/informacion/informacion.service';
var BoletinesComponent = /** @class */ (function () {
    function BoletinesComponent(informacionService, boletinesService, router) {
        this.informacionService = informacionService;
        this.boletinesService = boletinesService;
        this.router = router;
        this.informacion = [];
        this.boletines = [];
        this.boletinDetalle = [];
        this.totalBoletin = 0;
        this.url = urlBackendUpload + 'informacion/';
        this.urlBoletin = urlBackendUpload + "boletines/";
    }
    BoletinesComponent.prototype.ngOnInit = function () {
        this.cargarInformacion();
        this.cargarBoletines();
    };
    BoletinesComponent.prototype.cargarInformacion = function () {
        var _this = this;
        this.informacionService.cargarInformacion().subscribe(function (response) {
            _this.informacion = response.informacion;
            _this.total = response.total;
        });
    };
    BoletinesComponent.prototype.cargarBoletines = function () {
        var _this = this;
        this.boletinesService.cargarBoletines().subscribe(function (response) {
            _this.boletines = response.boletines;
            _this.totalBoletin = response.total;
        });
    };
    return BoletinesComponent;
}());
export { BoletinesComponent };
