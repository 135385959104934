import { OnInit } from '@angular/core';
import { urlBackendUpload } from 'src/app/models/GLOBAL';
import { NoticiasService } from 'src/app/services/noticias/noticias.service';
var PrensaListadoComponent = /** @class */ (function () {
    function PrensaListadoComponent(noticiasService) {
        this.noticiasService = noticiasService;
        this.url = urlBackendUpload + "noticias/";
        this.Anios = [];
        this.Meses = [];
        this.Noticias = [];
        // PAGINADO
        this.p = 1;
        this.autoHide = true;
        this.labels = {
            previousLabel: '',
            nextLabel: ''
        };
    }
    PrensaListadoComponent.prototype.ngOnInit = function () {
        this.cargarAnios();
        this.cargarMeses();
        this.cargarNoticias();
    };
    PrensaListadoComponent.prototype.cargarAnios = function () {
        var _this = this;
        this.noticiasService.listarAnios().subscribe(function (response) {
            _this.Anios = response.anios;
        });
    };
    PrensaListadoComponent.prototype.cargarMeses = function () {
        var _this = this;
        this.noticiasService.listarMeses().subscribe(function (response) {
            _this.Meses = response.meses;
        });
    };
    PrensaListadoComponent.prototype.cargarNoticias = function () {
        var _this = this;
        this.noticiasService.listarNoticias().subscribe(function (response) {
            _this.noticias = response.noticias;
        });
    };
    return PrensaListadoComponent;
}());
export { PrensaListadoComponent };
