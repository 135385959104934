import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mesesNumero'
})
export class MesesNumeroPipe implements PipeTransform {

  mesLetra:any;

  transform(value: any): any {

    switch (value) {
      case "12":
        this.mesLetra = "Diciembre";
        break;
      case "11":
        this.mesLetra = "Noviembre";
        break;
      case "10":
        this.mesLetra = "Octubre";
        break;
      case "09":
        this.mesLetra = "Setiembre";
        break;
      case "08":
        this.mesLetra = "Agosto";
        break;
      case "07":
        this.mesLetra = "Julio";
        break;
      case "06":
        this.mesLetra = "Junio";
        break;
      case "05":
        this.mesLetra = "Mayo";
        break;
      case "04":
        this.mesLetra = "Abril";
        break;
      case "03":
        this.mesLetra = "Marzo";
        break;
      case "02":
        this.mesLetra = "Febrero";
        break;
      case "01":
        this.mesLetra = "Enero";
        break;

      case 12:
        this.mesLetra = "Diciembre";
        break;
      case 11:
        this.mesLetra = "Noviembre";
        break;
      case 10:
        this.mesLetra = "Octubre";
        break;
      case 9:
        this.mesLetra = "Setiembre";
        break;
      case 8:
        this.mesLetra = "Agosto";
        break;
      case 7:
        this.mesLetra = "Julio";
        break;
      case 6:
        this.mesLetra = "Junio";
        break;
      case 5:
        this.mesLetra = "Mayo";
        break;
      case 4:
        this.mesLetra = "Abril";
        break;
      case 3:
        this.mesLetra = "Marzo";
        break;
      case 2:
        this.mesLetra = "Febrero";
        break;
      case 1:
        this.mesLetra = "Enero";
        break;
      default:
        this.mesLetra = "";
        break;
    }

    return this.mesLetra;
  }

}
