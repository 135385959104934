import { OnInit } from '@angular/core';
import { GaleriaService } from 'src/app/services/galeria/galeria.service';
import { urlBackendUpload } from 'src/app/models/GLOBAL';
var GaleriaComponent = /** @class */ (function () {
    function GaleriaComponent(galeriaService) {
        this.galeriaService = galeriaService;
        this.galerias = [];
        this.galeriasPortada = [];
        this.url = urlBackendUpload + 'galeria/';
        // PAGINADO
        this.p = 1;
        this.autoHide = true;
        this.labels = {
            previousLabel: 'Anterior',
            nextLabel: 'Siguiente'
        };
    }
    GaleriaComponent.prototype.ngOnInit = function () {
        this.cargarGaleria();
        this.cargarGaleriaPortada();
    };
    GaleriaComponent.prototype.cargarGaleria = function () {
        var _this = this;
        this.galeriaService.cargarGaleria().subscribe(function (response) {
            _this.galerias = response.galerias;
            _this.total = response.total;
        });
    };
    GaleriaComponent.prototype.cargarGaleriaPortada = function () {
        var _this = this;
        this.galeriaService.cargarGaleriaPortada().subscribe(function (response) {
            _this.galeriasPortada = response.galerias;
            _this.total = response.total;
        });
    };
    return GaleriaComponent;
}());
export { GaleriaComponent };
