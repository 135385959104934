export class Empresas {
  constructor(
    public id?:number,
    public colegio?: string,
    public descripcion?: string,
    public horario?:string,
    public correo?:string,
    public telefono?:string,
    public direccion?:string,
    public imagen?: string
  ) {}
}
