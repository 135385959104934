import { Component, OnInit } from '@angular/core';
import { urlBackendUpload } from 'src/app/models/GLOBAL';
import { Boletin } from 'src/app/models/boletin';
import { BoletinesService } from 'src/app/services/boletines/boletines.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: []
})
export class HomeComponent implements OnInit {

  boletines: Boletin[] = [];
  totalBoletin: number = 0;
  url: string = urlBackendUpload + 'informacion/';
  urlBoletin:string = urlBackendUpload + "boletines/";

  constructor(
    public boletinesService: BoletinesService
  ) { }

  ngOnInit() {
    this.cargarBoletines();
  }

  cargarBoletines(){
    this.boletinesService.cargarBoletines().subscribe(response=>{
      this.boletines = response.boletines;
      this.totalBoletin = response.total;
    });
  }

}
