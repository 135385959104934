import { Injectable } from '@angular/core';
import { Router, ActivationEnd } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})

export class BreadcrumbsService {

  constructor(
    private router:Router,
    private meta: Meta,
    private title: Title,
    ) { }

  getDataRoute(){
    return this.router.events.pipe(
      filter(evento => evento instanceof ActivationEnd),
      filter((evento:ActivationEnd) => evento.snapshot.firstChild === null),
      map( (evento:ActivationEnd)=> evento.snapshot.data)
    )
  }

  generateTag(config: any){

    this.title.setTitle(config.title);

    this.meta.updateTag({ name: 'description', content: config.title });
    this.meta.updateTag({ property: 'og:title', content: config.title });
    this.meta.updateTag({ property: 'og:description', content: config.description });
    this.meta.updateTag({ property: 'og:image', content: config.image });
    this.meta.updateTag({ property: 'og:url', content: config.slug });
  }
}
