import { OnInit, DoCheck } from '@angular/core';
import { NotariosService } from '../../../services/notarios/notarios.service';
import { urlBackendUpload } from 'src/app/models/GLOBAL';
import { Router } from '@angular/router';
import swal from 'sweetalert';
var ListaPrecioComponent = /** @class */ (function () {
    function ListaPrecioComponent(notariosServices, router) {
        this.notariosServices = notariosServices;
        this.router = router;
        this.data = [];
        this.total = 0;
        this.url = urlBackendUpload + 'listaprecios/';
        this.distritos = [];
        this.totalContacto = 0;
        this.totalAbogado = 0;
        this.notarios = [];
        this.panelOpenState = false;
    }
    ListaPrecioComponent.prototype.ngOnInit = function () {
        init_slider();
        this.cargarNotarios(11);
        this.cargarDistritos();
    };
    ListaPrecioComponent.prototype.ngDoCheck = function () {
        init_slider();
    };
    ListaPrecioComponent.prototype.cargarNotarios = function (sisev) {
        var _this = this;
        this.notariosServices.listaNotariosPrecio(sisev).subscribe(function (response) {
            _this.notarios = response.notarios;
            _this.total = response.total;
        });
    };
    ListaPrecioComponent.prototype.buscarNotario = function (termino) {
        var _this = this;
        if (termino != '') {
            this.notariosServices.buscarNotarioNuevo(termino).subscribe(function (response) {
                _this.notarios = response.notarios;
                _this.total = response.total;
            });
        }
        else {
            this.cargarNotarios(11);
        }
    };
    ListaPrecioComponent.prototype.buscarNotarioDistrito = function (termino) {
        var _this = this;
        if (termino != '0') {
            this.notariosServices.buscarNotarioDistrito(termino).subscribe(function (response) {
                _this.notarios = response.notarios;
                _this.total = response.total;
            });
        }
        else {
            this.cargarNotarios(11);
        }
    };
    ListaPrecioComponent.prototype.cargarDistritos = function () {
        var _this = this;
        this.notariosServices.cargarDistritos().subscribe(function (response) {
            _this.distritos = response.distritos;
        });
    };
    ListaPrecioComponent.prototype.descargarPDF = function (colegiatura) {
        if (colegiatura === 0) {
            swal('Sin datos encontrados');
            return;
        }
        window.open(this.url + colegiatura + '.pdf', '_blank');
    };
    ListaPrecioComponent.prototype.obtenerServicios = function (notarioId) {
        if (this.panelOpenState === true) {
            $("#" + notarioId).addClass('color-expand');
        }
        else {
            $("#" + notarioId).removeClass('color-expand');
        }
    };
    return ListaPrecioComponent;
}());
export { ListaPrecioComponent };
