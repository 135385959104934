<div class="afx adq">
    <app-slider></app-slider>
</div>

<div class="afx akn">
    <app-icon></app-icon>
</div>

<div class="afx ake">
    <div role="main" class="aem">
        <div id="homepage">
            <div class="row">
                <section class="col-lg-8" style="margin-bottom: 0px;">
                    <app-noticias></app-noticias>
                </section>

                <section class="col-lg-4">
                    <app-boletines></app-boletines>
                </section>

                <div class="col-lg-4" *ngIf="totalBoletin > 0">
                  <h2>
                      <span>Boletines</span>
                  </h2>
                  <article class="col-lg-12" *ngFor="let boletin of boletines">
                      <div class="acx">
                          <div class="adu aie">
                              <a [routerLink]="['/detalleBoletines', boletin.id]" class="acm" title="{{boletin.titulo}}">
                                  <img class="aeg" [src]="urlBoletin + boletin.imagen" alt="">
                              </a>
                          </div>
                          <p>
                              <a [routerLink]="['/detalleBoletines', boletin.id]" class="ahs aip aks">Leer más »</a>
                          </p>
                      </div>
                  </article>
                  <div class="ain"></div>
                </div>
            </div>
            <div id="sidebar_1" class="ags agg">
                <aside>
                    <!-- <app-importante></app-importante> -->
                    <app-eventos></app-eventos>

                </aside>
            </div>
        </div>
        <app-album></app-album>
        <div class="ain"></div>
    </div>

    <app-movil-template></app-movil-template>

</div>
