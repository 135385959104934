import { urlBackend, urlBackendUpload } from '../../models/GLOBAL';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var InformacionService = /** @class */ (function () {
    function InformacionService(http) {
        this.http = http;
        this.url = urlBackend;
        this.urlUpload = urlBackendUpload;
    }
    InformacionService.prototype.cargarInformacion = function () {
        return this.http.get(this.url + 'informacion');
    };
    InformacionService.ngInjectableDef = i0.defineInjectable({ factory: function InformacionService_Factory() { return new InformacionService(i0.inject(i1.HttpClient)); }, token: InformacionService, providedIn: "root" });
    return InformacionService;
}());
export { InformacionService };
