<app-breadcrumbs></app-breadcrumbs>

<div class="afx ake">
    <div role="main" class="aem">
      <section class="ain ahp">
        <div class="ais">
          <div class="akr ahg" style="margin-bottom: 8%;">
              <iframe class="aiq" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d959.5648073668801!2d-70.03004917083081!3d-15.842966999313823!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x915d69ec6284226b%3A0xf5840955887866f2!2sColegio+de+Notarios+de+Puno!5e0!3m2!1ses!2spe!4v1563395388960!5m2!1ses!2spe" width="600" height="450" frameborder="0" style="border:0" allowfullscreen></iframe>
          </div>
        </div>
      </section>
      <!-- <section class="agl ain" style="margin-top: 10%;">
        <div class="ain">
          <form #forma ngNativeValidate (ngSubmit)="enviarMensaje(forma)">
            <div class="ain">
              <label class="agi ais aie" for="firstname">Nombre: <span class="afc">*</span><br>
                <input type="text" [(ngModel)]="mensajes.nombres" name="nombres" size="22" required>
              </label>
              <label class="agi aie" for="email">Correo: <span class="afc">*</span><br>
                <input type="email" [(ngModel)]="mensajes.correo" name="correo" type="email" size="22" required>
              </label>
              <label class="agi aie" for="ref">Asunto:<br>
                <input type="text" [(ngModel)]="mensajes.asunto" name="asunto"size="22">
              </label>
            </div>
            <div class="ahw">
              <label for="message">Mensaje <span class="afc">*</span></label>
              <textarea [(ngModel)]="mensajes.mensaje" name="mensaje" cols="25" rows="10" required></textarea>
            </div>
            <input class="ahs air aec aks" type="submit" value="Enviar Mensaje">
          </form>
        </div>
      </section> -->
      <div class="ain"></div>
    </div>
  </div>
