import { PipeTransform } from '@angular/core';
var MesesPipe = /** @class */ (function () {
    function MesesPipe() {
    }
    MesesPipe.prototype.transform = function (value) {
        var mes = value.split('-')[1];
        switch (mes) {
            case '12':
                this.mesLetra = 'Diciembre';
                break;
            case '11':
                this.mesLetra = 'Noviembre';
                break;
            case '10':
                this.mesLetra = 'Octubre';
                break;
            case '09':
                this.mesLetra = 'Setiembre';
                break;
            case '08':
                this.mesLetra = 'Agosto';
                break;
            case '07':
                this.mesLetra = 'Julio';
                break;
            case '06':
                this.mesLetra = 'Junio';
                break;
            case '05':
                this.mesLetra = 'Mayo';
                break;
            case '04':
                this.mesLetra = 'Abril';
                break;
            case '03':
                this.mesLetra = 'Marzo';
                break;
            case '02':
                this.mesLetra = 'Febrero';
                break;
            case '01':
                this.mesLetra = 'Enero';
                break;
            default:
                this.mesLetra = '';
                break;
        }
        return this.mesLetra;
    };
    return MesesPipe;
}());
export { MesesPipe };
