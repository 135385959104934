import { urlBackend, urlBackendUpload } from '../../models/GLOBAL';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var GaleriaService = /** @class */ (function () {
    function GaleriaService(http) {
        this.http = http;
        this.url = urlBackend;
        this.urlUpload = urlBackendUpload;
    }
    GaleriaService.prototype.cargarGaleria = function () {
        return this.http.get(this.url + 'galeria');
    };
    GaleriaService.prototype.cargarGaleriaPortada = function () {
        return this.http.get(this.url + 'galeria/slider');
    };
    GaleriaService.prototype.obtenerGaleria = function (id) {
        return this.http.get(this.url + 'galeria/' + id);
    };
    GaleriaService.prototype.obtenerImagen = function (idGaleria) {
        return this.http.get(this.url + 'imagenGaleria/' + idGaleria);
    };
    GaleriaService.ngInjectableDef = i0.defineInjectable({ factory: function GaleriaService_Factory() { return new GaleriaService(i0.inject(i1.HttpClient)); }, token: GaleriaService, providedIn: "root" });
    return GaleriaService;
}());
export { GaleriaService };
