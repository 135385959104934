import { Component, OnInit } from '@angular/core';
import { EmpresasService } from 'src/app/services/empresas/empresas.service';
import { Empresas } from 'src/app/models/empresas';
import { urlBackendUpload } from 'src/app/models/GLOBAL';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styles: []
})
export class HeaderComponent implements OnInit {

  empresas: Empresas = new Empresas();
  url: string = urlBackendUpload + 'empresas/';

  constructor(
    public empresasService: EmpresasService
  ) {
  }

  ngOnInit() {
    this.empresasService.cargarEmpresas(1).subscribe(response => {
      this.empresas = response.empresas;
    });
  }
}
