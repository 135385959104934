import { ParsefloatPipe } from './parsefloat.pipe';
import { NgModule } from '@angular/core';
import { DomseguroPipe } from './domseguro.pipe';
import { CadenasPipe } from './cadenas.pipe';
import { DiasPipe } from './dias.pipe';
import { MesesPipe } from './meses.pipe';
import { FechaPipe } from './fecha.pipe';
import { CadenasLargasPipe } from './cadenas-largas.pipe';
import { MesesNumeroPipe } from './meses-numero.pipe';
import { HtmlseguroPipe } from './htmlseguro.pipe';
import { CadenasCortasPipe } from './cadenas-cortas.pipe';
import { CadenasLibrosPipe } from './cadenas-libros.pipe';

@NgModule({
  imports: [],
  declarations: [
    ParsefloatPipe,
    DomseguroPipe,
    CadenasPipe,
    DiasPipe,
    MesesPipe,
    FechaPipe,
    CadenasLargasPipe,
    MesesNumeroPipe,
    HtmlseguroPipe,
    CadenasCortasPipe,
    CadenasLibrosPipe
  ],
  exports: [
    ParsefloatPipe,
    DomseguroPipe,
    CadenasPipe,
    DiasPipe,
    MesesPipe,
    FechaPipe,
    CadenasLargasPipe,
    MesesNumeroPipe,
    HtmlseguroPipe,
    CadenasCortasPipe,
    CadenasLibrosPipe
  ]
})
export class PipesModule { }
