import { urlBackend } from 'src/app/models/GLOBAL';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var EmpresasService = /** @class */ (function () {
    function EmpresasService(http) {
        this.http = http;
        this.url = urlBackend;
    }
    EmpresasService.prototype.cargarEmpresas = function (id) {
        return this.http.get(this.url + "empresas/" + id);
    };
    EmpresasService.ngInjectableDef = i0.defineInjectable({ factory: function EmpresasService_Factory() { return new EmpresasService(i0.inject(i1.HttpClient)); }, token: EmpresasService, providedIn: "root" });
    return EmpresasService;
}());
export { EmpresasService };
