import { Injectable } from '@angular/core';
import { urlBackend } from 'src/app/models/GLOBAL';
import { HttpClient } from '@angular/common/http';
import { Mensajes } from 'src/app/models/mensajes';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MensajesService {

  url: string = urlBackend;

  constructor(public http: HttpClient) { }

  enviarMensaje(mensaje: Mensajes):Observable<any>{
    let json = JSON.stringify(mensaje);
    return this.http.post(this.url + 'mensajes', {json:json});
  }
}
