<div class="afx ajh">
    <div id="aen" class="ain">
        <div class="agh">
            <h2 class="agl">Boletín Institucional</h2>
        </div>
        <nav class="aex adv">
            <ul>
                <li><a routerLink="/home" title="Inicio">Inicio</a></li>
                <li><a routerLink="/boletines">Boletín Institucional</a></li>
                <li *ngIf="titulo"><a class="titulo" title="{{titulo}}">{{titulo}}</a></li>
            </ul>
        </nav>
    </div>
</div>

<div class="afx ake">

    <div role="main" class="aem">
        <div class="ain ahg">
            <div class="ais">
                <h1 *ngIf="titulo">{{titulo}}</h1>
                <ng-container *ngIf="total == 0">
                    <div class="aap">
                        <div class="ady ait">Sin registros encontrados.</div>
                    </div>
                </ng-container>
                <ng-container *ngIf="total > 0">
                    <div class="aap" *ngFor="let boletin of boletinDetalles">
                        <a *ngIf="boletin.archivo" [href]="url + boletin.archivo" class="abf" target="blank">
                            <span>
                <strong>Día {{boletin.fecha | dias}}({{boletin.fecha | date:'d'}}):</strong>
                  {{boletin.titulo}}
                <strong style="margin-left: 2%;">{{boletin.fecha | meses}}</strong>
              </span>
                        </a>
                        <a *ngIf="!boletin.archivo" class="abf" target="blank">
                            <span>
                <strong>Día {{boletin.fecha | dias}}({{boletin.fecha | date:'d'}}):</strong>
                  {{boletin.titulo}}
                <strong style="margin-left: 2%;">{{boletin.fecha | meses}}</strong>
              </span>
                        </a>
                    </div>
                </ng-container>
            </div>
        </div>
        <a routerLink="/boletines/9/1">Regresar</a>
        <div class="ain"></div>
    </div>

</div>
