<div role="main" class="aes" *ngIf="total > 0">
  <div class="ain agb">
  <div class="flex-container">
    <div class="flexslider afs acq">

      <div class="flex-viewport">
        <ul class="flex-slides">
          <li *ngFor="let entidad of entidades">
            <img *ngIf="!entidad.url" [src]="url + entidad.imagen" alt="" draggable="false" style="width:180px; height: 55px;">
            <a *ngIf="entidad.url" [href]="entidad.url" target="blank"><img [src]="url + entidad.imagen" alt="" draggable="false" style="width:180px; height: 55px;"></a>
          </li>
        </ul>
      </div>
      <ul class="flex-direction-nav">
        <li>
          <a class="flex-prev" href="#">
            <em>Previous</em>
          </a>
        </li>
        <li>
          <a class="flex-next" href="#">
            <em>Next</em>
          </a>
        </li>
      </ul>
    </div>
  </div>
  </div>
</div>
