<app-breadcrumbs></app-breadcrumbs>

<div class="afx ake" *ngIf="total > 0">

    <div role="main" class="aem">
        <div id="agv">
            <section>
                <h2>Galeria de Videos</h2>
                <ul class="ain">
                    <li class="agg" *ngFor="let video of videos | paginate: { itemsPerPage: 10, currentPage: p }" style="margin-right: 4%; margin-left: 0;">
                        <a class="" [routerLink]="['/detalleVideos', video.id]" title="{{video.titulo}}" style="margin-right: 5%;">
                            <img [src]="url + video.imagen" alt="">
                        </a>
                        <h3 class="text-center" style="margin-bottom: 12px;"><a [routerLink]="['/detalleVideos', video.id]">{{video.titulo}}</a></h3>
                    </li>
                </ul>
            </section>
            <nav class="adl" style="margin-bottom: 1%;">
                <pagination-controls [autoHide]="autoHide" [previousLabel]="labels.previousLabel" [nextLabel]="labels.nextLabel" (pageChange)="p = $event" class="text-center"></pagination-controls>
            </nav>
        </div>
        <div class="ain"></div>
    </div>

</div>
