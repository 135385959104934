import { RouterModule, Routes } from '@angular/router';

import { PagesComponent } from './pages.component';
import { HomeComponent } from './home/home.component';
import { BuscarComponent } from './buscar/buscar.component';
import { BuscarColegioComponent } from './buscar/buscar-colegio.component';
import { PaginasComponent } from './paginas/paginas.component';
import { PrensaNoticiasComponent } from './prensa-noticias/prensa-noticias.component';
import { PrensaBoletinesComponent } from './prensa-boletines/prensa-boletines.component';
import { PrensaEventosComponent } from './prensa-eventos/prensa-eventos.component';
import { DetalleNoticiaComponent } from './prensa-noticias/detalle-noticia.component';
import { BoletinDetalleComponent } from './prensa-boletines/boletin-detalle.component';
import { PrensaVideosComponent } from './prensa-videos/prensa-videos.component';
import { VideoDetalleComponent } from './prensa-videos/video-detalle.component';
import { GaleriaComponent } from './galeria/galeria.component';
import { GaleriaDetalleComponent } from './galeria/galeria-detalle.component';
import { PrensaListadoComponent } from './prensa-noticias/prensa-listado.component';
import { BuscarDistritoComponent } from './buscar/buscar-distrito.component';
import { MovilComponent } from './movil/movil.component';
import { NotariosComponent } from './notarios/notarios.component';
import { NotariosTodosComponent } from './notarios/notarios-todos/notarios-todos.component';
import { LibrosComponent } from './libros/libros.component';
import { LibroDetallesComponent } from './libros/libro-detalles/libro-detalles.component';
import { ListaPrecioComponent } from './notarios/lista-precio/lista-precio.component';
import { ContactoComponent } from './contacto/contacto.component';
import { ListaContactosComponent } from './notarios/lista-contactos/lista-contactos.component';
const pagesRoutes: Routes = [
  {
    path: "",
    component: PagesComponent,
    children: [
      {
        path: "home",
        component: HomeComponent,
        data: { titulo: "Colegio de Notarios de Puno" }
      },
      {
        path: "contacto",
        component: ContactoComponent,
        data: { titulo: "Contacto" }
      },
      {
        path: "notarios/:notarios",
        component: BuscarComponent,
        data: { titulo: "Búsqueda de Notarios" }
      },
      {
        path: "colegios/:terminos/:terminos2",
        component: BuscarColegioComponent,
        data: { titulo: "Búsqueda por Colegio" }
      },
      {
        path: "distrito/:nomdis",
        component: BuscarDistritoComponent,
        data: { titulo: "Búsqueda por Distrito" }
      },

      // PAGINAS
      {
        path: "paginas/:menuId/:submenuId",
        component: PaginasComponent,
        data: { titulo: "" }
      },

      // NOTICIAS
      {
        path: "saladeprensa",
        component: PrensaListadoComponent,
        data: { titulo: "Notas de Prensa" }
      },
      {
        path: "noticias",
        component: PrensaNoticiasComponent,
        data: { titulo: "Notas de Prensa" }
      },
      {
        path: "detalleNoticias/:id",
        component: DetalleNoticiaComponent,
        data: { titulo: "Detalles de Notas de Prensa" }
      },

      {
        path: "boletines",
        component: PrensaBoletinesComponent,
        data: { titulo: "Boletín Institucional" }
      },
      {
        path: "detalleBoletines/:id",
        component: BoletinDetalleComponent,
        data: { titulo: "Resumen de Boletín" }
      },

      {
        path: "eventos",
        component: PrensaEventosComponent,
        data: { titulo: "Eventos Académicos" }
      },

      {
        path: "videos",
        component: PrensaVideosComponent,
        data: { titulo: "Videos del CNL" }
      },
      {
        path: "detalleVideos/:id",
        component: VideoDetalleComponent,
        data: { titulo: "Resumen de Videos" }
      },

      {
        path: "galerias",
        component: GaleriaComponent,
        data: { titulo: "Galeria" }
      },
      {
        path: "detalleGaleria/:id",
        component: GaleriaDetalleComponent,
        data: { titulo: "Detalle de Galeria" }
      },

      {
        path: "movil",
        component: MovilComponent,
        data: { titulo: "Aplicación Móvil" }
      },

      // NOTARIOS
      {
        path: "notarios",
        component: NotariosComponent,
        data: { titulo: "Listado de Notarios" }
      },
      {
        path: "listaNotarios/:id/:pagina",
        component: NotariosTodosComponent,
        data: { titulo: "Listado de Notarios" }
      },
      {
        path: "informate-y-compara",
        component: ListaPrecioComponent,
        data: { titulo: "Relación actualizada de notarios según Ley 30908" }
      },
      {
        path: "libros",
        component: LibrosComponent,
        data: { titulo: "Fondo Editorial CNL" }
      },
      {
        path: "libroDetalles/:id",
        component: LibroDetallesComponent,
        data: { titulo: "Detalles Fondo Editorial CNL" }
      },

      {
        path: "listaContacto/:id",
        component: ListaContactosComponent,
        data: { titulo: "Listado de Contactos" }
      },

      { path: "", redirectTo: "home", pathMatch: "full" }
    ]
  }
];

// tslint:disable-next-line:eofline
export const PAGES_ROUTES = RouterModule.forChild(pagesRoutes);
