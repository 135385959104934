<ul class="ajz agl ahx">
  <li>Directorio Notarial:</li>
  <li>
    <form class="example-form">
      <mat-form-field class="example-full-width">
        <input #select type="text" placeholder="Colegio de Notarios de Ucayali" aria-label="Colegio de Notarios de Ucayali" matInput [formControl]="myColegios" [matAutocomplete]="colegio">
        <mat-autocomplete #colegio="matAutocomplete" [displayWith]="displayColegios">
          <mat-option *ngFor="let option of filteredColegios | async" [value]="option" (click)="mostrarNotarios(option)">
            {{option.colegios}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </form>
  </li>
</ul>

<ul class="ajz agl ahx">
  <li>
    <form class="example-form">
      <mat-form-field class="example-full-width">
        <input placeholder="Distritos" aria-label="Distritos" matInput [formControl]="myDistritos" [matAutocomplete]="distrito">
        <mat-autocomplete #distrito="matAutocomplete" [displayWith]="displayDistritos">
          <mat-option *ngFor="let option of filteredDistritos | async" [value]="option" (click)="mostrarNotariosDistritos(option.nomdis)">
            {{option.nomdis}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </form>
  </li>
  <li>
    <form class="example-form">
      <mat-form-field class="example-full-width">
        <input #input placeholder="Notario" aria-label="Notario" matInput [formControl]="myNotarios" [matAutocomplete]="notario">
        <mat-autocomplete #notario="matAutocomplete" [displayWith]="displayNotarios">
          <mat-option *ngFor="let option of filteredNotarios | async" [value]="option" (click)="filtrarNotario(select.value, input.value)">
            {{option.notario}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </form>
  </li>
  <li style="margin: 0;"><a title="Buscar" (click)="mostrarNotarios(21)" style="cursor: pointer;"><i class="fa fa-search"></i></a></li>
</ul>
