import { OnInit } from '@angular/core';
import { urlBackendUpload } from 'src/app/models/GLOBAL';
import { Movil } from 'src/app/models/movil';
import { MovilService } from 'src/app/services/movil/movil.service';
var MovilTemplateComponent = /** @class */ (function () {
    function MovilTemplateComponent(movilService) {
        this.movilService = movilService;
        this.url = urlBackendUpload + 'movil/';
        this.movil = new Movil();
    }
    MovilTemplateComponent.prototype.ngOnInit = function () {
        this.cargarMovil();
    };
    MovilTemplateComponent.prototype.cargarMovil = function () {
        var _this = this;
        this.movilService.cargarMovil().subscribe(function (response) {
            _this.movil = response.moviles;
        });
    };
    return MovilTemplateComponent;
}());
export { MovilTemplateComponent };
