import { OnInit } from '@angular/core';
import { urlBackendUpload } from 'src/app/models/GLOBAL';
import { MovilService } from 'src/app/services/movil/movil.service';
import { Movil } from 'src/app/models/movil';
var MovilComponent = /** @class */ (function () {
    function MovilComponent(movilService) {
        this.movilService = movilService;
        this.url = urlBackendUpload + 'movil/';
        this.movil = new Movil();
    }
    MovilComponent.prototype.ngOnInit = function () {
        this.cargarMovil();
    };
    MovilComponent.prototype.cargarMovil = function () {
        var _this = this;
        this.movilService.cargarMovil().subscribe(function (response) {
            _this.movil = response.moviles;
        });
    };
    return MovilComponent;
}());
export { MovilComponent };
