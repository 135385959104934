import { OnInit } from '@angular/core';
import { Empresas } from 'src/app/models/empresas';
import { urlBackendUpload } from 'src/app/models/GLOBAL';
import { EmpresasService } from 'src/app/services/empresas/empresas.service';
var FooterComponent = /** @class */ (function () {
    function FooterComponent(empresasService) {
        this.empresasService = empresasService;
        this.empresas = new Empresas();
        this.url = urlBackendUpload + 'empresas/';
        this.fecha = new Date();
        this.urlFacebook = 'https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FColegio-De-Notarios-De-Ica-1462365380686522%2F&tabs=timeline&width=340&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId';
    }
    FooterComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.empresasService.cargarEmpresas(1).subscribe(function (response) {
            _this.empresas = response.empresas;
        });
    };
    return FooterComponent;
}());
export { FooterComponent };
