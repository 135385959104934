import { urlBackend, urlBackendUpload } from '../../models/GLOBAL';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var SliderService = /** @class */ (function () {
    function SliderService(http) {
        this.http = http;
        this.url = urlBackend;
        this.urlUpload = urlBackendUpload;
    }
    SliderService.prototype.cargarSlider = function () {
        return this.http.get(this.url + 'slider');
    };
    SliderService.ngInjectableDef = i0.defineInjectable({ factory: function SliderService_Factory() { return new SliderService(i0.inject(i1.HttpClient)); }, token: SliderService, providedIn: "root" });
    return SliderService;
}());
export { SliderService };
