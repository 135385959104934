import { OnInit } from '@angular/core';
import { urlBackendUpload } from 'src/app/models/GLOBAL';
import { BoletinesService } from 'src/app/services/boletines/boletines.service';
var HomeComponent = /** @class */ (function () {
    function HomeComponent(boletinesService) {
        this.boletinesService = boletinesService;
        this.boletines = [];
        this.totalBoletin = 0;
        this.url = urlBackendUpload + 'informacion/';
        this.urlBoletin = urlBackendUpload + "boletines/";
    }
    HomeComponent.prototype.ngOnInit = function () {
        this.cargarBoletines();
    };
    HomeComponent.prototype.cargarBoletines = function () {
        var _this = this;
        this.boletinesService.cargarBoletines().subscribe(function (response) {
            _this.boletines = response.boletines;
            _this.totalBoletin = response.total;
        });
    };
    return HomeComponent;
}());
export { HomeComponent };
