<div class="afx ajh">
  <div id="aen" class="ain">
    <div class="agh">
      <h2 class="agl">{{label}}</h2>
    </div>
    <nav class="aex adv">
      <ul>
        <li><a routerLink="/home" title="Inicio">Inicio</a></li>
        <li><a>Página</a></li>
        <li><a class="titulo" title="{{label}}">{{label}}</a></li>
      </ul>
    </nav>
  </div>
</div>
