/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "@angular/router";
import * as i3 from "../../pipes/fecha.pipe";
import * as i4 from "../../pipes/cadenas.pipe";
import * as i5 from "./paginas.component";
import * as i6 from "../../services/paginas/paginas.service";
import * as i7 from "../../services/noticias/noticias.service";
import * as i8 from "../../services/galeria/galeria.service";
var styles_PaginasComponent = [];
var RenderType_PaginasComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PaginasComponent, data: {} });
export { RenderType_PaginasComponent as RenderType_PaginasComponent };
function View_PaginasComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "aeg ahg text-center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "img", [["alt", ""]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.url + _co.pagina.imagen); _ck(_v, 1, 0, currVal_0); }); }
function View_PaginasComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "a", [["target", "blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Verificaci\u00F3n Biom\u00E9trica Notarios Puno."]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.url + _co.pagina.archivo); _ck(_v, 0, 0, currVal_0); }); }
function View_PaginasComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "p", [["style", "text-align: justify;"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Descargar: "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PaginasComponent_5)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.pagina.menu_id === 3); _ck(_v, 3, 0, currVal_0); }, null); }
function View_PaginasComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [["class", "aem"], ["role", "main"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 5, "article", [["class", "aiq"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PaginasComponent_3)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(4, 0, null, null, 0, "p", [["style", "text-align: justify;"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PaginasComponent_4)), i0.ɵdid(6, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(7, 0, null, null, 0, "div", [["class", "ain"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.pagina.imagen; _ck(_v, 3, 0, currVal_0); var currVal_2 = _co.pagina.archivo; _ck(_v, 6, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.pagina.contenido; _ck(_v, 4, 0, currVal_1); }); }
function View_PaginasComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "ady ajt ait"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Sin registros encontrados "])), (_l()(), i0.ɵeld(2, 0, null, null, 1, "a", [["class", "aiu"], ["href", "javascript:void(0)"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u00D7"]))], null, null); }
function View_PaginasComponent_9(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 23, "article", [["class", "aiq ais"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "h2", [["class", "agl akm aic"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 3, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 3).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(3, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i1.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i0.ɵpad(4, 2), (_l()(), i0.ɵted(5, null, ["", ""])), (_l()(), i0.ɵeld(6, 0, null, null, 4, "div", [["class", "d18 ais aic aeg mr-10"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 3, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 8).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(8, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i1.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i0.ɵpad(9, 2), (_l()(), i0.ɵeld(10, 0, null, null, 0, "img", [["alt", ""]], [[8, "src", 4]], null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 3, "p", [["class", "agl aec"]], null, null, null, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 2, "strong", [], null, null, null, null, null)), (_l()(), i0.ɵted(13, null, ["", ""])), i0.ɵppd(14, 1), (_l()(), i0.ɵeld(15, 0, null, null, 2, "div", [["style", "text-align: justify;margin-right: 3%;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(16, 0, null, null, 1, "p", [], [[8, "innerHTML", 1]], null, null, null, null)), i0.ɵppd(17, 1), (_l()(), i0.ɵeld(18, 0, null, null, 4, "p", [["class", "agl"]], null, null, null, null, null)), (_l()(), i0.ɵeld(19, 0, null, null, 3, "a", [["class", "ahs aip aks"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 20).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(20, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i1.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i0.ɵpad(21, 2), (_l()(), i0.ɵted(-1, null, ["Leer m\u00E1s \u00BB"])), (_l()(), i0.ɵeld(23, 0, null, null, 0, "hr", [], null, null, null, null, null))], function (_ck, _v) { var currVal_2 = _ck(_v, 4, 0, "/detalleNoticias", _v.context.$implicit.id); _ck(_v, 3, 0, currVal_2); var currVal_6 = _ck(_v, 9, 0, "/detalleNoticias", _v.context.$implicit.id); _ck(_v, 8, 0, currVal_6); var currVal_12 = _ck(_v, 21, 0, "/detalleNoticias", _v.context.$implicit.id); _ck(_v, 20, 0, currVal_12); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 3).target; var currVal_1 = i0.ɵnov(_v, 3).href; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_3 = _v.context.$implicit.titulo; _ck(_v, 5, 0, currVal_3); var currVal_4 = i0.ɵnov(_v, 8).target; var currVal_5 = i0.ɵnov(_v, 8).href; _ck(_v, 7, 0, currVal_4, currVal_5); var currVal_7 = (_co.urlNoticias + _v.context.$implicit.imagen); _ck(_v, 10, 0, currVal_7); var currVal_8 = i0.ɵunv(_v, 13, 0, _ck(_v, 14, 0, i0.ɵnov(_v.parent.parent.parent.parent, 0), _v.context.$implicit.fecha)); _ck(_v, 13, 0, currVal_8); var currVal_9 = i0.ɵunv(_v, 16, 0, _ck(_v, 17, 0, i0.ɵnov(_v.parent.parent.parent.parent, 1), _v.context.$implicit.desc_small)); _ck(_v, 16, 0, currVal_9); var currVal_10 = i0.ɵnov(_v, 20).target; var currVal_11 = i0.ɵnov(_v, 20).href; _ck(_v, 19, 0, currVal_10, currVal_11); }); }
function View_PaginasComponent_8(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "aiq ais"], ["id", "ado"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PaginasComponent_9)), i0.ɵdid(2, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.noticias; _ck(_v, 2, 0, currVal_0); }, null); }
function View_PaginasComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "aem"], ["role", "main"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PaginasComponent_7)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PaginasComponent_8)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.total === 0); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.total > 0); _ck(_v, 4, 0, currVal_1); }, null); }
function View_PaginasComponent_11(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "ady ajt ait"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Sin registros encontrados "])), (_l()(), i0.ɵeld(2, 0, null, null, 1, "a", [["class", "aiu"], ["href", "javascript:void(0)"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u00D7"]))], null, null); }
function View_PaginasComponent_13(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "article", [["class", "aiq ais"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "h2", [["class", "agl akm aic"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 3, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 3).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(3, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i1.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i0.ɵpad(4, 2), (_l()(), i0.ɵted(5, null, ["", ""])), (_l()(), i0.ɵeld(6, 0, null, null, 4, "div", [["class", "d18 ais aic aeg mr-10"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 3, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 8).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(8, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i1.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i0.ɵpad(9, 2), (_l()(), i0.ɵeld(10, 0, null, null, 0, "img", [["alt", ""]], [[8, "src", 4]], null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 0, "hr", [], null, null, null, null, null))], function (_ck, _v) { var currVal_2 = _ck(_v, 4, 0, "/detalleGaleria", _v.context.$implicit.id); _ck(_v, 3, 0, currVal_2); var currVal_6 = _ck(_v, 9, 0, "/detalleGaleria", _v.context.$implicit.id); _ck(_v, 8, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 3).target; var currVal_1 = i0.ɵnov(_v, 3).href; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_3 = _v.context.$implicit.titulo; _ck(_v, 5, 0, currVal_3); var currVal_4 = i0.ɵnov(_v, 8).target; var currVal_5 = i0.ɵnov(_v, 8).href; _ck(_v, 7, 0, currVal_4, currVal_5); var currVal_7 = (_co.urlGaleria + _v.context.$implicit.imagen); _ck(_v, 10, 0, currVal_7); }); }
function View_PaginasComponent_12(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "aiq ais"], ["id", "ado"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PaginasComponent_13)), i0.ɵdid(2, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.galerias; _ck(_v, 2, 0, currVal_0); }, null); }
function View_PaginasComponent_10(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "aem"], ["role", "main"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PaginasComponent_11)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PaginasComponent_12)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.totalGaleria === 0); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.totalGaleria > 0); _ck(_v, 4, 0, currVal_1); }, null); }
function View_PaginasComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "afx ake"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PaginasComponent_2)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PaginasComponent_6)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PaginasComponent_10)), i0.ɵdid(6, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.pagina.menu_id !== "2") && (_co.pagina.submenu !== 1)); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.pagina.menu_id === 2); _ck(_v, 4, 0, currVal_1); var currVal_2 = ((_co.pagina.menu_id === 1) && (_co.pagina.submenu_id === 1)); _ck(_v, 6, 0, currVal_2); }, null); }
export function View_PaginasComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i3.FechaPipe, []), i0.ɵpid(0, i4.CadenasPipe, []), (_l()(), i0.ɵeld(2, 0, null, null, 4, "div", [["class", "afx ajh"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 3, "div", [["class", "ain"], ["id", "aen"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 2, "div", [["class", "agh"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 1, "h2", [["class", "agl"]], null, null, null, null, null)), (_l()(), i0.ɵted(6, null, ["", ""])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PaginasComponent_1)), i0.ɵdid(8, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.pagina; _ck(_v, 8, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.pagina.titulo; _ck(_v, 6, 0, currVal_0); }); }
export function View_PaginasComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-paginas", [], null, null, null, View_PaginasComponent_0, RenderType_PaginasComponent)), i0.ɵdid(1, 114688, null, 0, i5.PaginasComponent, [i2.ActivatedRoute, i6.PaginasService, i7.NoticiasService, i8.GaleriaService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PaginasComponentNgFactory = i0.ɵccf("app-paginas", i5.PaginasComponent, View_PaginasComponent_Host_0, {}, {}, []);
export { PaginasComponentNgFactory as PaginasComponentNgFactory };
