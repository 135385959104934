import { Informacion } from '../../models/informacion';
import { Component, OnInit } from '@angular/core';
import { BoletinesService } from '../../services/boletines/boletines.service';
import { Boletin } from '../../models/boletin';
import { BoletinDetalle } from '../../models/boletinDetalle';
import { Router } from '@angular/router';
import { urlBackendUpload } from 'src/app/models/GLOBAL';
import { InformacionService } from 'src/app/services/informacion/informacion.service';

@Component({
  selector: 'app-boletines',
  templateUrl: './boletines.component.html',
  styles: []
})
export class BoletinesComponent implements OnInit {

  informacion: Informacion[] = [];
  boletines: Boletin[] = [];
  boletinDetalle: BoletinDetalle[] = [];
  total: number;
  totalBoletin: number = 0;
  url: string = urlBackendUpload + 'informacion/';
  urlBoletin:string = urlBackendUpload + "boletines/";

  constructor(
    public informacionService: InformacionService,
    public boletinesService: BoletinesService,
    public router: Router
  ) {}

  ngOnInit() {
    this.cargarInformacion();
    this.cargarBoletines();
  }

  cargarInformacion(){
    this.informacionService.cargarInformacion().subscribe(response=>{
      this.informacion = response.informacion;
      this.total = response.total;
    });
  }

  cargarBoletines(){
    this.boletinesService.cargarBoletines().subscribe(response=>{
      this.boletines = response.boletines;
      this.totalBoletin = response.total;
    });
  }
}
