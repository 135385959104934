import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

import { NotariosService } from './notarios/notarios.service';
import { LibrosService } from './libros/libros.service';
import { SharedService } from './shared/shared.service';
import { GaleriaService } from './galeria/galeria.service';
import { BreadcrumbsService } from './shared/breadcrumbs.service';
import { SliderService } from './slider/slider.service';
import { NoticiasService } from './noticias/noticias.service';
import { EntidadesService } from './entidades/entidades.service';
import { VideosService } from './videos/videos.service';
import { MenuService } from './menu/menu.service';
import { PaginasService } from './paginas/paginas.service';
import { EventosService } from './eventos/eventos.service';
import { InformacionService } from './informacion/informacion.service';
import { EmpresasService } from './empresas/empresas.service';
import { MensajesService } from './mensajes/mensajes.service';

 @NgModule({
 imports: [
     CommonModule,
     HttpClientModule
  ],
  providers: [
    BreadcrumbsService,
    SliderService,
    NoticiasService,
    VideosService,
    EntidadesService,
    MenuService,
    PaginasService,
    EventosService,
    InformacionService,
    GaleriaService,
    NotariosService,
    LibrosService,
    SharedService,
    EmpresasService,
    MensajesService
  ],
  declarations: []
})
export class ServiceModule { }
