import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotariosService } from '../../../services/notarios/notarios.service';
import { Notarios } from 'src/app/models/notarios';
import { urlBackendUpload } from 'src/app/models/GLOBAL';
import swal from 'sweetalert';
var ListaContactosComponent = /** @class */ (function () {
    function ListaContactosComponent(activatedRoute, notariosService, router, route) {
        this.activatedRoute = activatedRoute;
        this.notariosService = notariosService;
        this.router = router;
        this.route = route;
        this.contactos = [];
        this.abogados = [];
        this.total = 0;
        this.totalAbogado = 0;
        this.notario = new Notarios();
        this.contactoArray = [];
        this.contactoFor = [];
        this.abogadoArray = [];
        this.abogadoFor = [];
        this.url = urlBackendUpload + 'listaprecios/';
    }
    ListaContactosComponent.prototype.ngOnInit = function () {
        this.cargarMapa();
    };
    ListaContactosComponent.prototype.descargarPDF = function (colegiatura) {
        if (colegiatura === 0) {
            swal('Sin datos encontrados');
            return;
        }
        window.open(this.url + colegiatura + '.pdf', '_blank');
    };
    ListaContactosComponent.prototype.cargarMapa = function () {
        var _this = this;
        this.route.params.subscribe(function (parametros) {
            _this.notariosService.obtenerNotario(parametros['id']).subscribe(function (response) {
                console.log(response);
                _this.notario = response.notarios;
            });
        });
    };
    return ListaContactosComponent;
}());
export { ListaContactosComponent };
