var Mensajes = /** @class */ (function () {
    function Mensajes(id, nombres, correo, asunto, mensaje) {
        this.id = id;
        this.nombres = nombres;
        this.correo = correo;
        this.asunto = asunto;
        this.mensaje = mensaje;
    }
    return Mensajes;
}());
export { Mensajes };
