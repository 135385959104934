<h2 *ngIf="total > 0">
  <span>Videos / <a class="acl" title="Youtube" target="blank" href="https://www.youtube.com/user/cnotarioslima">Canal de Youtube</a></span>
</h2>
<div class="flex-container" *ngIf="total > 0" style="margin-bottom: 18%;">
  <div class="flexslider aax">
    <ul class="flex-slides">
      <li *ngFor="let video of videos">
        <div class="afa">
          <div class="fluid-width-video-wrapper" style="padding-top: 102.609%;">
            <iframe [src]="video.url + opciones | domseguro"
            frameborder="0" allow="autoplay; encrypted-media" allowfullscreen width="230" height="236"></iframe>
          </div>
        </div>
      </li>
    </ul>
  </div>
</div>

