<div id="ajd" class="ain">
    <ul class="agl">
        <li><a class="ajf" routerlink="/notarios" href="#/notarios"><div class="ahh"><span class="aku aid"><img alt="" class="img-fluid" src="assets/img/iconos/notarios.png"></span></div><h6 class="aic">NOTARIOS CON <br> HERRAM TEC.</h6></a></li>
        <li>
          <a class="ajf" href="https://cel.reniec.gob.pe/celweb/index.html" target="_blank">
            <div class="ahh">
              <span class="aku aid">
                <img src="assets/img/iconos/notarios.png" alt="" class="img-fluid">
              </span>
            </div>
            <h6 class="aic">CONSULTAS EN<br> LINEA</h6>
          </a>
        </li>
        <li>
            <a class="ajf" href="https://serviciosbiometricos.reniec.gob.pe/identifica/verification.do" target="_blank">
                <div class="ahh">
                    <span class="aku aid">
            <img src="assets/img/iconos/aula-virtual.png" alt="" class="img-fluid">
          </span>
                </div>
                <h6 class="aic">IDENTIFICA <br> RENIEC</h6>
            </a>
        </li>

        <li>
            <a class="ajf" href="https://www.migraciones.gob.pe/" target="_blank">
                <div class="ahh">
                    <span class="aku aid">
            <img src="assets/img/iconos/infonot.png" alt="" class="img-fluid">
          </span>
                </div>
                <h6 class="aic">MIGRACIONES <br> PERU</h6>
            </a>
        </li>
        <li>
            <a class="ajf" href="https://www.sunarp.gob.pe/w-sid/index.html" target="_blank">
                <div class="ahh">
                    <span class="aku aid">
            <img src="assets/img/iconos/aula-virtual.png" alt="" class="img-fluid">
          </span>
                </div>
                <h6 class="aic">SID <br> SUNARP</h6>
            </a>
        </li>
    </ul>
</div>
