/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./prensa-eventos.component";
var styles_PrensaEventosComponent = [];
var RenderType_PrensaEventosComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PrensaEventosComponent, data: {} });
export { RenderType_PrensaEventosComponent as RenderType_PrensaEventosComponent };
export function View_PrensaEventosComponent_0(_l) { return i0.ɵvid(0, [], null, null); }
export function View_PrensaEventosComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-prensa-eventos", [], null, null, null, View_PrensaEventosComponent_0, RenderType_PrensaEventosComponent)), i0.ɵdid(1, 114688, null, 0, i1.PrensaEventosComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PrensaEventosComponentNgFactory = i0.ɵccf("app-prensa-eventos", i1.PrensaEventosComponent, View_PrensaEventosComponent_Host_0, {}, {}, []);
export { PrensaEventosComponentNgFactory as PrensaEventosComponentNgFactory };
