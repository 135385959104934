import { urlBackend } from 'src/app/models/GLOBAL';
import { HttpClient } from '@angular/common/http';
var SharedService = /** @class */ (function () {
    function SharedService(http) {
        this.http = http;
        this.url = urlBackend;
    }
    SharedService.prototype.getNotarios = function () {
        return this.http.get(this.url + "notarios");
    };
    SharedService.prototype.getColegios = function () {
        return this.http.get(this.url + "colegios");
    };
    SharedService.prototype.buscarNotarioLike = function (termino) {
        return this.http.get(this.url + "notarios/like/" + termino);
    };
    SharedService.prototype.buscarColegio = function (termino) {
        return this.http.get(this.url + "colegios/like/" + termino);
    };
    SharedService.prototype.listarNotariosXcolegios = function (sisev) {
        return this.http.get(this.url + "colegios/" + sisev);
    };
    SharedService.prototype.buscarNotariosAll = function (termino1, termino2) {
        return this.http.get(this.url + "notarios/all/" + termino1 + '/' + termino2);
    };
    SharedService.prototype.buscarNotarioXColegio = function (termino, termino2) {
        return this.http.get(this.url + "colegios/" + termino + "/" + termino2);
    };
    SharedService.prototype.buscarGrupos = function (terminos, terminos2) {
        return this.http.get(this.url + "grupos/" + terminos + "/" + terminos2);
    };
    SharedService.prototype.getDistritos = function () {
        return this.http.get(this.url + "distritos");
    };
    SharedService.prototype.buscarNotarioXDistrito = function (nomdis) {
        return this.http.get(this.url + "distritos/" + nomdis);
    };
    SharedService.prototype.buscarDistrito = function (nomdis) {
        return this.http.get(this.url + "distritos/like/" + nomdis);
    };
    return SharedService;
}());
export { SharedService };
