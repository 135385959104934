<app-breadcrumbs></app-breadcrumbs>

<div class="afx ake">
    <div role="main" class="aem">
        <div class="ain ahp">

          <div class="aji">
            <div class="ajo pull-left aie">
              <form class="example-form">
                <mat-form-field class="example-full-width">
                  <input #input matInput placeholder="Notario" (keyup)="buscarNotarioTodos(input.value)">
                </mat-form-field>
              </form>
            </div>

            <div class="agk pull-left">
              <form class="example-form">
                <select #estado placeholder="Estado" (change)="buscarNotariosEstado(estado.value)" class="form-control">
                  <option value="3">Estado (Todos)</option>
                  <option value="0">Activo</option>
                  <option value="1">Cesado(a)</option>
                  <option value="2">Licencia</option>
                </select>
              </form>
            </div>
          </div>

          <div class="aji">
            <div class="acu">
                <div class="aap" *ngIf="total == 0">
                      <div style="float: left;width: 100%;" class="ady ait">Sin registros encontrados.</div>
                  </div>
                <table class="table table-light" *ngIf="total > 0">
                    <thead class="thead-light">
                        <tr>
                            <th>N°</th>
                            <th>LISTADO DE NOTARIOS</th>
                            <th>ESTADO</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let notario of notarios  | paginate: { itemsPerPage: 30, currentPage: p }; let i = index; ">
                            <td style="width: 30%;">{{i+1}}</td>
                            <td>{{notario.notario}}</td>

                            <td *ngIf="notario.estado == 0"><strong style="color:green;">ACTIVO</strong></td>
                            <td *ngIf="notario.estado == 1"><strong style="color:red;">CESADO(A)</strong></td>
                            <td *ngIf="notario.estado == 2"><strong style="color:darkslateblue;">LICENCIA</strong></td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan="3">
                                <nav class="adl" style="margin-bottom: 1%;">
                                    <pagination-controls [autoHide]="autoHide" [previousLabel]="labels.previousLabel" [nextLabel]="labels.nextLabel" (pageChange)="p = $event" class="text-center"></pagination-controls>
                                </nav>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
          </div>

        </div>
        <div class="ain"></div>
    </div>
</div>
