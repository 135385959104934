/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./entidades.component";
import * as i3 from "../../services/entidades/entidades.service";
var styles_EntidadesComponent = [];
var RenderType_EntidadesComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_EntidadesComponent, data: {} });
export { RenderType_EntidadesComponent as RenderType_EntidadesComponent };
function View_EntidadesComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "img", [["alt", ""], ["draggable", "false"], ["style", "width:180px; height: 55px;"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.url + _v.parent.context.$implicit.imagen); _ck(_v, 0, 0, currVal_0); }); }
function View_EntidadesComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "a", [["target", "blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "img", [["alt", ""], ["draggable", "false"], ["style", "width:180px; height: 55px;"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.parent.context.$implicit.url; _ck(_v, 0, 0, currVal_0); var currVal_1 = (_co.url + _v.parent.context.$implicit.imagen); _ck(_v, 1, 0, currVal_1); }); }
function View_EntidadesComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "li", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EntidadesComponent_3)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EntidadesComponent_4)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = !_v.context.$implicit.url; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.url; _ck(_v, 4, 0, currVal_1); }, null); }
function View_EntidadesComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 16, "div", [["class", "aes"], ["role", "main"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 15, "div", [["class", "ain agb"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 14, "div", [["class", "flex-container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 13, "div", [["class", "flexslider afs acq"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 3, "div", [["class", "flex-viewport"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 2, "ul", [["class", "flex-slides"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EntidadesComponent_2)), i0.ɵdid(7, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵeld(8, 0, null, null, 8, "ul", [["class", "flex-direction-nav"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 3, "li", [], null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 2, "a", [["class", "flex-prev"], ["href", "#"]], null, null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 1, "em", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Previous"])), (_l()(), i0.ɵeld(13, 0, null, null, 3, "li", [], null, null, null, null, null)), (_l()(), i0.ɵeld(14, 0, null, null, 2, "a", [["class", "flex-next"], ["href", "#"]], null, null, null, null, null)), (_l()(), i0.ɵeld(15, 0, null, null, 1, "em", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Next"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.entidades; _ck(_v, 7, 0, currVal_0); }, null); }
export function View_EntidadesComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_EntidadesComponent_1)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.total > 0); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_EntidadesComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-entidades", [], null, null, null, View_EntidadesComponent_0, RenderType_EntidadesComponent)), i0.ɵdid(1, 114688, null, 0, i2.EntidadesComponent, [i3.EntidadesService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EntidadesComponentNgFactory = i0.ɵccf("app-entidades", i2.EntidadesComponent, View_EntidadesComponent_Host_0, {}, {}, []);
export { EntidadesComponentNgFactory as EntidadesComponentNgFactory };
