import { PipeTransform } from '@angular/core';
var ParsefloatPipe = /** @class */ (function () {
    function ParsefloatPipe() {
    }
    ParsefloatPipe.prototype.transform = function (value) {
        return parseFloat(value);
    };
    return ParsefloatPipe;
}());
export { ParsefloatPipe };
