import { OnInit } from '@angular/core';
import { Mensajes } from 'src/app/models/mensajes';
import { MensajesService } from 'src/app/services/mensajes/mensajes.service';
var ContactoComponent = /** @class */ (function () {
    function ContactoComponent(mensajesService) {
        this.mensajesService = mensajesService;
        this.mensajes = new Mensajes();
    }
    ContactoComponent.prototype.ngOnInit = function () {
    };
    ContactoComponent.prototype.enviarMensaje = function (forma) {
        if (forma.invalid) {
            return;
        }
        this.mensajesService.enviarMensaje(this.mensajes).subscribe(function (response) {
            swal('Mensaje', response.message, response.status);
            forma.reset();
        }, function (error) {
            console.log(error);
        });
    };
    return ContactoComponent;
}());
export { ContactoComponent };
