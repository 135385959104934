export class Paginas {
  constructor(
    public id?:number,
    public menu_id?: number,
    public submenu_id?: number,
    public titulo?: string,
    public imagen?:string,
    public archivo?:string,
    public contenido?:string,
    public orden?:number,
    public status?:number,
  ) {}
}
