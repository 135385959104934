/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/common";
import * as i3 from "./nav.component";
import * as i4 from "../../services/menu/menu.service";
var styles_NavComponent = [];
var RenderType_NavComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_NavComponent, data: {} });
export { RenderType_NavComponent as RenderType_NavComponent };
function View_NavComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "a", [], [[8, "title", 0], [1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 671744, [[4, 4]], 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i0.ɵpad(2, 3), (_l()(), i0.ɵted(3, null, ["", ""]))], function (_ck, _v) { var currVal_3 = _ck(_v, 2, 0, "/paginas", _v.parent.context.$implicit.id, 0); _ck(_v, 1, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i0.ɵinlineInterpolate(1, "", _v.parent.context.$implicit.nommenu, ""); var currVal_1 = i0.ɵnov(_v, 1).target; var currVal_2 = i0.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); var currVal_4 = _v.parent.context.$implicit.nommenu; _ck(_v, 3, 0, currVal_4); }); }
function View_NavComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "a", [["class", "akb"]], [[8, "title", 0]], [[null, "mouseover"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseover" === en)) {
        var pd_0 = (_co.submenu($event.type, _v.parent.context.$implicit.id) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = i0.ɵinlineInterpolate(1, "", _v.parent.context.$implicit.nommenu, ""); _ck(_v, 0, 0, currVal_0); var currVal_1 = _v.parent.context.$implicit.nommenu; _ck(_v, 1, 0, currVal_1); }); }
function View_NavComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "a", [["class", "submenu"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 671744, [[4, 4]], 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i0.ɵpad(2, 3), (_l()(), i0.ɵted(3, null, [" ", " "]))], function (_ck, _v) { var currVal_2 = _ck(_v, 2, 0, "/paginas", _v.parent.parent.parent.context.$implicit.id, _v.parent.context.$implicit.id); _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).target; var currVal_1 = i0.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = _v.parent.context.$implicit.nomhijo; _ck(_v, 3, 0, currVal_3); }); }
function View_NavComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_NavComponent_6)), i0.ɵdid(2, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = (_v.context.$implicit.menu_id == _v.parent.parent.context.$implicit.id); _ck(_v, 2, 0, currVal_0); }, null); }
function View_NavComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "ul", [["class", "afq"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_NavComponent_5)), i0.ɵdid(2, 278528, null, 0, i2.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.submenus; _ck(_v, 2, 0, currVal_0); }, null); }
function View_NavComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "li", [["routerLinkActive", "ahv"]], null, null, null, null, null)), i0.ɵdid(1, 1720320, null, 2, i1.RouterLinkActive, [i1.Router, i0.ElementRef, i0.Renderer2, i0.ChangeDetectorRef], { routerLinkActive: [0, "routerLinkActive"] }, null), i0.ɵqud(603979776, 3, { links: 1 }), i0.ɵqud(603979776, 4, { linksWithHrefs: 1 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_NavComponent_2)), i0.ɵdid(5, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_NavComponent_3)), i0.ɵdid(7, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_NavComponent_4)), i0.ɵdid(9, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = "ahv"; _ck(_v, 1, 0, currVal_0); var currVal_1 = (_v.context.$implicit.padre == 1); _ck(_v, 5, 0, currVal_1); var currVal_2 = (_v.context.$implicit.padre == 2); _ck(_v, 7, 0, currVal_2); var currVal_3 = (_v.context.$implicit.padre == 2); _ck(_v, 9, 0, currVal_3); }, null); }
export function View_NavComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 19, "nav", [["class", "ain"], ["id", "ahu"], ["role", "navigation"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "a", [["class", "iconMenu pointer"], ["style", "background: inherit;color: #b9282c;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.menuResponsive() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "i", [["class", "fa fa-2x fa-bars"], ["style", "padding: 6px 0 6px 0;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 16, "ul", [["class", "ain topnav"], ["id", "myTopnav"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 6, "li", [["routerLinkActive", "ahv"]], null, null, null, null, null)), i0.ɵdid(5, 1720320, null, 2, i1.RouterLinkActive, [i1.Router, i0.ElementRef, i0.Renderer2, i0.ChangeDetectorRef], { routerLinkActive: [0, "routerLinkActive"] }, null), i0.ɵqud(603979776, 1, { links: 1 }), i0.ɵqud(603979776, 2, { linksWithHrefs: 1 }), (_l()(), i0.ɵeld(8, 0, null, null, 2, "a", [["routerLink", "/home"], ["title", "Inicio"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 9).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(9, 671744, [[2, 4]], 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵted(-1, null, ["Inicio"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_NavComponent_1)), i0.ɵdid(12, 278528, null, 0, i2.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵeld(13, 0, null, null, 6, "li", [["routerLinkActive", "ahv"]], null, null, null, null, null)), i0.ɵdid(14, 1720320, null, 2, i1.RouterLinkActive, [i1.Router, i0.ElementRef, i0.Renderer2, i0.ChangeDetectorRef], { routerLinkActive: [0, "routerLinkActive"] }, null), i0.ɵqud(603979776, 5, { links: 1 }), i0.ɵqud(603979776, 6, { linksWithHrefs: 1 }), (_l()(), i0.ɵeld(17, 0, null, null, 2, "a", [["routerLink", "/contacto"], ["title", "Contacto"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 18).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(18, 671744, [[6, 4]], 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵted(-1, null, ["Contacto"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "ahv"; _ck(_v, 5, 0, currVal_0); var currVal_3 = "/home"; _ck(_v, 9, 0, currVal_3); var currVal_4 = _co.menus; _ck(_v, 12, 0, currVal_4); var currVal_5 = "ahv"; _ck(_v, 14, 0, currVal_5); var currVal_8 = "/contacto"; _ck(_v, 18, 0, currVal_8); }, function (_ck, _v) { var currVal_1 = i0.ɵnov(_v, 9).target; var currVal_2 = i0.ɵnov(_v, 9).href; _ck(_v, 8, 0, currVal_1, currVal_2); var currVal_6 = i0.ɵnov(_v, 18).target; var currVal_7 = i0.ɵnov(_v, 18).href; _ck(_v, 17, 0, currVal_6, currVal_7); }); }
export function View_NavComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-nav", [], null, null, null, View_NavComponent_0, RenderType_NavComponent)), i0.ɵdid(1, 114688, null, 0, i3.NavComponent, [i4.MenuService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NavComponentNgFactory = i0.ɵccf("app-nav", i3.NavComponent, View_NavComponent_Host_0, {}, {}, []);
export { NavComponentNgFactory as NavComponentNgFactory };
