import { PipeTransform } from '@angular/core';
var CadenasLibrosPipe = /** @class */ (function () {
    function CadenasLibrosPipe() {
    }
    CadenasLibrosPipe.prototype.transform = function (value) {
        this.cadenas = value.substr(0, 50);
        return this.cadenas + '...';
    };
    return CadenasLibrosPipe;
}());
export { CadenasLibrosPipe };
