import { Component, OnInit } from '@angular/core';
import { Noticia } from '../../models/noticias';
import { urlBackendUpload } from 'src/app/models/GLOBAL';
import { NoticiasService } from 'src/app/services/noticias/noticias.service';
declare function init_slider(): any;

@Component({
  selector: 'app-prensa-noticias',
  templateUrl: './prensa-noticias.component.html',
  styles: []
})
export class PrensaNoticiasComponent implements OnInit {

  noticias: Noticia;
  total: number;
  noticiasMeses: any[]= [];
  totalMeses: number;
  url: string = urlBackendUpload + 'noticias/';

   // PAGINADO
   p: number = 1;
   public autoHide: boolean = true;
   public labels: any = {
         previousLabel: 'Anterior',
         nextLabel: 'Siguiente'
     };

  constructor(
    public noticiasService: NoticiasService
  ) {}

  ngOnInit() {
    this.cargarNoticias();
  }

  cargarNoticias(){
    this.noticiasService.cargarNoticias().subscribe(response=>{
      this.noticias = response.noticias;
      this.total = response.total;
    });
  }

}
