var Galeria = /** @class */ (function () {
    function Galeria(id, titulo, imagen, fecha, detalle, orden, status, created_at, updated_at) {
        this.id = id;
        this.titulo = titulo;
        this.imagen = imagen;
        this.fecha = fecha;
        this.detalle = detalle;
        this.orden = orden;
        this.status = status;
        this.created_at = created_at;
        this.updated_at = updated_at;
    }
    return Galeria;
}());
export { Galeria };
