<div class="afx ajh" *ngIf="total > 0">
    <div id="aen" class="ain">
        <div class="agh">
            <h2 class="agl">Detalles de Galerias</h2>
        </div>
        <nav class="aex adv">
            <ul>
                <li><a routerLink="/home" title="Inicio">Inicio</a></li>
                <li><a routerLink="/galeria/16/1">Galeria</a></li>
                <li><a class="titulo" title="{{galeria.titulo}}">{{galeria.titulo}}</a></li>
            </ul>
        </nav>
    </div>
</div>


<div class="afx ake" *ngIf="total > 0">
    <div role="main" class="aem">

        <h2>{{galeria.titulo}}</h2>
        <p style="text-align: justify !important;" [innerHTML]="galeria.detalle | htmlseguro"></p>
        <hr style="margin: 0;">
        <p>{{galeria.fecha | fecha}}</p>

        <ul class="ain" *ngIf="totalImagen > 0" style="padding: 0;width: 105%;">
            <li class="agi" style="margin-right: 2%;margin-bottom: 2%;" *ngFor="let imagen of imagenes | paginate: { itemsPerPage: 15, currentPage: p }; let i=index; ">
                <a class="acm" data-lightbox-gallery="gallery1" [href]="urlImagen + imagen.imagen" title="Imágen {{i+1}}">
                    <img [src]="urlImagen + imagen.imagen" alt="">
                    <div class="abs" *ngIf="imagen.titulo !== null">
                        <p>{{imagen.titulo}}</p>
                    </div>
                </a>
            </li>
        </ul>
        <ul class="ain" *ngIf="totalImagen == 0">
            <li class="ady ajy">No se encontrarón imágenes para este evento.</li>
        </ul>

        <nav class="adl" style="margin-bottom: 1%;">
            <pagination-controls [autoHide]="autoHide" [previousLabel]="labels.previousLabel" [nextLabel]="labels.nextLabel" (pageChange)="p = $event" class="text-center"></pagination-controls>
        </nav>

        <div class="ain"></div>
    </div>

</div>
