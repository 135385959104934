<app-breadcrumbs></app-breadcrumbs>

<div class="afx ake" *ngIf="total > 0">
    <div role="main" class="aem">
        <div class="aeg ahg" *ngIf="totalImagen > 0">
            <div class="flex-container">
                <div class="flexslider abe">
                    <ul class="flex-slides">
                        <li *ngFor="let imagen of imagenes" class="clone" aria-hidden="true">
                            <img [src]="urlImagen + imagen.imagen" alt="" draggable="true" style="width: 100%; height: 563px;">
                        </li>
                    </ul>
                    <ul class="flex-direction-nav">
                        <li>
                            <a class="flex-prev" href="#">
                                <em>Previous</em>
                            </a>
                        </li>
                        <li>
                            <a class="flex-next" href="#">
                                <em>Next</em>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <h2>{{noticias.titulo}}</h2>
        <p style="text-align: justify !important;" [innerHTML]="noticias.desc_small | htmlseguro"></p>
        <hr style="margin: 0;">
        <p>{{noticias.fecha | fecha}} - <a [href]="url + noticias.archivo" target="blank">Descargar Archivo</a></p>

        <div class="ain"></div>
    </div>

</div>
