import { Eventos } from '../../models/eventos';
import { Component, OnInit } from '@angular/core';
import { EventosService } from 'src/app/services/eventos/eventos.service';
import { urlBackendUpload } from 'src/app/models/GLOBAL';

@Component({
  selector: 'app-eventos',
  templateUrl: './eventos.component.html',
  styles: []
})
export class EventosComponent implements OnInit {

  eventos: Eventos[] = [];
  total: number;
  url: string = urlBackendUpload + 'eventos/';

  constructor(public eventosService: EventosService) {}

  ngOnInit() {
    this.eventosService.cargarEventos().subscribe(response=>{
      this.eventos = response.eventos;
      this.total = response.total;
    });
  }

}
