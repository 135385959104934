<app-breadcrumbs></app-breadcrumbs>

<div class="afx ake">
    <div role="main" class="aem" style="text-align: center;">
      <div id="ado">
        <div id="aca" class="ain">
          <article class="aak">
            <div class="acx">
              <h1 class="aba">{{movil.titulo}}</h1>
              <h2>Directorio Notarial del Perú </h2>
              <div class="adu"><img src="{{url}}logo_notario.jpg" alt="" style="width: 134px; height: 122px;"></div>
              <p [innerHTML]="movil.detalle | htmlseguro"></p>

              <div class="adu" style="margin-top: 3%;">
                <a href="{{url}}cnl.apk"><img src="{{url}}descargar_apk.jpg" alt="" style="width: 203px; height: 95px;"></a>
                <img src="{{url}}qr.jpg" alt="" style="width: 103px; height: 107px;">
              </div>

              <div class="adu"><a href="https://play.google.com/store/apps/details?id=com.buildv2.cnl&hl=es" target="blank">
                <img src="{{url}}google_play.jpg" alt="" style="width: 203px; height: 73px;"></a>
              </div>

              <div class="adu" style="margin-top: 3%;">
                <img src="{{url}}inicio_notario.jpg" alt="" style="width: 172px; height: 282px;margin-right: 2%;">
                <img src="{{url}}colegios_notario.jpg" alt="" style="width: 172px; height: 282px;margin-right: 2%;">
                <img src="{{url}}info_notario.jpg" alt="" style="width: 172px; height: 282px;margin-right: 2%;">
                <img src="{{url}}gps_notario.jpg" alt="" style="width: 172px; height: 282px;margin-right: 2%;">
              </div>

              <p style="margin-top: 3%;" [innerHTML]="movil.detalle2 | htmlseguro"></p>

            </div>
          </article>
        </div>
      </div>
      <div class="ain"></div>
    </div>
  </div>
