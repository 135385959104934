/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/common";
import * as i3 from "ngx-pagination";
import * as i4 from "../../../../node_modules/ngx-pagination/dist/ngx-pagination.ngfactory";
import * as i5 from "../../shared/breadcrumbs/breadcrumbs.component.ngfactory";
import * as i6 from "../../shared/breadcrumbs/breadcrumbs.component";
import * as i7 from "../../services/shared/breadcrumbs.service";
import * as i8 from "@angular/platform-browser";
import * as i9 from "./prensa-videos.component";
import * as i10 from "../../services/videos/videos.service";
var styles_PrensaVideosComponent = [];
var RenderType_PrensaVideosComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PrensaVideosComponent, data: {} });
export { RenderType_PrensaVideosComponent as RenderType_PrensaVideosComponent };
function View_PrensaVideosComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "li", [["class", "agg"], ["style", "margin-right: 4%; margin-left: 0;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "a", [["class", ""], ["style", "margin-right: 5%;"]], [[8, "title", 0], [1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 671744, null, 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i0.ɵpad(3, 2), (_l()(), i0.ɵeld(4, 0, null, null, 0, "img", [["alt", ""]], [[8, "src", 4]], null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 4, "h3", [["class", "text-center"], ["style", "margin-bottom: 12px;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 3, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 7).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(7, 671744, null, 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i0.ɵpad(8, 2), (_l()(), i0.ɵted(9, null, ["", ""]))], function (_ck, _v) { var currVal_3 = _ck(_v, 3, 0, "/detalleVideos", _v.context.$implicit.id); _ck(_v, 2, 0, currVal_3); var currVal_7 = _ck(_v, 8, 0, "/detalleVideos", _v.context.$implicit.id); _ck(_v, 7, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "", _v.context.$implicit.titulo, ""); var currVal_1 = i0.ɵnov(_v, 2).target; var currVal_2 = i0.ɵnov(_v, 2).href; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_4 = (_co.url + _v.context.$implicit.imagen); _ck(_v, 4, 0, currVal_4); var currVal_5 = i0.ɵnov(_v, 7).target; var currVal_6 = i0.ɵnov(_v, 7).href; _ck(_v, 6, 0, currVal_5, currVal_6); var currVal_8 = _v.context.$implicit.titulo; _ck(_v, 9, 0, currVal_8); }); }
function View_PrensaVideosComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 14, "div", [["class", "afx ake"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 13, "div", [["class", "aem"], ["role", "main"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 11, "div", [["id", "agv"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 7, "section", [], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Galeria de Videos"])), (_l()(), i0.ɵeld(6, 0, null, null, 4, "ul", [["class", "ain"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 3, null, View_PrensaVideosComponent_2)), i0.ɵdid(8, 278528, null, 0, i2.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i0.ɵpod(9, { itemsPerPage: 0, currentPage: 1 }), i0.ɵpid(0, i3.PaginatePipe, [i3.PaginationService]), (_l()(), i0.ɵeld(11, 0, null, null, 2, "nav", [["class", "adl"], ["style", "margin-bottom: 1%;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 1, "pagination-controls", [["class", "text-center"]], null, [[null, "pageChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("pageChange" === en)) {
        var pd_0 = ((_co.p = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_PaginationControlsComponent_0, i4.RenderType_PaginationControlsComponent)), i0.ɵdid(13, 49152, null, 0, i3.PaginationControlsComponent, [], { autoHide: [0, "autoHide"], previousLabel: [1, "previousLabel"], nextLabel: [2, "nextLabel"] }, { pageChange: "pageChange" }), (_l()(), i0.ɵeld(14, 0, null, null, 0, "div", [["class", "ain"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 8, 0, i0.ɵnov(_v, 10).transform(_co.videos, _ck(_v, 9, 0, 10, _co.p))); _ck(_v, 8, 0, currVal_0); var currVal_1 = _co.autoHide; var currVal_2 = _co.labels.previousLabel; var currVal_3 = _co.labels.nextLabel; _ck(_v, 13, 0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_PrensaVideosComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-breadcrumbs", [], null, null, null, i5.View_BreadcrumbsComponent_0, i5.RenderType_BreadcrumbsComponent)), i0.ɵdid(1, 114688, null, 0, i6.BreadcrumbsComponent, [i7.BreadcrumbsService, i8.Title, i8.Meta], null, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PrensaVideosComponent_1)), i0.ɵdid(3, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_0 = (_co.total > 0); _ck(_v, 3, 0, currVal_0); }, null); }
export function View_PrensaVideosComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-prensa-videos", [], null, null, null, View_PrensaVideosComponent_0, RenderType_PrensaVideosComponent)), i0.ɵdid(1, 114688, null, 0, i9.PrensaVideosComponent, [i10.VideosService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PrensaVideosComponentNgFactory = i0.ɵccf("app-prensa-videos", i9.PrensaVideosComponent, View_PrensaVideosComponent_Host_0, {}, {}, []);
export { PrensaVideosComponentNgFactory as PrensaVideosComponentNgFactory };
