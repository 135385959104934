import { Component, OnInit } from '@angular/core';
import { urlBackendUpload } from 'src/app/models/GLOBAL';
import { Noticia } from './../../models/noticias';
import { NoticiasService } from 'src/app/services/noticias/noticias.service';

@Component({
  selector: "app-prensa-listado",
  templateUrl: "./prensa-listado.component.html",
  styles: []
})
export class PrensaListadoComponent implements OnInit {
  url: string = urlBackendUpload + "noticias/";
  noticias: Noticia;
  Anios:any[] = [];
  Meses:any[] = [];
  Noticias:Noticia[] = [];

  // PAGINADO
  p: number = 1;
  public autoHide: boolean = true;
  public labels: any = {
    previousLabel: '',
    nextLabel: ''
  };

  constructor(
    public noticiasService: NoticiasService,
  ) {}

  ngOnInit() {
    this.cargarAnios();
    this.cargarMeses();
    this.cargarNoticias();
  }

  cargarAnios(){
    this.noticiasService.listarAnios().subscribe(response=>{
      this.Anios = response.anios;
    });
  }

  cargarMeses(){
    this.noticiasService.listarMeses().subscribe(response => {
      this.Meses = response.meses;
    });
  }

  cargarNoticias(){
    this.noticiasService.listarNoticias().subscribe(response=>{
      this.noticias = response.noticias;
    });
  }
}
