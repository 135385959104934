var Libros = /** @class */ (function () {
    function Libros(id, titulo, autor, resumen, publicacion, precio, imagen, orden, status, created_at, updated_at) {
        this.id = id;
        this.titulo = titulo;
        this.autor = autor;
        this.resumen = resumen;
        this.publicacion = publicacion;
        this.precio = precio;
        this.imagen = imagen;
        this.orden = orden;
        this.status = status;
        this.created_at = created_at;
        this.updated_at = updated_at;
    }
    return Libros;
}());
export { Libros };
