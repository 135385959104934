import { urlBackend, urlBackendUpload } from '../../models/GLOBAL';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var EntidadesService = /** @class */ (function () {
    function EntidadesService(http) {
        this.http = http;
        this.url = urlBackend;
        this.urlUpload = urlBackendUpload;
    }
    EntidadesService.prototype.cargarSlider = function () {
        return this.http.get(this.url + 'entidad');
    };
    EntidadesService.ngInjectableDef = i0.defineInjectable({ factory: function EntidadesService_Factory() { return new EntidadesService(i0.inject(i1.HttpClient)); }, token: EntidadesService, providedIn: "root" });
    return EntidadesService;
}());
export { EntidadesService };
