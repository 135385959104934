import { urlBackend } from '../../models/GLOBAL';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var VideosService = /** @class */ (function () {
    function VideosService(http) {
        this.http = http;
        this.url = urlBackend;
    }
    VideosService.prototype.cargarVideos = function () {
        return this.http.get(this.url + 'videos');
    };
    VideosService.prototype.cargarVideosPortada = function () {
        return this.http.get(this.url + 'videos/portada');
    };
    VideosService.prototype.obtenerVideoDetalle = function (id) {
        return this.http.get(this.url + 'videos/' + id);
    };
    VideosService.ngInjectableDef = i0.defineInjectable({ factory: function VideosService_Factory() { return new VideosService(i0.inject(i1.HttpClient)); }, token: VideosService, providedIn: "root" });
    return VideosService;
}());
export { VideosService };
