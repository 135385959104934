import { urlBackend } from 'src/app/models/GLOBAL';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var MensajesService = /** @class */ (function () {
    function MensajesService(http) {
        this.http = http;
        this.url = urlBackend;
    }
    MensajesService.prototype.enviarMensaje = function (mensaje) {
        var json = JSON.stringify(mensaje);
        return this.http.post(this.url + 'mensajes', { json: json });
    };
    MensajesService.ngInjectableDef = i0.defineInjectable({ factory: function MensajesService_Factory() { return new MensajesService(i0.inject(i1.HttpClient)); }, token: MensajesService, providedIn: "root" });
    return MensajesService;
}());
export { MensajesService };
