/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/common";
import * as i3 from "../../pipes/domseguro.pipe";
import * as i4 from "@angular/platform-browser";
import * as i5 from "../../pipes/fecha.pipe";
import * as i6 from "./video-detalle.component";
import * as i7 from "../../services/videos/videos.service";
var styles_VideoDetalleComponent = [];
var RenderType_VideoDetalleComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_VideoDetalleComponent, data: {} });
export { RenderType_VideoDetalleComponent as RenderType_VideoDetalleComponent };
function View_VideoDetalleComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 17, "div", [["class", "afx ajh"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 16, "div", [["class", "ain"], ["id", "aen"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "div", [["class", "agh"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "h2", [["class", "agl"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Detalles de Videos"])), (_l()(), i0.ɵeld(5, 0, null, null, 12, "nav", [["class", "aex adv"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 11, "ul", [], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 3, "li", [], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 2, "a", [["routerLink", "/home"], ["title", "Inicio"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 9).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(9, 671744, null, 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵted(-1, null, ["Inicio"])), (_l()(), i0.ɵeld(11, 0, null, null, 3, "li", [], null, null, null, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 2, "a", [["routerLink", "/videos/10/1"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 13).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(13, 671744, null, 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵted(-1, null, ["Videos del CNL"])), (_l()(), i0.ɵeld(15, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i0.ɵeld(16, 0, null, null, 1, "a", [["class", "titulo"]], [[8, "title", 0]], null, null, null, null)), (_l()(), i0.ɵted(17, null, ["", ""]))], function (_ck, _v) { var currVal_2 = "/home"; _ck(_v, 9, 0, currVal_2); var currVal_5 = "/videos/10/1"; _ck(_v, 13, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 9).target; var currVal_1 = i0.ɵnov(_v, 9).href; _ck(_v, 8, 0, currVal_0, currVal_1); var currVal_3 = i0.ɵnov(_v, 13).target; var currVal_4 = i0.ɵnov(_v, 13).href; _ck(_v, 12, 0, currVal_3, currVal_4); var currVal_6 = i0.ɵinlineInterpolate(1, "", _co.videos.titulo, ""); _ck(_v, 16, 0, currVal_6); var currVal_7 = _co.videos.titulo; _ck(_v, 17, 0, currVal_7); }); }
function View_VideoDetalleComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 16, "div", [["class", "afx ake"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 15, "div", [["class", "aem"], ["role", "main"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 14, "div", [["id", "ado"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 13, "div", [["class", "ain"], ["id", "aca"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 12, "article", [["class", "aak"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 11, "div", [["class", "acx"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 2, "div", [["class", "adu"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 1, "iframe", [["allow", "autoplay; encrypted-media"], ["allowfullscreen", ""], ["frameborder", "0"], ["height", "500px"], ["width", "100%"]], [[8, "src", 5]], null, null, null, null)), i0.ɵppd(8, 1), (_l()(), i0.ɵeld(9, 0, null, null, 1, "h2", [["class", "aba text-center"]], null, null, null, null, null)), (_l()(), i0.ɵted(10, null, ["", ""])), (_l()(), i0.ɵeld(11, 0, null, null, 5, "div", [["class", "abl text-center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 4, "ul", [], null, null, null, null, null)), (_l()(), i0.ɵeld(13, 0, null, null, 3, "li", [["class", "abw"]], null, null, null, null, null)), (_l()(), i0.ɵeld(14, 0, null, null, 2, "time", [], null, null, null, null, null)), (_l()(), i0.ɵted(15, null, ["", ""])), i0.ɵppd(16, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 7, 0, _ck(_v, 8, 0, i0.ɵnov(_v.parent, 0), (_co.videos.url + _co.opciones))); _ck(_v, 7, 0, currVal_0); var currVal_1 = _co.videos.titulo; _ck(_v, 10, 0, currVal_1); var currVal_2 = i0.ɵunv(_v, 15, 0, _ck(_v, 16, 0, i0.ɵnov(_v.parent, 1), _co.videos.fecha)); _ck(_v, 15, 0, currVal_2); }); }
export function View_VideoDetalleComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i3.DomseguroPipe, [i4.DomSanitizer]), i0.ɵpid(0, i5.FechaPipe, []), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_VideoDetalleComponent_1)), i0.ɵdid(3, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_VideoDetalleComponent_2)), i0.ɵdid(5, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.total > 0); _ck(_v, 3, 0, currVal_0); var currVal_1 = (_co.total > 0); _ck(_v, 5, 0, currVal_1); }, null); }
export function View_VideoDetalleComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-video-detalle", [], null, null, null, View_VideoDetalleComponent_0, RenderType_VideoDetalleComponent)), i0.ɵdid(1, 114688, null, 0, i6.VideoDetalleComponent, [i1.ActivatedRoute, i7.VideosService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var VideoDetalleComponentNgFactory = i0.ɵccf("app-video-detalle", i6.VideoDetalleComponent, View_VideoDetalleComponent_Host_0, {}, {}, []);
export { VideoDetalleComponentNgFactory as VideoDetalleComponentNgFactory };
