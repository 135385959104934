import { Component, OnInit } from '@angular/core';
import { Noticia } from '../../models/noticias';
import { NoticiasService } from 'src/app/services/noticias/noticias.service';

@Component({
  selector: 'app-noticias',
  templateUrl: './noticias.component.html',
  styles: []
})
export class NoticiasComponent implements OnInit {

  noticias: Noticia[] = [];
  total: number;
  url: string;

  constructor(public noticiasService: NoticiasService) {
    this.url = noticiasService.urlUpload + 'noticias/';
  }

  ngOnInit() {
    this.noticiasService.cargarNoticiasPortada().subscribe(response=>{
      this.noticias = response.noticias;
      this.total = response.total;
    });
  }

}
