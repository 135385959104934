var Paginas = /** @class */ (function () {
    function Paginas(id, menu_id, submenu_id, titulo, imagen, archivo, contenido, orden, status) {
        this.id = id;
        this.menu_id = menu_id;
        this.submenu_id = submenu_id;
        this.titulo = titulo;
        this.imagen = imagen;
        this.archivo = archivo;
        this.contenido = contenido;
        this.orden = orden;
        this.status = status;
    }
    return Paginas;
}());
export { Paginas };
