import { Component, OnInit } from '@angular/core';
import { Empresas } from 'src/app/models/empresas';
import { urlBackendUpload } from 'src/app/models/GLOBAL';
import { EmpresasService } from 'src/app/services/empresas/empresas.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styles: []
})
export class FooterComponent implements OnInit {

  empresas: Empresas = new Empresas();
  url: string = urlBackendUpload + 'empresas/';

  constructor(
    public empresasService: EmpresasService
  ) { }

  fecha:any = new Date();

  urlFacebook: string = 'https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FColegio-De-Notarios-De-Ica-1462365380686522%2F&tabs=timeline&width=340&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId';

  ngOnInit() {
    this.empresasService.cargarEmpresas(1).subscribe(response => {
      this.empresas = response.empresas;
    });
  }

}
