import { Component, OnInit } from '@angular/core';
import { urlBackendUpload } from 'src/app/models/GLOBAL';
import { MovilService } from 'src/app/services/movil/movil.service';
import { Movil } from 'src/app/models/movil';

@Component({
  selector: 'app-movil',
  templateUrl: './movil.component.html',
  styles: []
})
export class MovilComponent implements OnInit {

  url: string = urlBackendUpload + 'movil/';
  movil: Movil = new Movil();

  constructor(public movilService: MovilService) { }

  ngOnInit() {
    this.cargarMovil();
  }

  cargarMovil(){
    this.movilService.cargarMovil().subscribe(response=>{
      this.movil = response.moviles;
    });
  }

}
