import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styles: []
})
export class TopbarComponent implements OnInit {

  constructor() { }

  ngOnInit() {

  }

}
