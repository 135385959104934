import { Component, OnInit } from '@angular/core';
import { BoletinesService } from '../../services/boletines/boletines.service';
import { BoletinDetalle } from '../../models/boletinDetalle';
import { ActivatedRoute } from '@angular/router';
import { urlBackendUpload } from 'src/app/models/GLOBAL';

@Component({
  selector: 'app-boletin-detalle',
  templateUrl: './boletin-detalle.component.html',
  styles: []
})
export class BoletinDetalleComponent implements OnInit {

  url:string = urlBackendUpload + 'boletines/';

  constructor(
    private activatedRoute: ActivatedRoute,
    private boletinService: BoletinesService,
  ) {}

  boletinDetalles: BoletinDetalle;
  titulo: string;
  total: number;

  ngOnInit() {
    this.activatedRoute.params.subscribe(params=>{
      let codigo = params['id'];
      this.obtenerBoletin(codigo);
      this.boletinService.obtenerBoletinDetalle(codigo).subscribe(response=>{
        this.boletinDetalles = response.boletines;
        this.total = response.total;
      });
    });
  }

  obtenerBoletin(id:string){
    this.boletinService.obtenerBoletin(id).subscribe(response=>{
      this.titulo = response.boletines.titulo;
    });
  }

}
