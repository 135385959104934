import { Component, OnInit, DoCheck } from '@angular/core';
import { NotariosService } from '../../../services/notarios/notarios.service';
import { Notarios } from 'src/app/models/notarios';
import { urlBackendUpload } from 'src/app/models/GLOBAL';
import { Router } from '@angular/router';
declare function init_slider(): any;
declare var $: any;
import swal from 'sweetalert';
@Component({
  selector: 'app-lista-precio',
  templateUrl: './lista-precio.component.html',
  styles: []
})

export class ListaPrecioComponent implements OnInit, DoCheck {
  data: any[] = [];
  total: number = 0;
  url: string = urlBackendUpload + 'listaprecios/';
  distritos: any[] = [];

  totalContacto: number = 0;
  totalAbogado: number = 0;

  notarios: Notarios[] = [];

  panelOpenState = false;

  constructor(
    public notariosServices: NotariosService,
    public router: Router
  ) { }

  ngOnInit() {
    init_slider();
    this.cargarNotarios(11);
    this.cargarDistritos();
  }

  ngDoCheck() {
    init_slider();
  }

  cargarNotarios(sisev: number) {
    this.notariosServices.listaNotariosPrecio(sisev).subscribe(response => {
      this.notarios = response.notarios;
      this.total = response.total;
    });
  }

  buscarNotario(termino: string) {
    if (termino != '') {
      this.notariosServices.buscarNotarioNuevo(termino).subscribe(response => {
        this.notarios = response.notarios;
        this.total = response.total;
      });
    } else {
      this.cargarNotarios(11);
    }
  }

  buscarNotarioDistrito(termino: string) {
    if (termino != '0') {
      this.notariosServices.buscarNotarioDistrito(termino).subscribe(response => {
        this.notarios = response.notarios;
        this.total = response.total;
      });
    } else {
      this.cargarNotarios(11);
    }
  }

  cargarDistritos() {
    this.notariosServices.cargarDistritos().subscribe(response => {
      this.distritos = response.distritos;
    });
  }

  descargarPDF(colegiatura: number) {
    if (colegiatura === 0) {
      swal('Sin datos encontrados');
      return;
    }
    window.open(this.url + colegiatura + '.pdf', '_blank');
  }

  obtenerServicios(notarioId: number) {

    if (this.panelOpenState === true) {
      $("#" + notarioId).addClass('color-expand');
    } else {
      $("#" + notarioId).removeClass('color-expand');
    }
  }

}
