import { OnInit } from '@angular/core';
import { NoticiasService } from 'src/app/services/noticias/noticias.service';
var NoticiasComponent = /** @class */ (function () {
    function NoticiasComponent(noticiasService) {
        this.noticiasService = noticiasService;
        this.noticias = [];
        this.url = noticiasService.urlUpload + 'noticias/';
    }
    NoticiasComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.noticiasService.cargarNoticiasPortada().subscribe(function (response) {
            _this.noticias = response.noticias;
            _this.total = response.total;
        });
    };
    return NoticiasComponent;
}());
export { NoticiasComponent };
