var Notarios = /** @class */ (function () {
    function Notarios(id, ubigeo, sisev, estado, //0 activo - 1 cesado - 2 licencia
    notario, direccion, horario, telefono, correo, web, latitud, longitud, movilnotario, created_at, updated_at, colegiatura, presidente, miembro, visitado, nomdis, ubigeos, colegios) {
        this.id = id;
        this.ubigeo = ubigeo;
        this.sisev = sisev;
        this.estado = estado;
        this.notario = notario;
        this.direccion = direccion;
        this.horario = horario;
        this.telefono = telefono;
        this.correo = correo;
        this.web = web;
        this.latitud = latitud;
        this.longitud = longitud;
        this.movilnotario = movilnotario;
        this.created_at = created_at;
        this.updated_at = updated_at;
        this.colegiatura = colegiatura;
        this.presidente = presidente;
        this.miembro = miembro;
        this.visitado = visitado;
        this.nomdis = nomdis;
        this.ubigeos = ubigeos;
        this.colegios = colegios;
    }
    return Notarios;
}());
export { Notarios };
