import { urlBackend } from 'src/app/models/GLOBAL';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var MovilService = /** @class */ (function () {
    function MovilService(http) {
        this.http = http;
        this.url = urlBackend;
    }
    MovilService.prototype.cargarMovil = function () {
        return this.http.get(this.url + 'movil');
    };
    MovilService.ngInjectableDef = i0.defineInjectable({ factory: function MovilService_Factory() { return new MovilService(i0.inject(i1.HttpClient)); }, token: MovilService, providedIn: "root" });
    return MovilService;
}());
export { MovilService };
