import { Component, OnInit } from '@angular/core';
import { BoletinesService } from '../../services/boletines/boletines.service';
import { Boletin } from '../../models/boletin';

@Component({
  selector: 'app-prensa-boletines',
  templateUrl: './prensa-boletines.component.html',
  styles: []
})
export class PrensaBoletinesComponent implements OnInit {

  boletines: Boletin;
  total: number;

  constructor(
    public boletinService: BoletinesService,
  ) { }

  ngOnInit() {
    this.cargarBoletin();
  }

  cargarBoletin(){
    this.boletinService.cargarBoletines().subscribe(response=>{
      this.boletines = response.boletines;
      this.total = response.total;
    });
  }

}
