/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../pipes/domseguro.pipe";
import * as i3 from "@angular/platform-browser";
import * as i4 from "./video.component";
import * as i5 from "../../services/videos/videos.service";
var styles_VideoComponent = [];
var RenderType_VideoComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_VideoComponent, data: {} });
export { RenderType_VideoComponent as RenderType_VideoComponent };
function View_VideoComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "h2", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Videos / "])), (_l()(), i0.ɵeld(3, 0, null, null, 1, "a", [["class", "acl"], ["href", "https://www.youtube.com/user/cnotarioslima"], ["target", "blank"], ["title", "Youtube"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Canal de Youtube"]))], null, null); }
function View_VideoComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "li", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "div", [["class", "afa"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "div", [["class", "fluid-width-video-wrapper"], ["style", "padding-top: 102.609%;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "iframe", [["allow", "autoplay; encrypted-media"], ["allowfullscreen", ""], ["frameborder", "0"], ["height", "236"], ["width", "230"]], [[8, "src", 5]], null, null, null, null)), i0.ɵppd(4, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i0.ɵnov(_v.parent.parent, 0), (_v.context.$implicit.url + _co.opciones))); _ck(_v, 3, 0, currVal_0); }); }
function View_VideoComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "flex-container"], ["style", "margin-bottom: 18%;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "div", [["class", "flexslider aax"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "ul", [["class", "flex-slides"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_VideoComponent_3)), i0.ɵdid(4, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.videos; _ck(_v, 4, 0, currVal_0); }, null); }
export function View_VideoComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i2.DomseguroPipe, [i3.DomSanitizer]), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_VideoComponent_1)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_VideoComponent_2)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.total > 0); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.total > 0); _ck(_v, 4, 0, currVal_1); }, null); }
export function View_VideoComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-video", [], null, null, null, View_VideoComponent_0, RenderType_VideoComponent)), i0.ɵdid(1, 114688, null, 0, i4.VideoComponent, [i5.VideosService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var VideoComponentNgFactory = i0.ɵccf("app-video", i4.VideoComponent, View_VideoComponent_Host_0, {}, {}, []);
export { VideoComponentNgFactory as VideoComponentNgFactory };
