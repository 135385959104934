import { OnInit, DoCheck } from '@angular/core';
import { SliderService } from 'src/app/services/slider/slider.service';
var SliderComponent = /** @class */ (function () {
    function SliderComponent(sliderService) {
        this.sliderService = sliderService;
        this.sliders = [];
        this.url = sliderService.urlUpload + 'sliders/';
    }
    SliderComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.sliderService.cargarSlider().subscribe(function (response) {
            _this.sliders = response.sliders;
            _this.total = response.total;
            init_slider();
        });
    };
    SliderComponent.prototype.ngDoCheck = function () {
        init_slider();
    };
    return SliderComponent;
}());
export { SliderComponent };
