import { Component, OnInit } from '@angular/core';
import { NotariosService } from './../../services/notarios/notarios.service';
import { Notarios } from './../../models/notarios';

@Component({
  selector: "app-notarios",
  templateUrl: "./notarios.component.html",
  styles: []
})
export class NotariosComponent implements OnInit {
  notarios: Notarios[] = [];

  // PAGINADO
  p: number = 1;
  public autoHide: boolean = true;
  public labels: any = {
    previousLabel: 'Anterior',
    nextLabel: 'Siguiente'
  };

  constructor(
    public notariosService: NotariosService,
  ) {}

  ngOnInit() {
    this.cargarNotarios();
  }

  cargarNotarios() {
    this.notariosService.cargarNotarios().subscribe(response => {
      this.notarios = response.notarios;
    });
  }
}
