/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/common";
import * as i3 from "../../pipes/htmlseguro.pipe";
import * as i4 from "@angular/platform-browser";
import * as i5 from "../../pipes/cadenas.pipe";
import * as i6 from "./noticias.component";
import * as i7 from "../../services/noticias/noticias.service";
var styles_NoticiasComponent = [];
var RenderType_NoticiasComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_NoticiasComponent, data: {} });
export { RenderType_NoticiasComponent as RenderType_NoticiasComponent };
function View_NoticiasComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 14, "li", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 13, "div", [["class", "afa"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "div", [["class", "agj ais"], ["style", "margin-right: 5px;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 0, "img", [["alt", ""], ["class", "aeg"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 10, "div", [["class", "agj"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i0.ɵted(6, null, ["", ""])), (_l()(), i0.ɵeld(7, 0, null, null, 2, "p", [["style", "text-align: justify;"]], [[8, "innerHTML", 1]], null, null, null, null)), i0.ɵppd(8, 1), i0.ɵppd(9, 1), (_l()(), i0.ɵeld(10, 0, null, null, 4, "p", [], null, null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 3, "a", [["class", "ahs aip aks"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 12).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(12, 671744, null, 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i0.ɵpad(13, 2), (_l()(), i0.ɵted(-1, null, ["Leer mas \u00BB"]))], function (_ck, _v) { var currVal_5 = _ck(_v, 13, 0, "/detalleNoticias", _v.context.$implicit.id); _ck(_v, 12, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.url + _v.context.$implicit.imagen); _ck(_v, 3, 0, currVal_0); var currVal_1 = _v.context.$implicit.titulo; _ck(_v, 6, 0, currVal_1); var currVal_2 = i0.ɵunv(_v, 7, 0, _ck(_v, 9, 0, i0.ɵnov(_v.parent.parent, 0), i0.ɵunv(_v, 7, 0, _ck(_v, 8, 0, i0.ɵnov(_v.parent.parent, 1), _v.context.$implicit.desc_small)))); _ck(_v, 7, 0, currVal_2); var currVal_3 = i0.ɵnov(_v, 12).target; var currVal_4 = i0.ɵnov(_v, 12).href; _ck(_v, 11, 0, currVal_3, currVal_4); }); }
function View_NoticiasComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "aside", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "h2", [], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Noticias"])), (_l()(), i0.ɵeld(4, 0, null, null, 4, "div", [["class", "flex-container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 3, "div", [["class", "flexslider aax"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 2, "ul", [["class", "flex-slides"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_NoticiasComponent_2)), i0.ɵdid(8, 278528, null, 0, i2.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.noticias; _ck(_v, 8, 0, currVal_0); }, null); }
export function View_NoticiasComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i3.HtmlseguroPipe, [i4.DomSanitizer]), i0.ɵpid(0, i5.CadenasPipe, []), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_NoticiasComponent_1)), i0.ɵdid(3, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.total > 0); _ck(_v, 3, 0, currVal_0); }, null); }
export function View_NoticiasComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-noticias", [], null, null, null, View_NoticiasComponent_0, RenderType_NoticiasComponent)), i0.ɵdid(1, 114688, null, 0, i6.NoticiasComponent, [i7.NoticiasService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NoticiasComponentNgFactory = i0.ɵccf("app-noticias", i6.NoticiasComponent, View_NoticiasComponent_Host_0, {}, {}, []);
export { NoticiasComponentNgFactory as NoticiasComponentNgFactory };
