import { OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { VideosService } from 'src/app/services/videos/videos.service';
var VideoDetalleComponent = /** @class */ (function () {
    function VideoDetalleComponent(activatedRoute, videoService) {
        this.activatedRoute = activatedRoute;
        this.videoService = videoService;
        this.opciones = '?rel=0&amp;wmode=transparent';
    }
    VideoDetalleComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.activatedRoute.params.subscribe(function (params) {
            var codigo = params['id'];
            _this.videoService.obtenerVideoDetalle(codigo).subscribe(function (response) {
                _this.videos = response.videos;
                _this.total = response.total;
            });
        });
    };
    return VideoDetalleComponent;
}());
export { VideoDetalleComponent };
