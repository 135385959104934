import { Injectable } from '@angular/core';
import { urlBackend, urlBackendUpload } from '../../models/GLOBAL';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InformacionService {

  url: string = urlBackend;
  urlUpload: string = urlBackendUpload;

  constructor(public http: HttpClient) { }

  cargarInformacion():Observable<any>{
    return this.http.get(this.url + 'informacion');
  }
}
