<div class="afx ajh">
  <div id="aen" class="ain">
    <div class="agh">
      <h2 class="agl">404</h2>
    </div>
    <nav class="aex adv">
      <ul>
        <li><a routerLink="/home" title="Inicio">Inicio</a></li>
        <li><a title="Inicio">Página</a></li>
        <li><a routerLink="/home" title="404">404</a></li>
      </ul>
    </nav>
  </div>
</div>
<div class="afx ake">
  <div role="main" class="aem">
    <div id="akt" class="ain">
      <h1>404</h1>
      <h2>Ocurrió un error</h2>
      <p class="ahz">Lo siento pero la página o archivo que <br>
        eras buscando no se puede encontrar</p>
      <p>Regrese a la <a routerLink="/home">página de inicio</a>
    </div>
    <div class="ain"></div>
  </div>
</div>
