import { OnInit } from '@angular/core';
import { MenuService } from 'src/app/services/menu/menu.service';
var NavComponent = /** @class */ (function () {
    function NavComponent(menuService) {
        this.menuService = menuService;
        this.menus = [];
    }
    NavComponent.prototype.ngOnInit = function () {
        this.cargarMenu();
    };
    NavComponent.prototype.cargarMenu = function () {
        var _this = this;
        this.menuService.cargarMenu().subscribe(function (response) {
            _this.menus = response.menus;
        });
    };
    NavComponent.prototype.cargarSubMenu = function (id) {
        var _this = this;
        this.menuService.cargarSubMenu(id).subscribe(function (response) {
            _this.submenus = response.submenus;
        });
    };
    NavComponent.prototype.submenu = function (event, menuId) {
        if (event === 'mouseover') {
            this.cargarSubMenu(menuId);
        }
    };
    NavComponent.prototype.menuResponsive = function () {
        var x = document.getElementById("myTopnav");
        if (x.className === "topnav") {
            x.className += " responsive";
        }
        else {
            x.className = "topnav";
        }
    };
    return NavComponent;
}());
export { NavComponent };
