/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../video/video.component.ngfactory";
import * as i2 from "../video/video.component";
import * as i3 from "../../services/videos/videos.service";
import * as i4 from "@angular/common";
import * as i5 from "./boletines.component";
import * as i6 from "../../services/informacion/informacion.service";
import * as i7 from "../../services/boletines/boletines.service";
import * as i8 from "@angular/router";
var styles_BoletinesComponent = [];
var RenderType_BoletinesComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_BoletinesComponent, data: {} });
export { RenderType_BoletinesComponent as RenderType_BoletinesComponent };
function View_BoletinesComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "col-lg-12"], ["style", "margin-right: 3%;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-video", [], null, null, null, i1.View_VideoComponent_0, i1.RenderType_VideoComponent)), i0.ɵdid(2, 114688, null, 0, i2.VideoComponent, [i3.VideosService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
export function View_BoletinesComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_BoletinesComponent_1)), i0.ɵdid(1, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.total > 0); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_BoletinesComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-boletines", [], null, null, null, View_BoletinesComponent_0, RenderType_BoletinesComponent)), i0.ɵdid(1, 114688, null, 0, i5.BoletinesComponent, [i6.InformacionService, i7.BoletinesService, i8.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BoletinesComponentNgFactory = i0.ɵccf("app-boletines", i5.BoletinesComponent, View_BoletinesComponent_Host_0, {}, {}, []);
export { BoletinesComponentNgFactory as BoletinesComponentNgFactory };
