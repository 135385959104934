import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cadenasLibros'
})
export class CadenasLibrosPipe implements PipeTransform {
  cadenas:string;
  transform(value: any): any {
    this.cadenas = value.substr(0,50);
    return this.cadenas + '...';
  }

}
