<header role="banner" id="ahq" class="ain">
  <div class="agh">
    <h1>
      <a routerLink="/home" *ngIf="empresas.imagen">
        <img [src]="url + empresas.imagen" alt="">
      </a>
    </h1>
  </div>

  <div class="aex aih">
    <ul class="ajz agl ahx">
      <li>
        <span class="fa fa-phone"></span> {{empresas.telefono}} </li>
      <li>
        <span class="fa fa-envelope-o"></span>
        <a href="mailTo:{{empresas.correo}}">{{empresas.correo}}</a>
      </li>
    </ul>
    <app-busqueda-notarial></app-busqueda-notarial>
  </div>
</header>
