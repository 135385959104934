import { Injectable } from '@angular/core';
import { urlBackend } from '../../models/GLOBAL';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  url:string = urlBackend;

  constructor(public http: HttpClient) { }

  cargarMenu():Observable<any>{
    return this.http.get(this.url + 'menu');
  }

  cargarSubMenu(id:string):Observable<any>{
    return this.http.get(this.url + 'submenu/' + id);
  }

}
