import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Videos } from '../../models/videos';
import { VideosService } from 'src/app/services/videos/videos.service';

@Component({
  selector: 'app-video-detalle',
  templateUrl: './video-detalle.component.html',
  styles: []
})
export class VideoDetalleComponent implements OnInit {

  videos: Videos;
  total: number;
  opciones: string = '?rel=0&amp;wmode=transparent';

  constructor(
    private activatedRoute: ActivatedRoute,
    private videoService: VideosService,
  ) {}

  ngOnInit() {
    this.activatedRoute.params.subscribe(params=>{
      let codigo = params['id'];
      this.videoService.obtenerVideoDetalle(codigo).subscribe(response=>{
        this.videos = response.videos;
        this.total = response.total;
      });
    });
  }

}
