import { OnInit } from '@angular/core';
import { EntidadesService } from 'src/app/services/entidades/entidades.service';
var EntidadesComponent = /** @class */ (function () {
    function EntidadesComponent(entidadService) {
        this.entidadService = entidadService;
        this.entidades = [];
        this.url = entidadService.urlUpload + 'entidades/';
    }
    EntidadesComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.entidadService.cargarSlider().subscribe(function (response) {
            _this.entidades = response.entidades;
            _this.total = response.total;
        });
    };
    return EntidadesComponent;
}());
export { EntidadesComponent };
