/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/common";
import * as i3 from "ngx-pagination";
import * as i4 from "../../../../node_modules/ngx-pagination/dist/ngx-pagination.ngfactory";
import * as i5 from "../../pipes/htmlseguro.pipe";
import * as i6 from "@angular/platform-browser";
import * as i7 from "../../pipes/fecha.pipe";
import * as i8 from "./galeria-detalle.component";
import * as i9 from "../../services/galeria/galeria.service";
var styles_GaleriaDetalleComponent = [];
var RenderType_GaleriaDetalleComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_GaleriaDetalleComponent, data: {} });
export { RenderType_GaleriaDetalleComponent as RenderType_GaleriaDetalleComponent };
function View_GaleriaDetalleComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 17, "div", [["class", "afx ajh"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 16, "div", [["class", "ain"], ["id", "aen"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "div", [["class", "agh"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "h2", [["class", "agl"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Detalles de Galerias"])), (_l()(), i0.ɵeld(5, 0, null, null, 12, "nav", [["class", "aex adv"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 11, "ul", [], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 3, "li", [], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 2, "a", [["routerLink", "/home"], ["title", "Inicio"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 9).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(9, 671744, null, 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵted(-1, null, ["Inicio"])), (_l()(), i0.ɵeld(11, 0, null, null, 3, "li", [], null, null, null, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 2, "a", [["routerLink", "/galeria/16/1"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 13).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(13, 671744, null, 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵted(-1, null, ["Galeria"])), (_l()(), i0.ɵeld(15, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i0.ɵeld(16, 0, null, null, 1, "a", [["class", "titulo"]], [[8, "title", 0]], null, null, null, null)), (_l()(), i0.ɵted(17, null, ["", ""]))], function (_ck, _v) { var currVal_2 = "/home"; _ck(_v, 9, 0, currVal_2); var currVal_5 = "/galeria/16/1"; _ck(_v, 13, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 9).target; var currVal_1 = i0.ɵnov(_v, 9).href; _ck(_v, 8, 0, currVal_0, currVal_1); var currVal_3 = i0.ɵnov(_v, 13).target; var currVal_4 = i0.ɵnov(_v, 13).href; _ck(_v, 12, 0, currVal_3, currVal_4); var currVal_6 = i0.ɵinlineInterpolate(1, "", _co.galeria.titulo, ""); _ck(_v, 16, 0, currVal_6); var currVal_7 = _co.galeria.titulo; _ck(_v, 17, 0, currVal_7); }); }
function View_GaleriaDetalleComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "abs"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.titulo; _ck(_v, 2, 0, currVal_0); }); }
function View_GaleriaDetalleComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "li", [["class", "agi"], ["style", "margin-right: 2%;margin-bottom: 2%;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "a", [["class", "acm"], ["data-lightbox-gallery", "gallery1"]], [[8, "href", 4], [8, "title", 0]], null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "img", [["alt", ""]], [[8, "src", 4]], null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_GaleriaDetalleComponent_5)), i0.ɵdid(4, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_3 = (_v.context.$implicit.titulo !== null); _ck(_v, 4, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.urlImagen + _v.context.$implicit.imagen); var currVal_1 = i0.ɵinlineInterpolate(1, "Im\u00E1gen ", (_v.context.index + 1), ""); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = (_co.urlImagen + _v.context.$implicit.imagen); _ck(_v, 2, 0, currVal_2); }); }
function View_GaleriaDetalleComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "ul", [["class", "ain"], ["style", "padding: 0;width: 105%;"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 3, null, View_GaleriaDetalleComponent_4)), i0.ɵdid(2, 278528, null, 0, i2.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i0.ɵpod(3, { itemsPerPage: 0, currentPage: 1 }), i0.ɵpid(0, i3.PaginatePipe, [i3.PaginationService])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 4).transform(_co.imagenes, _ck(_v, 3, 0, 15, _co.p))); _ck(_v, 2, 0, currVal_0); }, null); }
function View_GaleriaDetalleComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "ul", [["class", "ain"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "li", [["class", "ady ajy"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["No se encontrar\u00F3n im\u00E1genes para este evento."]))], null, null); }
function View_GaleriaDetalleComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 17, "div", [["class", "afx ake"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 16, "div", [["class", "aem"], ["role", "main"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["", ""])), (_l()(), i0.ɵeld(4, 0, null, null, 1, "p", [["style", "text-align: justify !important;"]], [[8, "innerHTML", 1]], null, null, null, null)), i0.ɵppd(5, 1), (_l()(), i0.ɵeld(6, 0, null, null, 0, "hr", [["style", "margin: 0;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(8, null, ["", ""])), i0.ɵppd(9, 1), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_GaleriaDetalleComponent_3)), i0.ɵdid(11, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_GaleriaDetalleComponent_6)), i0.ɵdid(13, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(14, 0, null, null, 2, "nav", [["class", "adl"], ["style", "margin-bottom: 1%;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(15, 0, null, null, 1, "pagination-controls", [["class", "text-center"]], null, [[null, "pageChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("pageChange" === en)) {
        var pd_0 = ((_co.p = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_PaginationControlsComponent_0, i4.RenderType_PaginationControlsComponent)), i0.ɵdid(16, 49152, null, 0, i3.PaginationControlsComponent, [], { autoHide: [0, "autoHide"], previousLabel: [1, "previousLabel"], nextLabel: [2, "nextLabel"] }, { pageChange: "pageChange" }), (_l()(), i0.ɵeld(17, 0, null, null, 0, "div", [["class", "ain"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_3 = (_co.totalImagen > 0); _ck(_v, 11, 0, currVal_3); var currVal_4 = (_co.totalImagen == 0); _ck(_v, 13, 0, currVal_4); var currVal_5 = _co.autoHide; var currVal_6 = _co.labels.previousLabel; var currVal_7 = _co.labels.nextLabel; _ck(_v, 16, 0, currVal_5, currVal_6, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.galeria.titulo; _ck(_v, 3, 0, currVal_0); var currVal_1 = i0.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i0.ɵnov(_v.parent, 0), _co.galeria.detalle)); _ck(_v, 4, 0, currVal_1); var currVal_2 = i0.ɵunv(_v, 8, 0, _ck(_v, 9, 0, i0.ɵnov(_v.parent, 1), _co.galeria.fecha)); _ck(_v, 8, 0, currVal_2); }); }
export function View_GaleriaDetalleComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i5.HtmlseguroPipe, [i6.DomSanitizer]), i0.ɵpid(0, i7.FechaPipe, []), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_GaleriaDetalleComponent_1)), i0.ɵdid(3, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_GaleriaDetalleComponent_2)), i0.ɵdid(5, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.total > 0); _ck(_v, 3, 0, currVal_0); var currVal_1 = (_co.total > 0); _ck(_v, 5, 0, currVal_1); }, null); }
export function View_GaleriaDetalleComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-galeria-detalle", [], null, null, null, View_GaleriaDetalleComponent_0, RenderType_GaleriaDetalleComponent)), i0.ɵdid(1, 376832, null, 0, i8.GaleriaDetalleComponent, [i9.GaleriaService, i1.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GaleriaDetalleComponentNgFactory = i0.ɵccf("app-galeria-detalle", i8.GaleriaDetalleComponent, View_GaleriaDetalleComponent_Host_0, {}, {}, []);
export { GaleriaDetalleComponentNgFactory as GaleriaDetalleComponentNgFactory };
