import { OnInit } from '@angular/core';
import { urlBackendUpload } from 'src/app/models/GLOBAL';
import { NoticiasService } from 'src/app/services/noticias/noticias.service';
var PrensaNoticiasComponent = /** @class */ (function () {
    function PrensaNoticiasComponent(noticiasService) {
        this.noticiasService = noticiasService;
        this.noticiasMeses = [];
        this.url = urlBackendUpload + 'noticias/';
        // PAGINADO
        this.p = 1;
        this.autoHide = true;
        this.labels = {
            previousLabel: 'Anterior',
            nextLabel: 'Siguiente'
        };
    }
    PrensaNoticiasComponent.prototype.ngOnInit = function () {
        this.cargarNoticias();
    };
    PrensaNoticiasComponent.prototype.cargarNoticias = function () {
        var _this = this;
        this.noticiasService.cargarNoticias().subscribe(function (response) {
            _this.noticias = response.noticias;
            _this.total = response.total;
        });
    };
    return PrensaNoticiasComponent;
}());
export { PrensaNoticiasComponent };
